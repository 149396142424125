<template>
  <div class='doc_contioner' onselectstart="return false;" style="-moz-user-select:none;">
    <div class='mar-top'>
        <!-- 、doc、docx、xls、xlsx、pdf -->
        <!-- <span class='doc_span'>文档支持类型：ppt、pptx</span> -->
        <!-- <span>文件大小：100M以内</span> -->
        <div class="tabs">
          <div v-if="$configs.businessType != 10007" @click="tabClick(1)" :class="tabType == 1 ? 'checked':''">我的文档 <span></span></div>  
          <div @click="tabClick(2)" :class="tabType == 2 ? 'checked':''">会议文档 <span></span></div>  
        </div>
        <img src="@/assets/images/re.png" alt="" class="reload_style" @click='debounceHandle(getDocList)'>
    </div>

    <div class='table_top'>
        <div class='docname'>文档名称</div>
        <div class='docstatus'>上传状态</div>
        <div class='docbtn'>操作</div>
    </div>
    <div class='height_over'>
        <div class='doc_content' v-for="(item, index) in fileList" :key='index'>
            <div class='doc_style'>
                <div class='doc_name'>
                    <img v-if="item.docType == 0" src="@/assets/images/pdf.png" alt="">
                    <img v-if="item.docType == 1" src="@/assets/images/word.png" alt="">
                    <img v-if="item.docType == 2" src="@/assets/images/png.png" alt="">
                    <img v-if="item.docType == 3" src="@/assets/images/im.png" alt="">
                    <div class="name_warp">
                        <div class='name_style'>{{ item.name }}</div>
                        <div class='doc_flex'>
                            <p>{{ formTime(item.createTime) }}</p>
                            <p>{{ formSize(item.size) }}</p>
                        </div>
                    </div> 
                </div>
                <div class='doc_status'>{{ _docStatusMapping(item.status, item.percent,item)}}</div>
                <div class='doc_btn'>
                    <div v-if="item.status == 1">
                        <p @click='debounceHandle(queryFile.bind(this,item,false))'>打开文档</p>
                        <p v-if="item.userId == $configs.userId"  @click="debounceHandle(clickDelete.bind(this,item.id))">删除</p>
                    </div>
                    <div v-else>
                        ---
                    </div>
                </div>
            </div>
        </div>
    </div>
     <!-- 上传button -->
     <div class="upload-btn-box" v-if="ownerId == $configs.userId || isHost || isCoHost || tabType == 1">
      <div class='sure_style' @click="debounceHandle(uploadFileHandle)">上传文档</div>
      <div class="upload-tips">文档支持类型：ppt、pptx，文件大小100M以内</div>
      <div class="upload-tips">上传期间请勿刷新或关闭窗口，否则会上传失败</div>
  </div>
</div>
</template>

<script>
import { ROLE_CODE, CONTROL_COMMAND } from "@/constant/index";
import { fetchMeetControl,quertDocumentList,deleteDocumen } from '@/api/meet'
import debounce from 'lodash.debounce'
import { loganLog } from "@/utils/log"
import {getDocDate} from '@/utils/date'
import { stringify } from 'qs';

export default {
  data() {
    return {
      logTop:'200px',
      tabType: this.$configs.businessType != 10007 ? 1 : 2,// 文档类型。1 我的文档 2 会议文档
      fileList: []
    };
  }, 
  created(){
    this.getDocList()
  },
  computed: {
    userList() {
      // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },
    ownerId() { // 会议所有者id
      return this.$store.state.meet.ownerId
    },
    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    shareFireId() {
      return this.$store.state.meet.shareFireId
    },
    
    sharePermission() {
      return this.$store.state.meet.sharePermission;
    },

    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId)
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    }
  },
  methods: {
    // 列表时间处理
    formTime(time){
        let date = new Date(time)
        return getDocDate(date)
    },
    // 列表文件大小
    formSize(filesize){
        if (filesize < 1024) {
            return filesize + 'B'
        } else if (filesize < (1024 * 1024)) {
            let temp = filesize / 1024
            temp = temp.toFixed(2);
            return temp + 'KB'
        } else if (filesize < (1024 * 1024 * 1024)) {
            let temp = filesize / (1024 * 1024)
            temp = temp.toFixed(2);
            return temp + 'M'
        }
    },
    // 文档状态
    _docStatusMapping(status, percent,item){
      // console.error(status, percent,item)
        switch (status) {
            case 'uploading':
                return percent == '100' ? '99%' : `${(Number(percent)).toFixed()}%`
            case 'done':
            case 1 :
                return '上传成功'
            case 'success' :
                return '上传成功'
            case 'error':
                return '上传失败'
            default:
                return '上传失败'
        }
    },
    // 文档上传状态监听处理
    handleChangeProgress (info) {
      this.fileList.forEach((item, index)=>{
          if(item.uid === info.uid){
              if(info && info.percent === '100.00'){
                this.fileList.splice(index, 1)
              }else{
                this.$set(this.fileList, index, info)
              }
          }
      })
    },
    hightLight(item){
      let reg = new RegExp(this.keywords,'g');
      let resDtring = `<span style='color:#1AB370;'>${this.keywords}</span>`
      return item.replace(reg,resDtring)
    },
    debounceHandle: debounce(fn => fn && fn(), 1000, { leading: true, trailing: false }),
     // 文件应用
    async queryFile(item) {
        if(this.$store.state.meet.isNetworkDisconnect){
          this.$parent.$parent.showToast(this.$t('meeting.netWorkBad'))
          return false
        }

        if(!this.$parent.$parent.isOneySharePermis()) return
        let _data= { 
          id: item.id,
          isShareDoc: this.isShareDoc,
          meetInfo: {userName: this.$configs.userName,roomId: this.$configs.roomId,userId: this.$configs.userId},
          officeUrl:item.officeUrl,
          businessCode:item.businessCode,
          totalPage:item.totalPage,
        }
        
        this.$store.commit("meet/updateGlobalMeetState", {
          shareType: 1
        });
        this.$parent.$parent.isShowDocumentPanel = false
        this.$nextTick(() =>
          this.$documentManager.queryFile(_data)
        );
    },
    tabClick(i){
      if(i == this.tabType ) return
      this.tabType = i
      this.fileList = []
      this.getDocList()
    },
    //上传按钮点击
    uploadFileHandle(){
      this.$store.commit("meet/updateGlobalMeetState", {
        shareType: 1
      });
      let _element = document.getElementById('uploadBtnElement');
      let isUseMyGeRen = this.$configs.conferenceNo.length == 10 //是否个人会议号
      _element.dataset.upType  = this.tabType == 1 ? 0 : isUseMyGeRen ? 2 : 1

      this.$nextTick(() =>
        _element && _element.click()
      );
    },
    // 查询文件列表
    async getDocList(){
      const routeQuery = this.$route.query
      let isUseMyGeRen = this.$configs.conferenceNo.length == 10 //是否个人会议号
      // console.error(222222,isUseMyGeRen,this.$configs.conferenceNo.length)
      let param={
        type: this.tabType == 1 ? 0 : isUseMyGeRen ? 2 : 1,
        conferenceId: routeQuery.conferenceId,
        userId: this.tabType == 1 ? this.$configs.userId : this.ownerId,
        conferenceNo: this.$configs.conferenceNo,
        saveForever: true,
      }
      const resData = await quertDocumentList(param)
      if(!this.fileList.length){
          this.fileList = resData.records
      }else{
          let uploadingDocArr = this.fileList.filter(item=> item.status === 'uploading');
          this.fileList = [...uploadingDocArr,...resData.records]
      }
    }, 
    // 删除文件
    async clickDelete(id) {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.$parent.showToast(this.$t('meeting.netWorkBad'))
        return false
      }
        
      if(this.isShareDoc && this.shareFireId == id){
        this.$confirm('删除后将会影响文档共享和观看，确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async() => {
          try {
            await this.$documentManager.docShareStopFn(this.$configs.roomId,this.$configs.userId)
            this.deleteMeth(id)
          } catch (error) {
            loganLog(`删除文档错误---${JSON,stringify(error)}`)
          }
        }).catch(() => {
          this.$totast({
            type: 'info',
            message: '已取消删除'
          });          
        });
      } else {
        this.$confirm('是否删除已上传的文档?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.deleteMeth(id)
        }).catch(() => {
          this.$totast({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }

    },
    // 确定删除
    async deleteMeth(id){
      try {
          await deleteDocumen([id])
          this.$totast({
            type: 'success',
            message: '删除成功!'
          });
          this.getDocList()
      } catch (error) {
          console.log(error, 'test')
      }
    },
    async allowSelfUnmuteChange(e) {
      // let allowSelfUnmute;
      let command;

      if (e) {
        // allowSelfUnmute = 1;
        command = CONTROL_COMMAND.ALLOW_SELF_UNMUTE;
      } else {
        // allowSelfUnmute = 0;
        command = CONTROL_COMMAND.FORBID_SELF_UNMUTE;
      }

      await fetchMeetControl({
        command
      }, this.$configs)

      // this.$store.commit("meet/updateGlobalMeetState", {
      //   allowSelfUnmute,
      // });
    },
  },
};
</script>

<style lang="scss">
.doc_contioner {
  width: 100%;
  outline:none;
  span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
  }

  .doc_span {
      margin: 0 24px;
  }
 
  .mar-top{
    display: flex;
    justify-content: space-between;
    .tabs{
      display: flex;
      div{
        width: 52px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        line-height: 18px;
        margin-right: 36px;
        padding: 9px 0;
        text-align: center;
        position: relative;
        font-weight: normal;
        span{
          position: absolute;
          width: 32px;
          margin-left: -16px;
          height: 2px;
          background: #ffffff;
          display: block;
          left: 50%;
          bottom: 0;
        }
      }
      .checked{
        font-weight: 600;
        span{
          background: #1AB370;
        }
      }
    }
    .reload_style{
        width:18px;
        height: 18px;
        margin-left:26px;
        -webkit-transition: -webkit-transform 2s ease-out;
        &:hover {
                cursor: pointer;
            }

            &:focus,
            &:active {
                -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
            }
    }
  }
}

.table_top {
  background: #FAFAFA;
  border-radius: 2px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin: 16px 0;
  padding: 8px 0;
  display: flex;
  justify-content:space-between;


  .docname{
    padding-left: 24px;
    box-sizing: border-box;
    width: 50%;
  }
  .docstatus{
    width: 72px;
  }
  .docbtn {
    width: 162px;
  }
}

.height_over {
  width: 100%;
  height: 252px;
  overflow-y: auto;

  .doc_content {
      width: 100%;
      img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
      }

      .doc_style {
          border-bottom: 1px solid rgba(232, 232, 232, 1);
          padding: 8px 0 8px 0;
          align-items: center;
          display: flex;
          justify-content:space-between;
          .doc_name {
              display: flex;
              width: 50%;
              padding-left: 10px;
              box-sizing: border-box;
              .doc_flex {
                  width: 142px;
                  display: flex;
                  justify-content: space-between;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
              }
              .name_warp{
                width: calc(100% - 40px);
                .name_style {
                  width: 100%;
                  text-align: left;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
          }

          .doc_status {
              width: 72px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
          }

          .doc_btn {
              width: 162px;
              div{
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
              }
              p{
                padding: 6px 16px;
                border-radius: 16px;
                border: 1px solid #1AB370;
                cursor: pointer;
              }
              p:nth-child(1) {
                  color: #1AB370;
                  margin-right: 6px;
              }

              p:nth-child(2) {
                border-color: #FF4D4F;
                  color: #FF4D4F;
              }

              .disabled{
                  color: rgb(139, 132, 132) !important;
              }
          }
      }
  }
}
.upload-btn-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  .sure_style {
    margin-top: 8px;
    width: 100px;
    background: #00B070;
    border-radius: 16px;
    text-align: center;
      height: 32px;
      line-height: 32px;
      border:none;
      border-radius: 16px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      display: inline;
  }
  .upload-tips{
      margin-top: 8px;
      line-height: 12px;
      font-size: 12px;
      color: #6e6e6e;
  }
}

</style>
