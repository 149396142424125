import Vue from 'vue'
import * as retry from 'retry'

import isobject from 'lodash.isobject'
import {
  QRTCVideoResolution,
  QRTCVideoResolutionMode,
  QRTCVideoFillMode,
  QRTCCodecType
} from "@/meet-sdk/module/rtc/lib/RoomParams";
import { queryDocumentShareData } from '@/api/meet'

import { getAvatar, getAcceptLanguage, getUuid, setMeetInfo } from "@/utils/auth"
import { strToBoolean } from "@/utils/str";
import eventBus from '@/utils/bus'
import { loganLog } from "@/utils/log"
// import { showErrorNotify } from "@/utils/notify"

import { signalUrl } from "@/config/index";
import { LAYOUT_CONFIG, MAX_SHARE_VIDEO_FPS, MAX_AUDIO_TRANSPORT, ROLE_CODE } from "@/constant/index";
import { fetchReconnectionInfo } from '@/api/meet'
import { DIALOG_ERR_CODE, DIALOG_BACK_ERR_CODE, ERR_DATE_MAP,NEED_LOADING_CODE,DEVICE_ERR_CODE} from "@/constant/index"

const MAX_CONF_TIME = 24 * 60 * 60 * 1000

function MeetingManager(vueInstance) {
  this.vue = vueInstance

  this.newTime = new Date().getTime() + 10 * MAX_CONF_TIME //排序基数
  
  this.roomClient = this.vue.$i100MeetSDK
  this.i100MeetingControl = this.roomClient.i100MeetingControl
  this.i100Settings =this.roomClient.i100Settings

  // console.log(this.i100MeetingControl)
  this.localUser = null; // 本地用户

  // 开启本地音视频等待队列
  this._isLocalMediaPending = false;
  this._pendingLocalMedia = [];

  // 订阅远端视频队列配置
  this._pendingRemoteConfig = {};

  // 是否执行reconnect
  this.isLoadReConnect = false;

  this.initEvent()
}


// 初始化方法
MeetingManager.prototype.initEvent = function() {
  // console.error('meetingManager initEvent')
  // 绑定sdk异常处理
  this.handErrorMessage();

  // 绑定本人进入房间后的回调
  this.onEnteredMeeting();

  // 远端有人进入房间后
  this.onRemoteUserEnterMeeting();

  // 入会QueryUserList
  // this.onQueryUserList();

  // 服务端通知 - 远端有人推视频流
  this.onUserVideoAvailable();

  // 远端有人离开房间后
  this.onRemoteUserLeaveMeeting();

  // 服务端通知 - 远程有人推音频流
  this.onUserAudioAvailable();

  // 服务端通知-远程有人开始讲话
  this.userSpeaking();

  // 本地用户共享开始
  this.screenCaptureStarted();

  // 本地用户共享结束
  this.screenCaptureStoped();

  // 服务端通知 - 远程有人推共享流
  this.userShareAvailable();
}

// 进入房间
MeetingManager.prototype.enterRoom = function(options = {}) {
  return new Promise(async (resolve, reject) => {
    const { roomId, peerId, userName, avatarUrl,businessType,hospital } = this.vue.$configs

    const { conferenceToken } = options
    // 2. 进入房间
    try {
      //设置编码参数
      this.setVideoEncoderParam()

      this.i100MeetingControl.setRemoteViewFillMode(
        QRTCVideoFillMode.QRTCVideoFillMode_Fill
      )

      // 进房
      let queData = {
        serverUrl: signalUrl,
        sdkAppId: 1,
        userSig: "",
        userId: peerId,
        userName: userName,
        roomId: roomId,
        maxRetryTime: 30 * 1000,
        avatarUrl: avatarUrl,

        xConferenceToken: conferenceToken, // 获取会议token
        acceptLanguage: getAcceptLanguage()
      }
      if(businessType == 10007 && hospital){
        queData.appData = {
          hospital : hospital
        }
      }
      await this.i100MeetingControl.startMeeting(queData)
      resolve()
    } catch (error) {
      // console.error(11111)
      // console.error(error)
      reject(error)
    }
  })
}


// 设置编码参数
MeetingManager.prototype.setVideoEncoderParam = async function() {
  const quality = await this.i100Settings.getConfig('videoQuality')
  let videoCodeModule
  try {
    videoCodeModule = await this.i100Settings.getConfig('videoCodeModule')
  } catch (error) {
    videoCodeModule = ''
  }
  let videoResolution
  try {
    videoResolution = await this.i100Settings.getConfig('videoResolution')
  } catch (error) {
    videoResolution = ''
  }
  let _VideoResolution 

  if(quality === 1){
    if(videoResolution === 1080){
      _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1920_1080
    }else if(videoResolution === 720){
      _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_1280_720
    }else{
      _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360
    }
  }else{
    _VideoResolution = QRTCVideoResolution.QRTCVideoResolution_640_360 // 16
  }


  let videoFps = 15;
  let videoBitrate = 3000;
  const videoEncoding = await this.i100Settings.getConfig('videoEncoding') //屏幕限制
  if (videoEncoding) {
    const videoLimitValue = await this.i100Settings.getConfig('videoLimitValue') //帧率
    const videoBitrateValue = await this.i100Settings.getConfig('videoBitrateValue') //码率

    videoFps = videoLimitValue
    videoBitrate = videoBitrateValue
  }

  const encoderParams = {
    preferCodec: videoCodeModule === 1? QRTCCodecType.QRTCCodec_AV1 : videoCodeModule === 3? QRTCCodecType.QRTCCodec_VP9: QRTCCodecType.QRTCCodec_H264Base,
    videoResolution:_VideoResolution,
    resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape,
    videoFps: videoFps,
    videoBitrate: videoBitrate,
    minVideoBitrate: 200,
    maxVideoBitrate: videoBitrate,
  }
  console.error('打开视频参数：',videoCodeModule,encoderParams)
  this.i100MeetingControl.setVideoEncoderParam(encoderParams)
};

// 打开本地音频
MeetingManager.prototype.startLocalAudio = function(isUseHuaTong) {
  return this._startLocalMedia("audio", { isUseHuaTong });
};

// 打开本地视频
MeetingManager.prototype.startLocalPreview = function(videoEl) {
  return this._startLocalMedia("video", { videoEl });
};

MeetingManager.prototype._startLocalMedia = function(type, params) {
  return new Promise((resolve, reject) => {
    if (this._isLocalMediaPending) {
      loganLog("要开启本地媒体，先缓存---" + type);

      this._pendingLocalMedia.push({
        type,
        f: this._handleStartlocalMedia,
        params,
        promiseResolve: resolve,
        promiseReject: reject,
      });
    } else {
      // 正常执行
      loganLog(`正常执行开启本地媒体---type:${type}--params:${JSON.stringify(params)}-`);
      this._handleStartlocalMedia(type, params, resolve, reject);
    }
  });
};

MeetingManager.prototype._handleStartlocalMedia = async function(type, p, resolve, reject) {
  this._isLocalMediaPending = true;

  if (type === "audio") {
    loganLog("将要初始化本地音频----");
    console.log(p);

    try {

      // 麦克风
      await this.vue.$deviceControl.initMicDeviceVolume() //初始化音量
      await this.i100MeetingControl.startLocalAudio(!p.isUseHuaTong);
      loganLog("本地音频初始化成功----");

      this._isLocalMediaPending = false
      resolve()
    } catch (error) {
      // this._handlelocalMediaError('audio', error) //音视频错误1.6 SDK异常处理了
      this._isLocalMediaPending = false
      error && loganLog(`本地音频初始化失败----error:${JSON.stringify(error)}`);
      reject(error)
    }
  } else if (type === "video") {
    loganLog("将要开启本地视频--manager--");
    console.log(p);
    
    try {
      //设置编码参数
      await this.setVideoEncoderParam()
      await this.i100MeetingControl.startLocalVideo(p.videoEl);
      loganLog("本地视频开启成功----");
      this._isLocalMediaPending = false

      resolve()
    } catch (error) {
      // this._handlelocalMediaError('video', error) //音视频错误1.6 SDK异常处理了
      this._isLocalMediaPending = false
      loganLog("本地视频开启失败----");
      reject(error)
    }
  }

  if (this._pendingLocalMedia.length > 0) {
    const currentPend = this._pendingLocalMedia[0];

    // 推出数组中的第一个元素
    this._pendingLocalMedia.shift();

    const { type, f, params, promiseResolve, promiseReject } = currentPend;

    f.call(this, type, params, promiseResolve, promiseReject);
  }
};

/**
 * 开启本地音视频的错误处理
 */
MeetingManager.prototype._handlelocalMediaError = function(type, error) {
  /**
   * error.name:
   * 1. NotAllowedError 未开启摄像头/麦克风权限
   * 2. AbortError
   * 3. OverConstrainedError
   * 4. NotReadableError 摄像头/麦克风正在被占用
   */
  const selfId = this.vue.$configs.peerId

  const config = {
    audio: {
      notPermission: this.vue.$t('meeting.audioNotPermission'),
      occupy: this.vue.$t('meeting.audioOccupy'),
      sentryMsg: '开启本地音频失败'
    },
    video: {
      notPermission: this.vue.$t('meeting.videoNotPermission'),
      occupy: this.vue.$t('meeting.videoOccupy'),
      sentryMsg: '开启本地视频失败'
    }
  }
  const targetConfig = config[type]

  let errMsg = this.vue.$t('meeting.operationFailed')
  if (isobject(error) && error.name) {
    const name = error.name
    if (name === 'NotAllowedError') {
      errMsg = targetConfig.notPermission
    } else if (name === 'NotReadableError') {
      errMsg = targetConfig.occupy
    }
  }
  // this.vue.showToast && this.vue.showToast(errMsg)

  const targetUser = this.vue.$store.state.member.userList.find(i => i.userId === selfId)
  this.vue.$sentry.captureException({
    msg: targetConfig.sentryMsg,
    userId: selfId,
    userName: targetUser ? targetUser.userName : '',
    error
  })
}


// 关闭本地音频
MeetingManager.prototype.stopLocalAudio = function() {
  loganLog(`关闭本地音频 stopLocalAudio`)
  return this.i100MeetingControl.stopLocalAudio();
};

// 停止本地视频
MeetingManager.prototype.stopLocalPreview = function() {
  loganLog(`停止本地视频 stopLocalPreview`)
  return this.i100MeetingControl.stopLocalVideo();
};

// 暂停/恢复本地音频
MeetingManager.prototype.muteLocalAudio = async function(pause) {
  const localAudioDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioDeviceId')
  loganLog(`暂停/恢复本地音频,pause:${pause},--localAudioDeviceId:${localAudioDeviceId}`)

  this.vue.$saveLocal("isMeetUseHuaTong",!pause) //储存会中状态
  return this.i100MeetingControl.muteLocalAudio(pause);
};

// 打开或者关闭扬声器
MeetingManager.prototype.setAudioPlayoutVolume = function(val, elements) {
  // 0的话代表关闭扬声器
  // loganLog(`setAudioPlayoutVolume,val:${val}`)
  return this.i100MeetingControl.startLocalSpeaker(elements, val);
};


// 订阅远端用户的音频
MeetingManager.prototype.startRemoteAudio = function(userId, element) {
  return this.i100MeetingControl.startRemoteAudio(userId, element);
};

// 停止订阅远端用户的音频
MeetingManager.prototype.stopRemoteAudio = function(userId) {
  return this.i100MeetingControl.stopRemoteAudio(userId);
};

// 订阅远端用户视频
MeetingManager.prototype.startRemoteVideo = function(userId, element) {
  if (!this._pendingRemoteConfig[userId]) {
    this._pendingRemoteConfig[userId] = {
      isPending: false,
      pendingList: [],
    };
  }

  const pendItem = this._pendingRemoteConfig[userId];

  if (pendItem.isPending) {
    loganLog('订阅远端视频, 先缓存-----')
    pendItem.pendingList.push({
      f: this._startRemoteVideo,
      params: { userId, element },
    });
  } else {
    loganLog('正常订阅远端视频-----');
    this._startRemoteVideo({ userId, element });
  }
};

MeetingManager.prototype._startRemoteVideo = async function(p) {

  const pendItem = this._pendingRemoteConfig[p.userId];

  pendItem.isPending = true;

  try {
    await this.remoteVideoWithRetry(p)
    loganLog('remoteVideoWithRetry成功了----------')
  } catch (error) {
    loganLog('remoteVideoWithRetry失败了----------')
    loganLog(error)
  }

  pendItem.isPending = false;

  if (pendItem.pendingList.length > 0) {
    const currentPend = pendItem.pendingList[0];

    loganLog('推出----------')
    loganLog(currentPend)

    // 推出数组中的第一个元素
    pendItem.pendingList.shift();

    const { f, params } = currentPend;

    f.call(this, params);
  }
};

//清理大画面loading
MeetingManager.prototype.changeMainLoadingClass = function() {
  setTimeout(() => {
    let loadingMainEl = null
    try {
      loadingMainEl = document.getElementById(`main-video-loading-box`)
    } catch (error) {
      loadingMainEl = null
    }
    console.error("loadingMainEl",loadingMainEl)
    loadingMainEl && loadingMainEl.classList.remove('loading-box-show')
  }, 0);
}
MeetingManager.prototype.changeLoadingClass = function(userId,type,isShare) {
  setTimeout(() => {
    let loadingEl = null
    let loadingMainEl = null
    let loadingTab2El = null
    let loadingTopShareEl = null
    try {
      loadingEl = document.getElementById(`video-${userId}`).nextSibling
      if((isShare && loadingEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingEl = null
      }
    } catch (error) {
      loadingEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingMainEl = document.getElementById(`main-video-${userId}`).nextSibling
      if((isShare && loadingMainEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingMainEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingMainEl = null
      }
    } catch (error) {
      loadingMainEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingTab2El = document.getElementById(`tab2-video-${userId}`).nextSibling
      if((isShare && loadingTab2El.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingTab2El.getAttribute("data-iscurrentshare") === 'true')){
        loadingTab2El = null
      }
    } catch (error) {
      loadingTab2El = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    try {
      loadingTopShareEl = document.getElementById(`top-share-${userId}`).nextSibling
      if((isShare && loadingTopShareEl.getAttribute("data-iscurrentshare") === 'false') || (!isShare && loadingTopShareEl.getAttribute("data-iscurrentshare") === 'true')){
        loadingTopShareEl = null
      }
    } catch (error) {
      loadingTopShareEl = null
      // console.error(`元素失败获取loading: ${userId}`)
    }
    // console.error("type:",type)
    // console.error("loadingEl",loadingEl)
    // console.error("loadingMainEl",loadingMainEl)
    // console.error("loadingTab2El",loadingTab2El)
    // console.error("loadingTopShareEl",loadingTopShareEl)
  
    if(type === 'remove'){
      loadingEl && loadingEl.classList.remove('loading-box-show')
      loadingMainEl && loadingMainEl.classList.remove('loading-box-show')
      loadingTab2El && loadingTab2El.classList.remove('loading-box-show')
      loadingTopShareEl && loadingTopShareEl.classList.remove('loading-box-show')
      if(this[`timer${userId}${isShare}`]){
        clearTimeout(this[`timer${userId}${isShare}`])
      }
    }else{
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
      if (targetUser && targetUser.isUseShiPin || isShare && targetUser && targetUser.isShare) {
        loadingEl && loadingEl.classList.add('loading-box-show')
        loadingMainEl && loadingMainEl.classList.add('loading-box-show')
        loadingTab2El && loadingTab2El.classList.add('loading-box-show')
        loadingTopShareEl && loadingTopShareEl.classList.add('loading-box-show')
      }
      if(!this[`timer${userId}${isShare}`]){
        this[`timer${userId}${isShare}`] =  setTimeout(() => {
          loganLog(`90后自动取消loading`)
          this.changeLoadingClass(userId,'remove',isShare)
        }, 90000);
      }
      // console.error("isUseShiPin:",targetUser.isUseShiPin)
    }
   }, 0);
 }

// MeetingManager.prototype.remoteVideoWithRetry = function(p) {
//   return new Promise((resolve, reject) => {
//     const operation = retry.operation({
//       retries: 3,
//       minTimeout: 0
//     });

//     operation.attempt(async (currentAttempt) => {
//       loganLog(`次数-----${currentAttempt}`)

//       /**
//        * 跳出情况：
//        * 1. 用户已经不在房间了
//        * 2. 用户已经关闭了视频
//        */
//       const operateUserId = this.vue.$configs.peerId
//       const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === p.userId))
//       const targetUserName = targetUser ? targetUser.userName : ''

//       if (!targetUser || (targetUser && !targetUser.isUseShiPin)) {
//         loganLog(`用户已经不在房间内或者已经把视频关闭了`)
//         loganLog(targetUser)

//         operation.stop()
//         reject({
//           name: 'notExistError',
//           message: 'does not exist'
//         })
//         return
//       }

//       try {
//         await this.i100MeetingControl.startRemoteVideo(p.userId, p.element)
//         this.changeLoadingClass(p.userId,'remove',false)
//         loganLog(`订阅远端用户的视频成功------userId: ${p.userId}---userName: ${targetUserName}`)
//         operation.stop()
//         resolve()
//       } catch (error) {
//         this.changeLoadingClass(p.userId,'add',false)
//         loganLog(`订阅远端用户的视频失败------userId: ${p.userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)



//         // showErrorNotify(this.vue, {
//         //   type: 'video',
//         //   userId: p.userId,
//         //   userName: targetUserName,
//         //   errorMessage: '' // TODO:
//         // })

//         this.vue.$sentry.captureException({
//           msg: '订阅远端用户的视频失败',
//           userId: p.userId,
//           operateUserId,
//           userName: targetUserName,
//           error
//         })

//         if (!operation.retry(new Error())) { // 已达到最大返回数量
//           loganLog(`订阅视频超过最大重试次数-----${currentAttempt}`)

//           this.vue.$sentry.captureException({
//             msg: '订阅远端用户的视频超出最大重试次数',
//             userId: p.userId,
//             operateUserId,
//             userName: targetUserName
//           })

//           reject({
//             name: 'exceedMixRetryError',
//             message: 'Maximum number of retries exceeded'
//           })
//         }
//       }
//     })
//   })
// }




MeetingManager.prototype.remoteVideoWithRetry = function(p) {
  return new Promise((resolve, reject) => {
    const operation = retry.operation({
      retries: 3,
      minTimeout: 0
    });

    operation.attempt(async (currentAttempt) => {
      loganLog(`retry次数-----${currentAttempt}`)

      /**
       * 跳出情况：
       * 1. 用户已经不在房间了
       * 2. 用户已经关闭了视频
       */
      const operateUserId = this.vue.$configs.peerId
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === p.userId))
      const targetUserName = targetUser ? targetUser.userName : ''

      if (!targetUser || (targetUser && !targetUser.isUseShiPin)) {
        loganLog(`用户已经不在房间内或者已经把视频关闭了`)
        loganLog(targetUser)

        operation.stop()
        reject({
          name: 'notExistError',
          message: 'does not exist'
        })
        return
      }

      try {
        await this.i100MeetingControl.startRemoteVideo(p.userId, p.element)
        this.changeLoadingClass(p.userId,'remove',false)
        //删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(p.userId, 'video')

        loganLog(`订阅远端用户的视频成功------userId: ${p.userId}---userName: ${targetUserName}`)
        operation.stop()
        resolve()
      } catch (error) {
        this.changeLoadingClass(p.userId,'add',false)
        loganLog(`订阅远端用户的视频失败------userId: ${p.userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)


        // showErrorNotify(this.vue, {
        //   type: 'video',
        //   userId: p.userId,
        //   userName: targetUserName,
        //   errorMessage: '' // TODO:
        // })

        // this.vue.$sentry.captureException({
        //   msg: '订阅远端用户的视频失败',
        //   userId: p.userId,
        //   operateUserId,
        //   userName: targetUserName,
        //   error
        // })

        //如果是解码能力不支持不用retry
        // console.error(88888888,error.code,error.code == 80000607)
        if(error.code == 80000607){
          operation.stop()
        }

        if (!operation.retry(new Error())) { // 已达到最大返回数量
          //增加视频重试任务
          if(currentAttempt > 3) {
            loganLog(`订阅视频超过最大重试次数-----${currentAttempt}`)
            this.vue.$exceptionManager.addRetryJob(p.userId, p.element, 'video')
            this.vue.$sentry.captureException({
              msg: '订阅远端用户的视频超出最大重试次数',
              userId: p.userId,
              operateUserId,
              userName: targetUserName
            })
          }

       

          reject({
            name: 'exceedMixRetryError',
            message: 'Maximum number of retries exceeded'
          })
        }
      }
    })
  })
}

// 订阅远端用户的分享
MeetingManager.prototype.startRemoteShare = function(userId, element) {
  const operation = retry.operation({
    retries: 3,
    minTimeout: 0
  })
  operation.attempt(async (currentAttempt) => {
    loganLog(`分享执行次数-----${currentAttempt}`)

    /**
     * 跳出情况：
     * 1. 用户已经不在房间了
     * 2. 用户已经关闭了分享
     */
    const operateUserId = this.vue.$configs.peerId
    const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
    const targetUserName = targetUser ? targetUser.userName : ''

    if (!targetUser || (targetUser && !targetUser.isShare)) {
      loganLog(`用户已经不在房间内或者已经把分享关闭了`)
      loganLog(targetUser)

      operation.stop()
      return
    }

    loganLog(targetUser)

    try {
      await this.i100MeetingControl.startRemoteSharing(userId , element)
      this.changeLoadingClass(userId,'remove',true)
      loganLog(`订阅远端用户的分享成功------userId: ${userId}---userName: ${targetUserName}`)
      operation.stop()
    } catch (error) {
      loganLog(`订阅远端用户的分享失败------userId: ${userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)
      this.changeLoadingClass(userId,'add',true)
      loganLog(`分享失败上报, 并且启动重试-----${currentAttempt}`)

      // showErrorNotify(this.vue, {
      //   type: 'share',
      //   userId,
      //   userName: targetUserName,
      //   errorMessage: '' // TODO:
      // })
  
      if (!operation.retry(new Error())) { // 已达到最大返回数量
        //增加共享重试任务
        if(currentAttempt > 3) {
          loganLog(`订阅分享超过最大重试次数-----${currentAttempt}`)
          this.vue.$exceptionManager.addRetryJob(userId, element, 'share')
          this.vue.$sentry.captureException({
            msg: '订阅远端用户的分享超出最大重试次数',
            userId,
            operateUserId,
            userName: targetUserName
          })
        } 
      }
    }
  })
}



// 停止订阅远端用户的视频
MeetingManager.prototype.stopRemoteVideo = function(userId) {
  loganLog(`停止订阅远端用户的视频 stopRemoteVideo,userId:${userId}`)
  return this.i100MeetingControl.stopRemoteVideo(userId);
};

// 暂停远程用户视频
MeetingManager.prototype.muteRemoteVideo = function(userInfo) {
  loganLog(`暂停远程用户视频 muteRemoteVideo,userId:${userId}`)
  return this.i100MeetingControl.muteRemoteVideo(userInfo.userId, true);
};

// 停止订阅远端用户的所有视频
MeetingManager.prototype.muteRemoteVideoAll = function() {
  loganLog(`停止订阅远端用户的所有视频 muteRemoteVideoAll`)
  return this.i100MeetingControl.muteAllRemoteViews(true);
};

// 结束会议
MeetingManager.prototype.logoutRoom = function(isLeave) {
  loganLog(`离开会议 logoutRoom isLeave:${isLeave}`)
    return this.i100MeetingControl.leaveMeeting(!isLeave);
};

// 通过程序停止共享
MeetingManager.prototype.stopShare = function() {
  loganLog(`通过程序停止共享 stopShare`)
  return this.i100MeetingControl.stopScreenSharing();
};

// 打开共享
MeetingManager.prototype.openShare = async function() {
  loganLog(`打开共享 openShare`)

  let shareFps = MAX_SHARE_VIDEO_FPS;
  let shareResolution = QRTCVideoResolution.QRTCVideoResolution_2560_1440;
  let shareBitrate = 3000;
  
  const shareLimit = await this.i100Settings.getConfig('shareLimit') //屏幕限制
  if (shareLimit) {
    const shareLimitValue = await this.i100Settings.getConfig('shareLimitValue') //帧率
    const shareResolutiontValue = await this.i100Settings.getConfig('shareResolutiontValue') //分辨率
    const shareBitrateValue = await this.i100Settings.getConfig('shareBitrateValue') //码率

    shareFps = shareLimitValue
    shareBitrate = shareBitrateValue
    
    if(shareResolutiontValue == '1280x720'){
      shareResolution = QRTCVideoResolution.QRTCVideoResolution_1280_720
    } else if(shareResolutiontValue == '1920x1080'){
      shareResolution = QRTCVideoResolution.QRTCVideoResolution_1920_1080
    } else {
      shareResolution = QRTCVideoResolution.QRTCVideoResolution_2560_1440
    }
  }
  
  // console.error('开启共享参数',{
  //   preferCodec: QRTCCodecType.QRTCCodec_H264Base,
  //   videoResolution: QRTCVideoResolution.QRTCVideoResolution_2560_1440, // 分辨率枚举
  //   resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape, // 画模式, Landscape: 长大于高, Portrait: 高大于长
  //   videoFps: videoFps, // 帧率 每秒刷多少帧
  //   videoBitrate: 500, // 每秒传多少字节
  //   minVideoBitrate: 300, // 每秒传送最少字节
  //   maxVideoBitrate: 2500, // 每秒传送最大字节 2500
  // })

  return this.i100MeetingControl.startScreenSharing(null, {
    preferCodec: QRTCCodecType.QRTCCodec_H264Base,
    videoResolution: shareResolution, // 分辨率枚举
    resMode: QRTCVideoResolutionMode.QRTCVideoResolutionModeLandscape, // 画模式, Landscape: 长大于高, Portrait: 高大于长
    videoFps: shareFps, // 帧率 每秒刷多少帧
    videoBitrate: shareBitrate, // 每秒传多少字节
    minVideoBitrate: 300, // 每秒传送最少字节
    maxVideoBitrate: shareBitrate, // 每秒传送最大字节 2500
  })

}


MeetingManager.prototype._selfLocalUserHandle = function() {
  let that = this;

  // 更新localUser
  const attendMap = that.vue.$configs.attendMap;
  const attendUserInfo = attendMap[that.localUser.userId];

  if (attendUserInfo) {
    // 合并
    Object.assign(that.localUser, attendUserInfo);
    // 合并完成后，清理当前的数据
    delete attendMap[that.localUser.userId];
  }

  console.error('adduser localUser',that.localUser)

  that.vue.$store.commit("member/addUser", that.localUser);


  that.vue.$store.commit("meet/updateGlobalMeetState", {
    isUseYangShengQi:that.localUser.isUseYangShengQi,
    isUseHuaTong:that.localUser.isUseHuaTong,
    isUseShiPin:that.localUser.isUseShiPin,
  })

  this.i100MeetingControl.setAudioQualityLevel(2)

  // 根据设置打开音频
  const { isUseHuaTong } = that.localUser;
  // console.error(33333,isUseHuaTong)
  that.startLocalAudio(isUseHuaTong);
}


// SDK异常通知处理
MeetingManager.prototype.handErrorMessage = function() {
  let that = this;
  let _eventCount = this.roomClient.listenerCount('errorMsg')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
    return
  }
  that.roomClient.on('errorMsg', async ({ code, errorData }) => {
    // console.error(3333335,code)
    code != '80001005' && loganLog(`SDK异常通知，code:${code} errorData:${JSON.stringify(errorData)}`);
    // console.error(ERR_DATE_MAP[code])
    //如果是设备无法打开上次记录，再次点击弹窗提示
    if(DEVICE_ERR_CODE.includes(code)){
      let key = (code === 80000304 || code === 80000334)? "micNoGrant" : "cameraNoGrant"
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        [key]:ERR_DATE_MAP[code]
      })
    }

    // 外接摄像头切换兼容---切换目标设备如果被其他程序占用，需停止切换前设备推流。bugId: 28834
    if(code === 80000310){
      const peerId = this.vue.$configs.peerId;
      const selfInfo = this.vue.$store.getters["member/getUser"](peerId);

      console.error('摄像头切换至被占用设备时Log信息', { peerId: peerId, userInfo: selfInfo });
      if(this.vue.$store.state.meet.isUseShiPin && selfInfo.userId){
        try {
          await this.stopLocalPreview();
        } catch (error) {
          loganLog(`摄像头切换至被占用设备时，停止上个camera设备推流失败: ${JSON.stringify(error)}`);
        }
      }
    }

    //如果摄像头设备异常更新本人isUseShiPin
    if(code === 80000301 || code === 80000303 || code === 80000332 || code === 80000310){
      const userId = this.vue.$configs.peerId
      that.vue.$store.commit("member/updateUser", {
        userId,
        isUseShiPin: false,
      })
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        isUseShiPin:false,
      })
    }

    //本地麦克风热插拔 目前还是在上层
    // if(code === 80000307){}

    //连续几秒（5/10/15）未收到订阅的指定流的音频包
    if(code === 80000528){
      if(errorData.userId){
        this.vue.$store.commit("member/updateUser",  {
          userId:errorData.userId,
          isUseHuaTongError: true
        })
      }
    }
    //订阅的指令流的音频包重新收到
    if(code === 80000538){
      if(errorData.userId){
        this.vue.$store.commit("member/updateUser",  {
          userId:errorData.userId,
          isUseHuaTongError: false
        })
      }
    }

    //应用层相应视频显示loading状态
    if(NEED_LOADING_CODE.includes(code)){
      if(errorData.userId && !that.localUser.sharePaused ){ //有user ID 且当前不是共享暂停状态
        this.changeLoadingClass(errorData.userId,'add',errorData.share || false)
      }
    }
    //应用层相应视频取消loading状态
    if(code === 80000518){
        if(errorData.userId){
          this.changeLoadingClass(errorData.userId,'remove',errorData.share)
        }
    }

    //av1 不支持类型解码能力不支持
    if(code === 80000607){
        if(errorData.userId){
          this.vue.$store.commit("member/updateUser",  {
            userId:errorData.userId,
            encodingError: true
          })
        }
    }

    // 打开麦克风失败后更改自己的麦克风状态
    if(code === 80000311){
      that.vue.$store.commit('member/updateUser', {
        userId: this.vue.$configs.peerId,
        isUseHuaTong: false
      })
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        isUseHuaTong: false,
      })
    }

    // 摄像头设备trackended
    if(code === 80000312){
      const peerId = this.vue.$configs.peerId;
      const videoEl = document.getElementById(`video-${peerId}`)
      setTimeout(() => {
        loganLog('摄像头trackended，尝试再次打开摄像头');
        this.startLocalPreview(videoEl) 
      }, 1000);
    }

    const meetDialogInfo = {
      tips: ERR_DATE_MAP[code]? ERR_DATE_MAP[code].title : errorData.msg,
      showClose : true,
      isGoIndex : false,
      commonText : `错误码:${code}`,
      describe:ERR_DATE_MAP[code]? ERR_DATE_MAP[code].describe : "",
      isGoLogin: false,
      ensureText: '',
      conferenceNo: '',
      title: ''
    }

    if(DIALOG_ERR_CODE.includes(code)){
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo:{
          ...meetDialogInfo,
          isGoIndex : false,
          showClose : true
        }
      })
    }
    
    if(DIALOG_BACK_ERR_CODE.includes(code)){
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo:{
          ...meetDialogInfo,
          showClose : false,
          isGoIndex : true
        }
      })
    }
  });
};

// 1.本人进入房间后
MeetingManager.prototype.onEnteredMeeting = function() {
  let that = this;

  let _eventCount = this.roomClient.listenerCount('onEnteredMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  that.roomClient.on("onEnteredMeeting", async (selfTimeMs) => {
    if (that.isLoadReConnect) {
      loganLog("断线重连，enterRoom成功, 需要调用reconnection");

      console.error('断线前的音视频等待队列情况---------');
      console.log(that._isLocalMediaPending)
      console.log(JSON.stringify(that._pendingLocalMedia))
      console.log(JSON.stringify(that._pendingRemoteConfig))
      
      //清空重试任务
      this.vue.$exceptionManager.deleteRetryJobAll()


      // 清空之前的音视频等待队列
      that._isLocalMediaPending = false
      that._pendingLocalMedia = []
      that._pendingRemoteConfig = {}

      // 清空attendMap
      that.vue.$configs.attendMap = {};

      // 断线重连前是否有人再共享
      const isExitUserShare = that.vue.$store.getters["member/getRealUserList"].find(i => i.isShare)
      if (isExitUserShare) {
        loganLog("断线前有人再共享");
        // window._isReconnectionShare = true
      } else {
        console.error('断线前没有人再共享')
      }

      // 清空member
      that.vue.$store.commit("member/disconnectedReset")
      eventBus.$emit('resetOldPageList')

      // 重置部分全局状态
      that.vue.$store.commit("meet/updateGlobalMeetState", {
        shareScale: "",
        isNetworkDisconnect: false,
      });


      // 本人状态相关
      if (that.localUser.isShare) {
        // 如果用户之前再共享，那需要给出提示
        that.vue.shareStopDialog = true;
      }
      that.localUser.isShare = false; // 是否共享
      that.localUser.sharePaused = false; // 是否共享暂停中
      that.localUser.isSpeaking = false;
      
      let isUseYangShengQi = that.vue.$loadLocal("isUseYangShengQi");
      let _isUseYangShengQi = that.vue.$loadLocal("isMeetUseYangShengQi");
      if (_isUseYangShengQi) isUseYangShengQi = _isUseYangShengQi
      that.isUseYangShengQi = strToBoolean(isUseYangShengQi, true), // 是否开启扬声器



      that.localUser.isDbClick = false;
      that.localUser.timeMs = selfTimeMs;
      that.localUser.isShareDoc = false;//是否云共享

      that.localUser._old = null;

      const { userId, conferenceNo, roomId, peerId } = that.vue.$configs;

      loganLog('重新调用reconnection恢复状态')


      try {
        const resData = await fetchReconnectionInfo({
          userId: userId,
          deviceId: getUuid(),
          conferenceNo: conferenceNo,
          roomId: roomId
        })
        const { attendList, conference, roleCode } = resData;

        loganLog('断网重连之后调用reconnection成功了-------------')


        // 如果是自己发去的云共享同步一下状态
        if(this.vue.$store.state.meet.isShareDoc){
          loganLog(`断网重连之后调用翻页同步`)
          this.vue.$documentManager.resumePageJump()
        }
        

        //如果是美颜预览的设置页面，关闭设置
        if (that.vue.$store.state.isShowSettingPanel && [2,3,4].includes(that.vue.$store.state.settingPanelDefaultIndex)) {
          that.vue.$store.commit("isShowSettingPanel", false)
        }


        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        that.vue.$router.replace({
          query: {
            ...that.vue.$route.query,
            conferenceNo: conference.conferenceNo
          }
        })
        let preFocusScreen = this.vue.$store.state.meet.isFocusScreen
        let isFocusScreen = 0
    
        // 这里不用存储attendList，直接更新对应的user属性即可
        if (Array.isArray(attendList) && attendList.length > 0) {
          attendList.forEach((user) => {

            // raiseHandStatus 举手状态 1:举手 0：手放下
            // recordStatus 录制状态 1：已录制  0：停止录制
            const { peerId, raiseHandStatus, recordStatus, roleCode,focusStatus } = user

            const stateInfo = {
              roleCode: Number(roleCode),
              isRaiseHand: !!raiseHandStatus,
              isRecord: !!recordStatus
            }

            that.vue.$store.commit("member/updateUser", {
              userId: peerId,
              ...stateInfo
            })
            if(focusStatus === 1){
              isFocusScreen = peerId
            }
          })
        }

        // 存储全局会议状态
        that.vue.$store.commit("meet/updateGlobalMeetState", {
          allowEarlyEntry: conference.allowEarlyEntry,
          muteJoinMeeting: conference.muteJoinMeeting,
          playTips: conference.playTips,
          allowSelfUnmute: conference.allowSelfUnmute,
          ownerPasswordEnable: conference.ownerPasswordEnable,
          passwordEnable: conference.passwordEnable,
          agendaPermission: conference.agendaPermission,
          allMuteState: conference.allMuteState,
          recordPermission: conference.recordPermission,
          sharePermission: conference.sharePermission,
          lockedState: conference.lockedState,

          ownerName: conference.ownerName,
          ownerId: conference.ownerId,
          links: conference.links,
          userRoleCode: Number(roleCode),
          meetName: conference.title,
          password:conference.password,
          isFocusScreen:isFocusScreen,
          cloudRecorState:conference.cloudRecordState
        });
        

        if(isFocusScreen!=0){
          try { //开启焦点画面
            loganLog('断网后开启焦点画面')
            await this.handleFocusScreen(isFocusScreen);
          } catch (error) {
            loganLog(`开启焦点画面失败---_id${isFocusScreen}---error${error}`)
          }
        }else{
          //如果是在断网期间取消了焦点画面
          let _userlist = this.vue.$store.state.member.userList
          loganLog(`断网后判断焦点画面---preFocusScreen:${preFocusScreen}`)
          if (preFocusScreen !=0 &&  _userlist.find((i) => i.isTopShare) && !_userlist.find((i) => i.isDbClick)) { // 存在共享画面且没有锁定画面
            window._isClickTopShare = true
            this.vue.$store.commit('member/removeShareView')
          }
        }
        
        // // 更新个人状态 //2024/5/16 断网重连还是使用之前的麦克风状态
        // // 是否开启话筒, 这里不在区分是主持人还是普通用户，allMuteState

        // if (!conference.allMuteState) {
        //   console.error("不去处理，还是保持原来的状态---");
        //   console.log(that.localUser.isUseHuaTong);
        // } else {
        //   that.localUser.isUseHuaTong = false;

        //   that.vue.$store.commit("member/updateUser", { 
        //     userId,
        //     isUseHuaTong: false,
        //   })
        //   that.vue.$store.commit("meet/updateGlobalMeetState", {
        //     isUseHuaTong: false,
        //   })
        // }
        
        that._selfLocalUserHandle() //开启话筒还是在fetchReconnectionInfo 之后

        that.localUser.roleCode = Number(roleCode) 
        that.vue.$store.commit("member/updateUser", {
          userId,
          roleCode: Number(roleCode),
        })

        
        
        //保证断网重连或者重新入会后再执行set
        try {
          await this.vue.$deviceControl.setCurrentMicDevice(null,true,true) //强制初始化一次
          loganLog("断网重连后setCurrentMicDevice成功----");
        } catch (error) {
          loganLog(`断网重连后setCurrentMicDevice失败------error:${JSON.stringify(error)}`);
        }


        //监控音量leavels
        that.getAudioLevels()
      } catch (error) {
        loganLog(`enterRoomAfter调用reconnection失败--------error:${JSON.stringify(error)}---`);

        that.vue.$store.commit("meet/updateGlobalMeetState", {
          meetDialogInfo: {
            isGoIndex: true,
            showClose: false,
            commonText: that.vue.$t('meeting.enterError')
          }
        })
      }
    } else { // 不需要，为第一次进入房间
      that.isLoadReConnect = true;

      loganLog("第一次进入房间,meet组件处理reconnection");

      // todo 断网图标手动置为false，都已经enter room成功了。 原因是 断网下离会底层没清理状态。还是会通知disconnected
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        isNetworkDisconnect: false
      })

      const { peerId, userName, timeMs,businessType,hospital } = that.vue.$configs;
      const { muteJoinMeeting, allMuteState, userRoleCode } = that.vue.$store.state.meet;

      let isUseHuaTong = that.vue.$loadLocal("isUseHuaTong");
      let isUseShiPin = that.vue.$loadLocal("isUseShiPin");
      let isUseYangShengQi = that.vue.$loadLocal("isUseYangShengQi");

      if(that.vue.preRouterName){ //会中的操作记忆，如果是当前页刷新 使用会中最新的记忆
        that.vue.$saveLocal("isMeetUseHuaTong", isUseHuaTong);
        that.vue.$saveLocal("isMeetUseShiPin", isUseShiPin);
        that.vue.$saveLocal("isMeetUseYangShengQi", isUseYangShengQi);
      }else{
        let _isUseHuaTong = that.vue.$loadLocal("isMeetUseHuaTong");
        let _isUseShiPin = that.vue.$loadLocal("isMeetUseShiPin");
        let _isUseYangShengQi = that.vue.$loadLocal("isMeetUseYangShengQi");
        if (_isUseHuaTong) isUseHuaTong = _isUseHuaTong
        if (_isUseShiPin) isUseShiPin = _isUseShiPin
        if (_isUseYangShengQi) isUseYangShengQi = _isUseYangShengQi
      }
      
      
      let  weight = this.getUserWeight(timeMs,strToBoolean(isUseShiPin, false) ) //本人需要排到第一

      // 设置自己用户对象初始化信息
      that.localUser = {
        userId: peerId,
        userWeight: weight,//用户排序权重
        userName: userName, // 用户名称
        avatarUrl: getAvatar(), // 头像信息

        isUseShiPin: strToBoolean(isUseShiPin, false), // 是否开启视频
        isUseYangShengQi: strToBoolean(isUseYangShengQi, true), // 是否开启扬声器
        isShare: false, // 是否共享
        sharePaused: false, // 是否共享暂停中
        isUseHuaTongError:false, //话筒放状态是否error
        isSpeaking: false,
        roleCode: userRoleCode,
        isRaiseHand: false,
        isRecord: false,
        recordPaused: false,
        isDbClick: false,
        timeMs: selfTimeMs,
        hospital: businessType == 10007 && hospital ? hospital : "",
        _old: null
      }
      // 是否开启话筒, 这里不在区分是主持人还是普通用户，统一取决于muteJoinMeeting和allMuteState
      if (!muteJoinMeeting && !allMuteState) {
        that.localUser.isUseHuaTong = strToBoolean(isUseHuaTong, true);
      } else {
        if(that.vue.preRouterName){ //会中的操作记忆，如果是当前页刷新 使用会中最新的记忆
         //如果没有pre页面 说明书刷新当前页面
         // 主持人设置了入会静音
         console.error("直接命中了静音-----");
         that.localUser.isUseHuaTong = false;
         that.vue.$saveLocal("isMeetUseHuaTong", false);
        }else{
         console.error("直接命中了当前页面刷新-----isUseHuaTong:",isUseHuaTong);
         that.vue.$saveLocal("isMeetUseHuaTong", isUseHuaTong);
         that.localUser.isUseHuaTong = strToBoolean(isUseHuaTong, true) // 是否开启麦克风; 
        }
      }
      //神策login
      // this.vue.$sensors.login(peerId)

      //是否云共享
      that.localUser.isShareDoc = false,//是否云共享


      that._selfLocalUserHandle()

      //监控音量leavels
      that.getAudioLevels()
    }
  });
};
MeetingManager.prototype.getAudioLevels = function() {
  clearInterval(this.getAudioLevelsTimer)

  this.getAudioLevelsTimer = setInterval(() => {
    let _leavels =  this.i100MeetingControl.getAudioLevels()
    // console.error(11111,JSON.stringify(_leavels))

    // this.$store.commit('member/updateUser', {
    //   userId,
    //   isUseHuaTong: true
    // })
  }, 500);

}

// 2.服务端通知 - 远端有人进入房间后
MeetingManager.prototype.onRemoteUserEnterMeeting = function() {
  let _eventCount = this.roomClient.listenerCount('onRemoteUserEnterMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }

  this.roomClient.on("onRemoteUserEnterMeeting", async (userInfo) => {
    console.error('onRemoteUserEnterMeeting',userInfo)
    let  weight = this.getUserWeight(userInfo.timeMs,false)
    const user = {
      userId: userInfo.userId,
      userName: userInfo.userName,
      avatarUrl: userInfo.avatarUrl,
      userWeight: weight,//用户排序权重
      isUseHuaTong: false, // 是否开启话筒
      isUseHuaTongError: false, // 话筒状态是否error
      isUseShiPin: false, // 是否开启视频
      isUseYangShengQi: false, // 是否开启扬声器
      isShare: false, // 是否共享中
      sharePaused: false, // 是否共享暂停中
      isSpeaking: false, // 是否说话中
      isShareDoc:false,//是否云共享
      encodingError:false,//编码能力不支持
      roleCode: 0,
      isRaiseHand: false,
      isRecord: false,
      recordPaused: false,
      isDbClick: false,
      timeMs: userInfo.timeMs, // 入会时间
      hospital: userInfo.appData && userInfo.appData.hospital ? userInfo.appData.hospital : "",
      userDevice: userInfo.userDevice || {},
      _old: null,
    }
    
    // 更新user数据
    const attendMap = this.vue.$configs.attendMap;
    const attendUserInfo = attendMap[user.userId];
    if (attendUserInfo) {
      // 合并
      Object.assign(user, attendUserInfo);

      // 合并完成后，清理当前的数据
      delete attendMap[user.userId];
    }

    // 新增用户

    console.error('adduser',user)
    this.vue.$store.commit("member/addUser", user);
    
    //开启了焦点画面
    let isFocusScreenOnly = this.vue.$store.state.meet.isFocusScreenOnly
    // console.error(888888,isFocusScreenOnly)
    if(isFocusScreenOnly != 0){
      try { //开启焦点画面
        this.vue.$store.commit("meet/updateGlobalMeetState", {
          isFocusScreenOnly: 0
        })

        loganLog('新成员入会后list大于2后开启焦点画面')
        await this.handleFocusScreen(isFocusScreenOnly);
      } catch (error) {
        loganLog(`开启焦点画面失败---_id${isFocusScreen}---error${error}`)
      }
    }


    // 播放提示音
    try {
      const { playTips } = this.vue.$store.state.meet;

      if (playTips && userInfo.timeMs > this.localUser.timeMs && userInfo.userId.indexOf('cloudshare') == -1) {
        console.log("播放提示音-----");
        const tipAudioEl = document.getElementById("userEnterTipAudio");
        tipAudioEl && tipAudioEl.play();
      }
    } catch (error) {
      console.log(error);
    }
  })
}

//queryUser信息
MeetingManager.prototype.onQueryUserList = function() {
  let _eventCount = this.roomClient.listenerCount('queryUserList')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }

  this.roomClient.on("onQueryUserList", async (userInfo) => {
    console.error('onQueryUserList',userInfo)
    userInfo.forEach(item=>{
      this.vue.$store.commit("member/updateUser", {
        userId:item.peerId,
        userDevice: item.device,
      });
    })
  })
}

// 4.服务端通知 - 远端有人离开房间后
MeetingManager.prototype.onRemoteUserLeaveMeeting = function() {
  let _eventCount = this.roomClient.listenerCount('onRemoteUserLeaveMeeting')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onRemoteUserLeaveMeeting",async (userInfo) => {
    const { userId } = userInfo
    loganLog(`onRemoteUserLeaveMeeting-----userId:${userId}`)
    // 在移除之前，判断当前离开用户是否为大画面的锁定用户
    // 判断是否为焦点用户离开且存在共享且当前不存在锁定
    const isLockUser = this.vue.$store.getters["member/getLockUser"]
    let _userlist = this.vue.$store.state.member.userList
    let _isFocusScreen = userId == this.vue.$store.state.meet.isFocusScreen

    //清除对应的loading
    this.changeLoadingClass(userId,'remove',false)
    this.changeLoadingClass(userId,'remove',true)
    
    if (isLockUser && (isLockUser.userId === userId) || _isFocusScreen && _userlist.find((i) => i.isTopShare) && !_userlist.find((i) => i.isDbClick)) {
      console.error("锁定用户离开房间了，这时候要移除topshare------");
      this.vue.$store.commit("member/removeShareView");
    }

    if(_isFocusScreen){ //如果退出的用户是焦点画面，重置焦点画面
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        isFocusScreen: 0
      })
    }
    
    //如果是云共享用户离会
    if(userId.indexOf('cloudshare') > -1) {
      let _shareUser = this.vue.$store.state.meet.docShareUserId
      
      _shareUser && this.vue.$store.commit("member/updateUser", {
        userId:_shareUser,
        isShareDoc: false
      });
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        docShareUserId: null,
      });
    }


    // 在userList移除离开房间的用户
    this.vue.$store.commit("member/removeUser", userInfo)

    // 如果是最后一个人切换9宫格 并且本人没开共享
    const { layoutType,isFullModel } = this.vue.$store.state.meet;
    if(!this.localUser.isShare && !isFullModel && this.vue.$store.state.member.userList.length <=1 && (layoutType === LAYOUT_CONFIG.COLUMN || layoutType === LAYOUT_CONFIG.ROW)){
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.GALLERY9
      })
      if(this.vue.$store.state.meet.isFocusScreen != 0){ //如果切换回一个人的会场需要重置焦点画面isFocusScreenOnly
        this.vue.$store.commit("meet/updateGlobalMeetState", {
          isFocusScreenOnly: this.vue.$store.state.meet.isFocusScreen
        })
      }
    }

    // 在speakList中移除离开房间的用户
    this.vue.$store.commit("member/removeSpeak", userInfo)

    // 判断lastSpeaker是否为当前离开房间的用户
    const lastSpeaker = this.vue.$store.state.member.lastSpeaker
    if (lastSpeaker && lastSpeaker.userId === userId) {
      this.vue.$store.commit("member/updateLastSpeaker", null)
    }

    // 在audioList中移除用户
    this.vue.$store.commit("member/removeAudio", userInfo)

    // 删除顶部共享画面
    const isTopShare = this.vue.$store.getters["member/getTopShare"]
    if (isTopShare && isTopShare.userId === userId) {
      console.error("存在锁定的共享画面，将要移除------");
      this.vue.$store.commit("member/removeShareView");
    }

    // reset媒体标签
    const audioEl = document.getElementById(`audio-${userId}`)
    const videoListEl = document.getElementById(`video-${userId}`)
    const videoMainEl = document.getElementById(`main-video-${userId}`)
    const videoTab2El = document.getElementById(`tab2-video-${userId}`)
    const topShareEl = document.getElementById(`top-share-${userId}`)

    audioEl && (audioEl.srcObject = null)
    videoListEl && (videoListEl.srcObject = null)
    videoMainEl && (videoMainEl.srcObject = null)
    videoTab2El && (videoTab2El.srcObject = null)
    topShareEl && (topShareEl.srcObject = null)
  });
};

// 5.服务端通知 - 远程有人推音频流
MeetingManager.prototype.onUserAudioAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserAudioAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserAudioAvailable", async (audioInfo) => {
    console.error("-----onUserAudioAvailable----------");
    console.log(audioInfo)

    if(!audioInfo || !audioInfo.userId || audioInfo.userId.indexOf('cloudshare')>0 && this.vue.$store.state.meet.isShareDoc) return; //忽略本人的云共享音频 
    
    // available: true 开启音频, false: 暂停音频
    let { userId, available } = audioInfo;
    loganLog(`userAudioAvailable-----userId:${userId}---available:${available}`)
    let user = this.vue.$store.getters["member/getUser"](userId)

    if (user.userId) {
      this.vue.$store.commit("member/updateUser", {
        userId,
        isUseHuaTong: available,
        isUseHuaTongError:false //每次订阅需要重新判断话筒状态
      });

      if (available) {
        console.error('准备订阅音频，先判断是否超过阈值--------')

        const { audioList } = this.vue.$store.state.member

        if (audioList.length < MAX_AUDIO_TRANSPORT) { // 没有超过阈值，正常订阅
          console.error('没有超过阈值，先新增或者修改audio，再订阅音频流');

          const targetAudio = audioList.find(i => i.userId === userId)

          if (targetAudio) { // 已经存在audioList中，只更新状态即可
            this.vue.$store.commit("member/updateAudio", {
              userId,
              action: 'start'
            })
          } else { // 新增
            this.vue.$store.commit("member/addAudio", {
              userId,
              action: 'start'
            })
          }

          this.vue.$nextTick(async () => {
            // 设置扬声器
            this._handleAudioPlayout(userId)

            try {
              await this._handleRemoteAudio(userId)
            } catch (error) {
              console.log(error)
            }
          })
        } else { // 超过了阈值
          console.error('超过了阈值------')

          const targetAudio = audioList.find(i => i.userId === userId)

          if (targetAudio) { // 已经存在audioList中，只更新状态即可
            console.error('超过了阈值，但是已经存在audioList中了，这时候只更新状态即可')

            this.vue.$store.commit("member/updateAudio", {
              userId,
              action: 'start'
            })
          } else { // 不存在，先置换，后新增
            console.error('useAudioAva超过了阈值，也不存在audiolist中，这时候进行置换处理------')

            const targetRemoveAudio = this._getRemoveAudioItem(audioList)

            if (targetRemoveAudio) { // 停掉当前的音频通道
              try {
                await this.stopRemoteAudio(targetRemoveAudio.userId)
              } catch (error) {
                console.log(error)
              }

              console.log('useAudioAva停止音频成功----');

              // 删除auidoList中的指定对象
              this.vue.$store.commit("member/removeAudio", {
                userId: targetRemoveAudio.userId
              })

              const audioEl = document.getElementById(`audio-${targetRemoveAudio.userId}`)
              audioEl && (audioEl.srcObject = null)
            }


            // 将audio对象新增到audioList中
            this.vue.$store.commit("member/addAudio", {
              userId,
              action: 'start'
            })

            // 拉取音频流
            this.vue.$nextTick(async () => {
              // 设置扬声器
              this._handleAudioPlayout(userId)

              try {
                await this._handleRemoteAudio(userId)

                console.log('useAudioAva订阅音频成功----');
              } catch (error) {
                console.log(error)
              }
            })
          }
        }
      } else {
        console.error('远端成员关闭音频，停止订阅，并移出数组')

        // 停掉当前的音频通道
        try {
          await this.stopRemoteAudio(userId)
        } catch (error) {
          console.log(error)
        }
        // 删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(userId, 'audio')


        // 删除auidoList中的指定对象
        this.vue.$store.commit("member/removeAudio", {
          userId
        })

        const audioEl = document.getElementById(`audio-${userId}`)
        audioEl && (audioEl.srcObject = null)
      }
    }
  })
}

//获取音频等级
MeetingManager.prototype.getAudioLevelsFn = function (){
  try {
    const audioLevels = this.i100MeetingControl
    ? this.i100MeetingControl.getAudioLevels()
    : {};
    this.vue.$store.commit("meet/updateGlobalMeetState", {
      realTimevolumeData: {...audioLevels},
    });
  } catch (error) {
    loganLog(`获取实时音量错误--error:${error}`)
  }
};



MeetingManager.prototype._handleRemoteAudio = function(userId) {
  const audioEl = document.getElementById(`audio-${userId}`)

  return new Promise(async (resolve, reject) => {
    const operation = retry.operation({
      retries: 3,
      minTimeout: 0
    });

    operation.attempt(async (currentAttempt) => {
      loganLog(`音频次数-----${currentAttempt}`)

      /**
       * 跳出情况：
       * 1. 用户已经不在房间了
       * 2. 用户已经关闭了音频
       */
      const operateUserId = this.vue.$configs.peerId
      const targetUser = this.vue.$store.state.member.userList.find(i => !i.isTopShare && (i.userId === userId))
      const targetUserName = targetUser ? targetUser.userName : ''

      if (!targetUser || (targetUser && !targetUser.isUseHuaTong)) {
        loganLog(`用户已经不在房间内或者已经把音频关闭了`)
        loganLog(targetUser)

        operation.stop()
        reject({
          name: 'notExistError',
          message: 'does not exist'
        })
        return
      }

      try {
        await this.startRemoteAudio(userId, audioEl)
        loganLog(`订阅远端用户的音频成功------userId: ${userId}---userName: ${targetUserName}`)
        //删除重试任务
        this.vue.$exceptionManager.deleteRetryJob(userId, 'audio')

        // 更改成员话筒是否为error状态
        this.vue.$store.commit("member/updateUser",  {
          userId,
          isUseHuaTongError: false
        })
        operation.stop()
        resolve()
      } catch (error) {
        loganLog(`订阅远端用户的音频失败------userId: ${userId}---userName: ${targetUserName}---error: ${JSON.stringify(error)}`)
        // 更改成员话筒是否为error状态
         this.vue.$store.commit("member/updateUser",  {
          userId,
          isUseHuaTongError: true
        })
        // 上报
        this.vue.$sentry.captureException({
          msg: '订阅远端用户的音频失败',
          userId,
          operateUserId,
          userName: targetUserName,
          error
        })

        // 弹窗提示
        // showErrorNotify(this.vue, {
        //   type: 'audio',
        //   userId,
        //   userName: targetUserName,
        //   errorMessage: '' // TODO:
        // })

        loganLog(`订阅音频失败, 启动重试-----${currentAttempt}`)

        if (!operation.retry(new Error())) { // 已达到最大返回数量
          if(currentAttempt > 3) {
            loganLog(`订阅音频超过最大重试次数-----${currentAttempt}`)
            //增加音频重试任务
            this.vue.$exceptionManager.addRetryJob(userId, audioEl, 'audio')

            this.vue.$sentry.captureException({
              msg: '订阅远端用户的音频超出最大重试次数',
              userId,
              operateUserId,
              userName: targetUserName
            })
          }

          reject({
            name: 'exceedMixRetryError',
            message: 'Maximum number of retries exceeded'
          })
        }
      }
    })
  })
}


// 3.服务端通知 - 远端有人推视频流
MeetingManager.prototype.onUserVideoAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserVideoAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserVideoAvailable", async (videoInfo) => {
    // console.log(videoInfo);

    let { userId, available} = videoInfo;
    loganLog(`onUserVideoAvailable-----userId:${userId}---available:${available}`)

    //视频流取消load
    this.changeLoadingClass(userId,'remove',false) 

    // 删除重试任务
    this.vue.$exceptionManager.deleteRetryJob(userId, 'video')

    //数据变化放在对象里 3s一执行
    // this.updateUserList[userId] = available
    
    let targetUser = this.vue.$store.getters["member/getUser"](userId)
    
    let weight = this.getUserWeight(targetUser.timeMs,available)
  
    this.vue.$store.commit("member/updateUser", {
      userId,
      isUseShiPin: available,
      userWeight: weight,
      encodingError:false
    })
  });
};

//获取用户排序权重
MeetingManager.prototype.getUserWeight = function(timeMs,isShipin = false) {
  let shipinWeight = 0

  if(isShipin){
    const nowTime = new Date().getTime()
    shipinWeight = this.newTime - nowTime
    shipinWeight = shipinWeight > 0 ? shipinWeight + MAX_CONF_TIME * 5 : MAX_CONF_TIME * 5
  }
  let weight = this.newTime - timeMs
  weight = weight > 0 ? weight : 0
  // console.error('11111nowTime2',videoInfo.userWeight,shipinWeight, weight ,videoInfo.userWeight - weight > MAX_CONF_TIME)
  // console.error('排序权重--isShipin:',isShipin,'--shipinWeight:',shipinWeight,'--weight:',weight,'--timeMs:',timeMs)
  if(isShipin){
    return shipinWeight
  }else{
    return weight
  }
}

// 设置定时任务
MeetingManager.prototype.setUpdateUserListTimer = function() {
  clearTimeout(this.updayaTimer)
  this.updayaTimer = setInterval(() => {
    if(this.updateUserList.length < 1){
      return false
    }
    let item
    while(item = this.updateUserList.shift()){ 
      if(!item){
        continue
      }
      // console.error(78787878,item)

      let user = this.vue.$store.getters["member/getUser"](userId);
      if (user.userId) {
        this.vue.$store.commit("member/updateUser", {
          userId,
          isUseShiPin: available,
        })
      }

    }
    }, 3000);
}


// 6.服务端通知-远程有人开始讲话
MeetingManager.prototype.userSpeaking = function() {
  let _eventCount = this.roomClient.listenerCount('userSpeaking')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  
  this.roomClient.on("userSpeaking", async (speakingInfo) => {
    const { userId, action } = speakingInfo;

    console.error('-----userSpeaking-----')
    console.log(speakingInfo)

    const user = this.vue.$store.getters["member/getUser"](userId)

    if (user.userId) {
      // 其他逻辑
      if (action === "unmute") {
        // 添加到用户发言列表
        // console.error("添加发言人------------")
        // console.error(user)

        this.vue.$store.commit("member/addSpeak", user)
        this.vue.$store.commit("member/updateLastSpeaker", user)
      }

      if (action === "stop") {
        // 移除
        // console.error("删除发言人------------")
        // console.error(user)

        this.vue.$store.commit("member/removeSpeak", {
          userId: userId
        })
      }

      if (userId !== this.vue.$configs.peerId) { // 排除自己
        // 置换策略
        this.vue.$store.commit("member/updateAudio", {
          userId,
          action
        })

        // console.error('userSpeaking--audioList--', JSON.stringify(this.vue.$store.state.member.audioList))

        if (action === 'start' || action === 'unmute') {
          const { audioList } = this.vue.$store.state.member
          const isInAudioList = audioList.find(i => i.userId === userId)

          if (isInAudioList) { // 当前的发言人已经在audioList中了，说明已经简历了音频通道，这时候不用处理。
            console.error('说话的人已经建立过音频通道了')

            // console.error('audioList---', JSON.stringify(audioList))
          } else { // 当前发言人没有在audioList中，这时候要进行插入或者置换
            console.error('当前发言人没有在audioList中----');

            if (audioList.length < MAX_AUDIO_TRANSPORT) { // 没有超过阈值，正常插入
              console.error('此时没有超过阈值，执行插入逻辑')
            } else { // 超过阈值，先置换，再插入
              console.error('超过阈值了，要先置换')

              const targetRemoveAudio = this._getRemoveAudioItem(audioList)
              console.error('当前的targetRemoveAudio--', targetRemoveAudio)

              if (targetRemoveAudio) {
                console.error('将要停止订阅音频流--', JSON.stringify(targetRemoveAudio))

                // 停掉当前的音频通道
                try {
                  await this.stopRemoteAudio(targetRemoveAudio.userId)
                } catch (error) {
                  console.log(error)
                }

                console.error('speaker停止订阅成功--')

                // 删除auidoList中的指定对象
                this.vue.$store.commit("member/removeAudio", {
                  userId: targetRemoveAudio.userId
                })

                const audioEl = document.getElementById(`audio-${targetRemoveAudio.userId}`)
                audioEl && (audioEl.srcObject = null)
              }
            }

            // 将audio对象新增到audioList中
            this.vue.$store.commit("member/addAudio", {
              userId,
              action
            })

            // 拉取音频流
            this.vue.$nextTick(async () => {
              // 设置扬声器
              this._handleAudioPlayout(userId)

              try {
                await this._handleRemoteAudio(userId)
              } catch (error) {
                console.log(error)
              }
            })
          }
        } else {
          console.error('当前action不做处理----')
        }
      } else {
        console.error('语音激励命中了自己，不做任何处理---')
      }
    }
  })
}

MeetingManager.prototype._getRemoveAudioItem = function(audioList) {
  // const isNotAvailable = audioList.find(i => !i.available)
  // if (isNotAvailable) { // 存在available: false
  //   console.error('选到ava: false');
  //   return isNotAvailable
  // }

  const isAudioStop = audioList.find(i => i.action === 'stop')
  if (isAudioStop) { // 存在action: stop
    return isAudioStop
  }

  const isAudioMute = audioList.find(i => i.action === 'mute')
  if (isAudioMute) { // 存在action: mute
    return isAudioMute
  }

  const isAudioStart = audioList.find(i => i.action === 'start')
  if (isAudioStart) { // 存在action: start
    return isAudioStart
  }

  const isAudioUnmute = audioList.find(i => i.action === 'unmute')
  if (isAudioUnmute) { // 存在action: unmute
    return isAudioUnmute
  }

  return null
}


// 7.本地用户共享开始
MeetingManager.prototype.screenCaptureStarted = function() {
  let _eventCount = this.roomClient.listenerCount('onScreenCaptureStarted')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onScreenCaptureStarted", async () => {
    console.log("------收到本地用户的共享开始回调-------");

    // 将所有用户的dobclcik重置为false
    const userList = this.vue.$store.getters["member/getRealUserList"];
  

    // 更新vuex中自己的共享状态为false
    this.vue.$store.commit("member/updateUser", {
      userId: this.vue.$configs.peerId,
      isShare: true,
    });

    const { layoutType } = this.vue.$store.state.meet;

    // 将所有用户的dbClick重置为false
    userList.forEach((i) => {
      if(i.isDbClick){
        this.vue.$store.commit("member/updateUser", {
          userId: i.userId,
          isDbClick: false,
        });
      }
    });


    if (
      layoutType === LAYOUT_CONFIG.GALLERY9 ||
      layoutType === LAYOUT_CONFIG.GALLERY25
    ) {
      // 如果当前处在宫格模式，则要切换到演讲者模式
      console.log("九宫格或者25宫格111，自动转换到演讲这个");

      try {
        console.error("首先停止订阅所有远端视频");
        await this.muteRemoteVideoAll();
        this.changeLoadingClass(this.vue.$configs.peerId,'remove',false)

        // 自动转到成员列表格式
        this.vue.$store.commit("meet/updateGlobalMeetState", {
          layoutType: LAYOUT_CONFIG.COLUMN,
        });
      } catch (error) {
        this.changeLoadingClass(this.vue.$configs.peerId,'add',false)
        console.log(error);
      }
    }
     //开启了焦点画面
    let isFocusScreen = this.vue.$store.state.meet.isFocusScreen
     if (isFocusScreen) {
       loganLog(`本人开共享，会场中存在焦点画面，开启TopShare`)
       window._loadTopShare = true
       if(!this.vue.$store.state.member.userList.find((i) => i.isTopShare)){
         this.vue.$store.commit('member/unshiftUser', {
           userId: this.vue.$configs.peerId,
           isTopShare: true,
         })
       }
       // return
     }
  });
};

// 8.本地用户共享结束
MeetingManager.prototype.screenCaptureStoped = function() {
  // let that = this
  let _eventCount = this.roomClient.listenerCount('onScreenCapturePaused')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onScreenCapturePaused", () => {
    console.log("------收到本地用户的共享结束回调-------");

    // 删除isTopShare
    this.vue.$store.commit("member/removeShareView");

    // 如果是只有一个人切换9宫格
    const { layoutType,isFullModel } = this.vue.$store.state.meet;
    if(!isFullModel && this.vue.$store.state.member.userList.length <=1 && (layoutType === LAYOUT_CONFIG.COLUMN || layoutType === LAYOUT_CONFIG.ROW)){
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.GALLERY9
      })
    }

    // 更新vuex中自己的共享状态为false
    this.vue.$store.commit("member/updateUser", {
      userId: this.vue.$configs.peerId,
      isShare: false,
    });
  });
};
// 9.服务端通知 - 远程有人开始或者停止共享流
MeetingManager.prototype.userShareAvailable = function() {
  let _eventCount = this.roomClient.listenerCount('onUserShareAvailable')
  if(_eventCount>0){ //如果注册过了就返回 避免多次注册   
    return
  }
  this.roomClient.on("onUserShareAvailable", async (shareInfo) => {
    const { userId } = shareInfo;
    console.error("userShareAvailable-----");
    console.log(shareInfo);
    loganLog(`userShareAvailable-----userId:${userId}`)

    let user = this.vue.$store.getters["member/getUser"](userId);

    if (user.userId) {
      console.error('已经存在userlist---');
      this._userShareAvailableHandle(shareInfo)
    }
  });
};

//焦点画面
MeetingManager.prototype.handleFocusScreen = async function(userId) {
  loganLog(`handleFocusScreen-----userId:${userId}`)
  if (userId != 0) {//设置焦点
     // 要判断模式
     const { layoutType } = this.vue.$store.state.meet;
     const userList = this.vue.$store.state.member.userList;
     
    // console.error(9999,this.vue.$store.getters["member/getRealUserList"].length)
    if(this.vue.$store.getters["member/getRealUserList"].length <=1 && !this.localUser.isShare){ //会场中只有一个人的情况下不需要切演讲者模式
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        isFocusScreenOnly: userId
      })
      return false
    }
     const findShare = this.vue.$store.getters["member/getRealUserList"].find(i => i.isShare)
     const isTopShare = userList.find((i) => i.isTopShare)
     let _isShipin = false

     
     if (!isTopShare && findShare) {
        window._loadTopShare = true
        window._isLoadMainVideo = true
        this.vue.$store.commit('member/unshiftUser', {
          userId: findShare.userId,
          isTopShare: true,
          sharePaused: findShare.sharePaused
        })
     }
      userList.forEach((i) => {
        if(i.isDbClick){
          this.vue.$store.commit("member/updateUser", {
            userId: i.userId,
            isDbClick: false,
          });
        }
      });
     // 如果当前处在宫格模式，则要切换到演讲者模式
     if (
       layoutType === LAYOUT_CONFIG.GALLERY9 ||
       layoutType === LAYOUT_CONFIG.GALLERY25
     ) {
       console.log("九宫格或者25宫格111，自动转换到演讲模式");
       try {
         if(_isShipin){
           console.error("首先停止订阅所有远端视频111");
           await this.muteRemoteVideoAll();
          }
 
         this.vue.$store.commit("meet/updateGlobalMeetState", {
           layoutType: LAYOUT_CONFIG.COLUMN,
         });
       } catch (error) {
         console.log(error);
       }
     }
   } else {
    // 如果是最后一个人切换9宫格 并且本人没开共享
    const {layoutType,isFullModel } = this.vue.$store.state.meet;
    // console.error(111,this.localUser)
    if((this.localUser && !this.localUser.isShare) && !isFullModel && this.vue.$store.state.member.userList.length <=1 && (layoutType === LAYOUT_CONFIG.COLUMN || layoutType === LAYOUT_CONFIG.ROW)){
      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.GALLERY9
      })
    }
   }
}

MeetingManager.prototype._userShareAvailableHandle = async function(shareInfo) {
  let that = this;
  const { userId, available, paused ,status} = shareInfo; //status  1 ,  开启共享 ， 2 ： 关闭共享 ， 3 ： 共享暂停 ， 4 共享恢复

  if (available) {
    //之前是否也有分享流，此状态为非登录游客状态断网重连userID发生了变化，之前的账户需要90s自动退会
    const userList = that.vue.$store.getters["member/getRealUserList"]
    const isPreUserShare = userList.find(i => i.isShare)

    // 更新之前的游客共享信息为false
    if(isPreUserShare && isPreUserShare.userId && isPreUserShare.userId !== userId){
      that.vue.$store.commit("member/updateUser", {
        userId:isPreUserShare.userId,
        isShare: false
      });
      loganLog(`isPreUserShare--user:${userId}`)
    }

    //开启了焦点画面
    let isFocusScreen = that.vue.$store.state.meet.isFocusScreen
    if (isFocusScreen && status == 1) { //会场中有焦点画面的时候不用改变布局不用冲开锁定
      loganLog(`会场中存在焦点画面，开启TopShare,available:${available}`)

      if(!this.vue.$store.state.member.userList.find((i) => i.isTopShare)){
        this.vue.$store.commit('member/unshiftUser', {
          userId: userId,
          isTopShare: true,
        })
      }
      window._isLoadMainVideo = true
      window._loadTopShare = true
    }else{
      // 有人开始共享了，这时候要判断模式
      const { layoutType } = that.vue.$store.state.meet;
      const userList = that.vue.$store.getters["member/getRealUserList"];

      // 将所有用户的dbClick重置为false，这时候就没有锁定状态了。
      if(status === 1 || status === 2){ //只有是开启共享和结束共享的情况下才取消锁定
        userList.forEach((i) => {
          if(i.isDbClick){
            that.vue.$store.commit("member/updateUser", {
              userId: i.userId,
              isDbClick: false,
            });
          }
        });
      }

      // 如果当前处在宫格模式，则要切换到演讲者模式
      if (
        layoutType === LAYOUT_CONFIG.GALLERY9 ||
        layoutType === LAYOUT_CONFIG.GALLERY25
      ) {
        console.log("九宫格或者25宫格111，自动转换到演讲这个");

        try {
          console.error("首先停止订阅所有远端视频111");
          await this.muteRemoteVideoAll();

          that.vue.$store.commit("meet/updateGlobalMeetState", {
            layoutType: LAYOUT_CONFIG.COLUMN,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    // 更新vuex中isShare 这个updateUser放到最后执行
    that.vue.$store.commit("member/updateUser", {
      userId,
      isShare: available,
      sharePaused: !!paused,
    });
    loganLog(`updataUserShareAvailable--uese:${userId}`)

    if(userId.indexOf('cloudshare') > -1) {
      const fileData = await queryDocumentShareData({
        roomid: this.vue.$route.query.roomID
      })
      let to_userid = fileData.roomId + '_' + fileData.userId

      // console.error(77777777,to_userid,userId)
      that.vue.$store.commit("member/updateUser", {
        userId:to_userid,
        isShareDoc: available
      });

      that.vue.$store.commit("meet/updateGlobalMeetState", {
        docShareUserId: to_userid,
      });
    }
  } else {
    // 更新vuex中isShare
    that.vue.$store.commit("member/updateUser", {
      userId,
      isShare: available,
      sharePaused: !!paused,
    });
    loganLog(`updataUserShareAvailable--uese:${userId}`)

    // 删除重试任务
    this.vue.$exceptionManager.deleteRetryJob(userId, 'share')

    // 删除isTopShare
    that.vue.$store.commit("member/removeShareView");
  }
  

  that.localUser.sharePaused = !!paused
  const isTopShare = that.vue.$store.state.member.userList.find((i) => i.isTopShare)
  if (isTopShare) {
    // 如果存在topShare，那更新topShare的sharePaused状态
    that.vue.$store.commit("member/updateTopShare", {
      sharePaused: !!paused,
    });
  }
  //如果是在收到几秒未收到流后的状态下共享流暂停状态手动清除loading动画的兜底处理
  if(paused){
    this.changeLoadingClass(userId,'remove',true)
  }
}

/**
 * 扬声器设置
 */
MeetingManager.prototype._handleAudioPlayout = function(userId) {
  const elements = document.querySelectorAll(`#audio-${userId}`)

  // 设置要使用的扬声器
  this.vue.$deviceControl.setCurrentSpeakerDevice(null, elements, true)
  this.vue.$nextTick(async () => {
    loganLog(`订阅时扬声器状态--${this.vue.$store.state.meet.isUseYangShengQi},userid=${userId}`);
    if (this.vue.$store.state.meet.isUseYangShengQi) {
      this.vue.$deviceControl.initPlayoutVolume(elements)
    } else {
      this.setAudioPlayoutVolume(0, elements)
    }
  })
}

export default MeetingManager
