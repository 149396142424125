import request from '@/utils/request'
import { getUuid } from "@/utils/auth"

/**
 * 获取会议信息
 */
export function fetchConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'get'
  })
}


/**
 * 
 */
export function fetchConferenceSnowflake() {
  return request({
    url: `/ybmeet-sequence/api/snowflake/get/ybmeet`,
    method: 'get'
  })
}


/**
 * 获取导出信息
 */
export function fetchRoomExport(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.rtcRoomNo}/detail/export`,
    method: 'get'
  })
}


/**
 * 导出信息
 */
export function exportRoomInfo(data) {
  return request({
    url: `/ybmeet-conference/conference/download/detail`,
    method: 'get',
    params: data,
    isBlobType: true,
    responseType: "blob"
  })
}


/**
 * 获取agenda info
 */
export function fetchAgendaInfo(data) {
  return request({
    url: `/ybmeet-conference/conference/agenda/info`,
    method: 'get',
    params: data
  })
}



/**
 * 个人信息
 */
export function fetchUserInfo() {
  return request({
    url: `/ybmeet-conference/conference/getUserInfo`,
    method: 'get'
  })
}


/**
 * 是否存在企业账号
 */
export function exitEnterprise(data) {
  return request({
    url: `/middle-auth/enterprise/exit/${data.userName}`,
    method: 'get'
  })
}

/**
 * 是否存在个人账号
 */
export function exitPermituser(data) {
  return request({
    url: `/middle-auth/permituser/exit/${data.userName}`,
    method: 'get'
  })
}

/**
 * 登录
 */
export function oauthLogin(data) {
  return request({
    url: '/middle-auth/login/oauth2/token',
    method: 'post',
    data
  })
}


/**
 * reconnection
 */
export function fetchReconnectionInfo(data) {
  return request({
    url: '/ybmeet-conference/conference/reconnection',
    method: 'post',
    data
  })
}

/**
 * 创建会议
 */
export function createConference(data) {
  data.businessType = 10006
  return request({
    url: `/ybmeet-conference/conference/create`,
    method: 'post',
    data
  })
}

/**
 * 加入会议
 */
export function joinConference(data) {
  return request({
    url: `/ybmeet-conference/conference/join`,
    method: 'post',
    data
  })
}


/**
 * 强制结束会议
 */
export function endConference(data) {
  return request({
    url: `/ybmeet-conference/conference/force/end/${data.id}`,
    method: 'post'
  })
}

/**
 * 会控
 */
export function fetchMeetControl(data, meetConfig = {}) {
  const { metaData, toPeerId, command } = data
  const { roomId, peerId } = meetConfig

  const formData = {
    room: roomId, // 房间id
    seq: new Date().getTime(),
    command,
    metadata: metaData || {},
    to: {
      peerid: toPeerId || '',
    },
    from: {
      peerid: peerId,
      deviceid: getUuid()
    }
  }

  formData.metadata = JSON.stringify(formData.metadata);
  return request({
    url: '/ybmeet-conference/conference/control',
    method: 'post',
    data: formData
  })
}


/**
 * 用户注册
 */
export function permituserRegist(data) {
  return request({
    url: `/middle-auth/permituser/regist`,
    method: 'post',
    data
  })
}

/**
 * 发送验证码
 */
export function sendCode(data) {
  return request({
    url: `/middle-auth/sms/send/code`,
    method: 'post',
    data
  })
}



/**
 * 更新用户信息
 */
export function updateUserInfo(data) {
  return request({
    url: `/middle-auth/user/info/update`,
    method: 'post',
    data
  })
}

/**
 * 修改密码
 */
export function modifyPassword(data, type) {
  return request({
    url: `/middle-auth/${type === 1 ? 'enterprise' :'permituser'}/modifypassword`,
    method: 'post',
    data
  })
}

/**
 * 忘记密码
 */
export function forgetPassword(data) {
  return request({
    url: `/middle-auth/permituser/forgetpassword`,
    method: 'post',
    data
  })
}


/**
 * 今日列表
 */
export function fetchTodayList(data) {
  return request({
    url: `/ybmeet-conference/conference/today/list`,
    method: 'post',
    data
  })
}

/**
 * 预定列表
 */
export function fetchOrderList(data) {
  return request({
    url: `/ybmeet-conference/conference/order/list`,
    method: 'post',
    data
  })
}

/**
 * 历史详情
 */
export function fetchHistoryDetail(data) {
  return request({
    url: `/ybmeet-conference/conference/history/detail`,
    method: 'post',
    data
  })
}




/**
 * 更新会议
 */
export function updateConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'put',
    data
  })
}

/**
 * 更新个人会议
 */
export function updatePersonConference(conferenceNo, data) {
  return request({
    url: `/ybmeet-conference/conference/person/${conferenceNo}`,
    method: 'put',
    data
  })
}


/**
 * 删除会议
 */
export function delConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'delete'
  })
}

/**
 * 删除房间
 */
export function delMeetRoom(data) {
  return request({
    url: `/ybmeet-conference/conference/room/${data.id}`,
    method: 'delete'
  })
}
// 医百会议&医推摩盘
//上传截图
export function imgUpload(data) {
  return request({
    url: `/ybmeet-conference/file/img/upload`,
    method: 'post',
    data
  })
}

//im上传图片
export function imImgUpload(data) {
  return request({
    url: `/ybmeet-conference/file/upload`,
    method: 'post',
    data
  })
}


// 根据房间号查询当前共享ID
export function queryDocumentShareData(data) {
  return request({
   url: '/ybmeet-conference/share/'+ data.roomid,
    method: 'GET',
    data: data
  })
}



// 单个共享文件查询
// export function queryDocumentId(data) {
//   return request({
//     url: '/ybshare-file/share/' + data.fileId,
//     method: 'GET',
//     data: data
//   })
// }


// // 文件总页数查询
// export function queryDocumentPageCount(params) {
//   return request({
//     url:'/ybshare-file/share/' + params.fileId + '/pageCount',
//     method: 'GET',
//     data: params
//   })
// }


// 云文件查询
export function queryDocFileData(params) {
  return request({
    url: '/ybmeet-conference/share/file/' + params.fileId,
    method: 'GET',
    data: params
  })
}


// 上传
// let CancelToken = axios.CancelToken;
// let source = CancelToken.source();

//上传接口
export function qureyFileUpload(data,onUploadProgress) {
  // 中断请求
  if(data.cancel){
    source.cancel()
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return;
  }

  return request({
    url: '/ybmeet-conference/share/file/upload',
    method: 'POST',
    headers:{'content-type':'application/x-www-form-urlencoded'},
    data: data,
    timeout:100000,
    onUploadProgress: ({ total, loaded })=> onUploadProgress({ total, loaded }),
    // cancelToken: source.token
  })
}


// 文件列表查询
export function quertDocumentList(data) {
  return request({
    url: '/ybmeet-conference/share/file/page/',
    method: 'post',
    data:{
      param:data
    }
  })
}


// 开启文件共享
export function documentShareStart(params) {
  return request({
    url:'/ybmeet-conference/share/start',
    method: 'POST',
    data: params
  })
}
// 停止文件共享
export function documentShareStop(params) {
  return request({
    url:'/ybmeet-conference/share/stop/'+ params.fileId + '/'+ params.userId,
    method: 'GET',
    data: params
  })
}


// 删除云分享文档
export function deleteDocumen(params) {
  return request({
   url: '/ybmeet-conference/share/file',
    method: 'DELETE',
    data: params
  })
}


// 文档改名
export function documentDisplayName(params) {
  return request({
   url: '/ybmeet-conference/share/change/displayName',
    method: 'POST',
    data: params
  })
}




// 查询缩略图
export function queryDocumentThumbnail(params) {
  return request({
   url: `/ybmeet-conference/share/file/${params.fileId}/thumbnail`,
    method: 'GET',
  })
}


/**
 * reconnection
 */
export function getHostRedirect(conferenceId,userId) {
  return request({
    url: `/ybmeet-conference/white/black/${conferenceId}/${userId}`,
    method: 'get'
  })
}


// 获取通用虚拟背景列表
export function getTentantArVirList(params){
  return request({
    url: '/ybmeet-conference/background/image/page',
     method: 'POST',
     data: params
   })
}



//上传图片接口
export function qureyImgUpload(data) {
  return request({
    url: '/ybmeet-conference/background/image/upload',
    method: 'POST',
    headers:{'content-type':'application/x-www-form-urlencoded'},
    data: data,
    timeout:100000,
  })
}



// 删除图片接口
export function qureyImgDelete(id) {
  return request({
    url: `/ybmeet-conference/background/image/${id}`,
    method: 'delete'
  })
}

//敏感词检测
export function wordsFilter(data) {
  return request({
    url: `/swgw/sensitive-words/sensitive/words/filter`,
    method: 'post',
    data
  })
}

// 用户入会确认
//无法入会{"code":0,"msg":"OK","data":{"success":false,"error":"接口待实现...","redirectUrl":"https://baidu.com"}}
//正常返回{"code":0,"msg":"OK","data":{"success":true,"redirectUrl":"https://baidu.com","info":{"topic":"100科室会","planStartTime":1650266642511,"planEndTime":1650288242511,"code":"12345678","requirePassword":true,"password":"password","userId":"Rep_12242","username":"张三","identify":20}}}
export function landingConfirm(data) {
  return request({
    url: `/adminapi/meeting/landing-page/confirm?token=` + data.token,
    method: 'get'
  })
}

// 确认用户是否可录制确认
export function landingCloudRecordEnable(data) {
  return request({
    url: `/adminapi/meeting/landing-page/plan/cloudRecordEnable`,
    method: 'get',
    params:data
  })
}


// 校验 Token 是否有效
//不通过{"code":0,"msg":"OK","data":{"success":false,"error":"接口待实现...","redirectUrl":"https://baidu.com"}}
//通过{"code":0,"msg":"OK","data":{"success":true,"info":{"topic":"100科室会","planStartTime":1650266632207,"planEndTime":1650288232207}}}
export function landingToken(data) {
  return request({
    url: `/adminapi/meeting/landing-page/auth?token=` + data.token,
    method: 'get',
  })
}


// 轮询Token
export function landingPoll(data) {
  return request({
    url: `/adminapi/meeting/landing-page/poll/${data}`,
    method: 'get'
  })
}



// 查询服务器时间
export function getCurrentTime(params) {
  return request({
   url: `/ybmeet-conference/conference/current/time`,
    method: 'GET',
  })
}