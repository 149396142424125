<!--
* 医百会议-主界面-顶部左边-录制浮层功能
* version: 1.0
* author:  SunAo
* time: 2021-02-03
-->
<template>
  <div class="record">
    <div class="dot"></div>
    <div class="info">{{ $t('meeting.recording') }}</div>
    <!-- <div class="time">{{time}}</div> -->
    <!-- 暂停按钮 -->
    <!-- <my-icon
      class="pauseIcon"
      :iconName="'iconluzhi_luzhizhong_24_bai'"
    ></my-icon> -->

    <!-- 关闭按钮 -->
    <!-- <my-icon
      class="closeIcon"
      :iconName="'iconluzhi_guanbi_24_bai'"
    ></my-icon> -->

    <!-- 继续录制按钮 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: ""
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.record {
  height: 24px;
  background: rgba(51, 51, 51, 0.4);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  // margin: 18px 0 0 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}


.record .dot {
  width: 8px;
  height: 8px;
  background: #ff4d4f;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.record .info {
  // width: 40px;
  height: 16px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
  margin-left: 6px;
}

.record .time {
  width: 54px;
  height: 16px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
  margin: 4px 0 4px 6px;
  // float: left;
}

.record .pauseIcon {
  margin: 0 0 0 4px;
  float: left;
  cursor: pointer;
}

.record .closeIcon {
  float: left;
  cursor: pointer;
}

.record .startIcon {
  float: left;
  cursor: pointer;
}
</style>
