<!--
* 医百会议-主界面-底部-扬声器功能
* version: 1.0
* author:  SunAo
* time: 2021-02-01
-->
<template>
  <div class="speakerBtn">
    <input type="hidden" id="speakerHiddenStatus" :value="isUseYangShengQi && !isShareDoc ? 1 : 0">
    <input type="hidden" id="speakerHiddenvolmue" v-model="speakerVolumeValue">
    <input type="hidden" id="speakerDeviceId" v-model="currentSelectedDevice.deviceId">
    <div class='btnLeft'  @click="clickLeft" id="meeting_speaker_btn_left_headless">
      <my-icon :iconName=" isUseYangShengQi ? 'iconyangshengqi_24_hei' : 'iconyangshengqi_guanbi_24_hei'"></my-icon>
      <div class="speakerInfo">{{$t('setUp.speaker')}}</div>
    </div>
    <div class="btnRight"  @click="clickRight" id="meeting_speaker_btn_right_headless">
      <my-icon class="speakerArrowIcon" :target="'speakerArrowIcon'" :fontSize="16"
        :iconName="'iconjiantou_xiajiantou_hei'"></my-icon>
    </div>

    <div class="speakerPanel" v-show="isShowPanel" v-extend-panel-manager="onClose">
      <div class="title">{{$t('meeting.selectSpeakers')}}</div>

      <template v-for="(device,idx) in speakerDevicesList" >
        <div class="rowItem hover_item" :key="idx" @click="selectedDevice(device)" :title="device.deviceName">
          <div :class="['name',{'selected':device.deviceId === currentSelectedDevice.deviceId}]">{{device.deviceName}}
          </div>
          <my-icon v-if="device.deviceId === currentSelectedDevice.deviceId" class="okIcon" :fontSize="16"
            :iconName="'iconduigou_16_lv'"></my-icon>
        </div>

        <div class="rowBar" :key="idx+'_bar'" v-if="device.deviceId === currentSelectedDevice.deviceId">
          <div class="barTitle" :class="$i18n.locale === 'en-US' ? 'barWidth' : ''">{{$t('setUp.voice')}}</div>

          <el-slider class="slider" @change="volumeChange" input-size="mini" :min="0" :max="100"
            v-model="speakerVolumeValue" :show-tooltip="false"></el-slider>
          <div class="valItem">{{speakerVolumeValue}}</div>
        </div>
      </template>

      <div class="rowItem" @click="openSetting" id="meeting_speaker_win_setting_headless">
        <div class="name">{{$t('setUp.seeting')}}</div>
      </div>

      <div class="rowItem" @click="clickLeft" id="meeting_speaker_win_switch_headless">
        <div class="name red" v-if="isUseYangShengQi">{{$t('meeting.closeSpeakers')}}</div>
        <div class="name green" v-else>{{$t('meeting.openSpeakers')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { loganLog } from "@/utils/log"

  export default {
    data() {
      return {
        isShowPanel: false, // 是否显示面板
        speakerVolumeValue: 0
      };
    },
    computed: {
      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId);
      },
      isUseYangShengQi() {
        return this.$store.state.meet.isUseYangShengQi;
      },
      isShareDoc() {
        return this.$store.state.meet.isShareDoc
      },
      speakerDevicesList() {
        return this.$store.state.device.speakerDeviceList
      },
      currentSelectedDevice() {
        return this.$store.state.device.currentSpeakerDevice
      },
      speakerVolumeState() {
        return this.$store.state.device.speakerVolumeValue
      }
    },
    watch: {
      isShowPanel(value) {
        this.$store.commit('isShowDeviceSmallWin', value)
      },
      speakerVolumeValue(value) {
        this.$store.commit('device/setSpeakerVolumeValue', value)
      },
      speakerVolumeState: {
        immediate: true,
        handler(value) {
          this.speakerVolumeValue = value
        }
      }
    },
    methods: {
      initPlayoutVolume() {
        this.$nextTick(() => {
          let audioEl = document.querySelectorAll(`.audioPlayoutVolumeItem`)
         
          if (!this.isUseYangShengQi) { // 代表要关闭扬声器
            loganLog('将要关闭扬声器');
            console.log(audioEl);

            this.$meetingControl.setAudioPlayoutVolume(0, audioEl)
            this.$saveLocal("isMeetUseYangShengQi", false);
          } else { // 代表要开启扬声器
            loganLog('将要打开扬声器');
            console.log(audioEl)

            this.$deviceControl.initPlayoutVolume(audioEl)
            this.$saveLocal("isMeetUseYangShengQi", true);
          }
        })
      },
      // 扬声器操作
      clickLeft: throttle(function () {
        if(this.$store.state.meet.isNetworkDisconnect){
          this.$parent.showToast(this.$t('meeting.netWorkBad'))
          return
        }

        const selfId = this.$configs.peerId

        // update vuex isUseYangShengQi
        this.$store.commit('member/updateUser', {
          userId: selfId,
          isUseYangShengQi: !this.isUseYangShengQi
        })

        this.$store.commit("meet/updateGlobalMeetState", {
          isUseYangShengQi:!this.isUseYangShengQi,
        })


        this.initPlayoutVolume()
      }, 500, {
        trailing: false
      }),

      // 点击按钮
      async clickRight() {
        if(!this.isShowPanel && !this.$store.state.isShowDeviceSmallWin) {
          this.isShowPanel = true
        } else {
          this.isShowPanel = false
        }
      },
      // 鼠标点击关闭面板
      onClose(mouseup, mousedown) {
        // if (
        //   mousedown.target.className === "btnRight over" ||
        //   mousedown.target.className.baseVal === "icon16 speakerArrowIcon" ||
        //   mousedown.target.className === "speakerArrowIcon"
        // ) {
        //   return;
        // }
        this.isShowPanel = false;
      },

      volumeChange(e) {
        // 设置设备音量值
        this.$deviceControl.setPlayoutVolume(null, this.speakerVolumeValue, this.isUseYangShengQi)
        loganLog(`会场设置扬声器音量：${this.speakerVolumeValue}`)
      },

      /**
       * 选中其他设备
       */
      selectedDevice(device) {
        if (device.deviceId === this.currentSelectedDevice.deviceId) {
          console.log('点击了当前，不做任何操作')
          return
        }
        this.$deviceControl.setCurrentSpeakerDevice(device.deviceId)
      },

      //打开设置窗组件
      openSetting() {
        this.isShowPanel = false
        this.$emit('openSetting')
      }
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    display: flex;
    align-items: center;
    background: #fff;
    height: 48px;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
        background: rgba(0, 0, 0, 0.05) !important;
      }
      &:focus{
        background: rgba(0, 0, 0, 0.1) !important;
      }
  }
  @mixin public_content{
    content: "";
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -10px;
    left: 10px;
  }
  .speakerBtn {
    display: flex;
    justify-content: space-between;
    height: 48px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    margin: 0 12px;
    .btnLeft {
      justify-content: space-between;
      padding-left: 10px;
      border-radius: 100px 0px 0px 100px;
      @include public_hover;
      .speakerInfo {
        height: 20px;
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 14px 4px;
      }
    }
    .btnRight {
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_hover;
    }

    .speakerPanel {
      width: 192px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 82px;
      left: 124px;
      padding-bottom: 10px;

      .title {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin: 17px 0 4px 16px;
      }
      .hover_item:hover{
        background: rgba(0, 0, 0, 0.05);
      }
      .rowItem {
        width: 100%;
        height: 36px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-right: 16px;
        .name {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin: 9px 0 0 16px;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .red {
          color: #FF0000;
        }


        .green {
          color: #1AB370;
        }

        .selected {
          color: #1ab370;
        }

        .okIcon {
          margin-top: 9px;
          margin-left: 10px;
        }
      }

      .rowBar {
        width: calc(100% - 18px);
        height: 47px;
        background: #fafafa;
        border: 1px solid rgba(0, 0, 0, 0.05);
        margin: 9px 0 0 9px;
        position: relative;
        display: flex;
        align-items: center;

        &::before {
          @include public_content;
          border-bottom: 10px solid #fafafa;
          z-index: 8;
        }

        &::after {
          @include public_content;
          border-bottom: 10px solid rgba(0, 0, 0, 0.05);
          z-index: 7;
        }

        .barTitle {
          width: 24px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
          // float: left;
          // margin: 16px 0 0 7px;
          margin-left: 7px;
        }

        .barWidth {
          width: 38px;
        }

        .slider {
          flex-grow: 1;
          // margin: 0 5px;
          margin-left: 10px;
          margin-right: 5px;
        }

        .barItem {
          width: 100px;
          height: 17px;
          line-height: 17px;
          margin-top: 14px;
          margin-left: 5px;
          float: left;
        }

        .valItem {
          display: block;
          width: 30px;
          // margin-top: 15px;
          // margin-left: 5px;
          // float: left;
          height: 17px;
          line-height: 17px;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
</style>