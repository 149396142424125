<template>
  <!-- 焦点画面 -->
  <div class="tab3-box">
    <videoItem 
      :user="tab3User"
      :tab3CurrentShare="tab3CurrentShare"
      idPix="tab3-video"
      @dobClick="dobuleTab3Click"
    />
  </div>
</template>

<script>
import { ROLE_CODE } from '@/constant/index'
import VideoItem from './video.item'
import dobuleClickMixins from "@/mixins/dobuleClick.mixins"

export default {
  mixins: [dobuleClickMixins],

  components: {
    videoItem: VideoItem
  },

  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },

    speakUser() { // 发言的用户
      return this.$store.getters["member/getSpeakUser"]
    },

    lastSpeaker() { // 最晚的发言用户
      return this.$store.state.member.lastSpeaker
    },

    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },

    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    },
    isFocusScreen() { // 存在焦点画面
      return this.$store.state.meet.isFocusScreen
    },
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId)
    },

    tab3User() { // tab3区域的显示画面
      console.error('tab3显示画面',this.isFocusScreen)
      if (!this.isFocusScreen) { // 没有焦点画面，则显示自己
        console.log('tab3没有共享人，显示自己')
        return this.selfInfo || {}
      } else { // 有人正在共享
        const findFocusScreen = this.userList.find(item => {
          return item.userId == this.isFocusScreen
        })
        return findFocusScreen
      }
    }
  },

  watch: {
    tab3User: {
      handler(newVal, oldVal) {
        const { userId, isShare, isUseShiPin } = newVal

        console.error('----------tab3User watch执行-----------')
        console.log(newVal)
        console.log(oldVal)

        if (!userId) {
          return
        }

        // 清理视频源操作
        if (!oldVal) {
        } else if (newVal.userId !== oldVal.userId) {
          console.error('tab3 newVal和oldval不是同一个人，执行清理操作--')
          this.clearVideoSource(oldVal.userId)
        } else { // 这个时候还是本人
          if (!newVal.isUseShiPin && !newVal.isShare) {
            console.error('tab3 newVal视频和分享都没开，执行清理操作--')
            this.clearVideoSource(newVal.userId)
          }
        }

        let isRun = false

        if (!oldVal) {
          isRun = true
        } else if (newVal.userId !== oldVal.userId) { // 换人，这时候下面得逻辑要执行 
          // 要执行
          isRun = true
        } else if (window._isClickSameTab) {
          window._isClickSameTab = false
          isRun = true
        } else { // 这个时候还是本人，不过其他属性改变了，这个时候要对isShare和isUseShiPin进行判断
          const _old = newVal._old || {}
          
          if (newVal.isShare && !_old.isShare) { // 旧的没开启分享，新的开启了分享
            console.log('tab3User 之前没有分享，现在分享了----')
            // 要执行
            isRun = true
          } else if (!newVal.isShare && _old.isShare) { // 旧的开启了分享，但是新的关闭了
            console.log('tab3User 之前分享，现在不分享了----')
            isRun = true
          } else if (newVal.isUseShiPin && !_old.isUseShiPin) {
            // 也要执行
            isRun = true
          }
        }


        if (isRun) {

          console.log('------执行 tab3 isRun------')

          this.$nextTick(async () => {
            const videoEl = document.getElementById(`tab3-video-${userId}`)
            console.log(videoEl)

            if (isUseShiPin) { // 命中的人是开启视频的
              if (userId === this.$configs.peerId) { // 命中的是自己，这时候要重新调用startlocalvideo，将大画面的el传入进去
                console.log('命中了tab3中的自己')
              
                this.$meetingControl.startLocalPreview(videoEl)
              } else { // 命中了其他用户，同理，再次订阅即可

                console.log('远端其他人命中了tab3，要开启视频')
                console.log(videoEl)
                this.$meetingControl.startRemoteVideo(userId, videoEl)
              }
            }
          })
        } else {
          console.log('------不执行 tab3 isRun------')
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      ROLE_CODE,
      tab3CurrentShare : false
    };
  },


  methods: {
    dobuleTab3Click(user) {
      this.dobuleClick('tab3', user)
    },

    clearVideoSource(userId) {
      const videoEl = document.getElementById(`tab3-video-${userId}`)

      console.error('执行tab3 video清理工作---')
      console.error(videoEl)

      videoEl && (videoEl.srcObject = null)
    }
  },
};
</script>

<style lang="scss" scoped>
.tab3-box {
  position: relative;
  height: 100%;
  background: #222;
  .user-item {
    min-height: 133px;
    height: 100%;

    // height: 160px;
    // ::v-deep video{
    //   width:auto;
    // }
  }

  .selfShare {
    color: #fff;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 16px;
  }
}
</style>