<!--
* 医百会议-主界面-底部-截图至云端功能
-->
<template>
  <div class="screenBtn">
    <!-- 截图icon展示 -->
    <div class="btnLeft"  @click="screenHot">
      <my-icon class="screenBtnIcon" :iconName="'iconyunduanjietu_24_hei'"></my-icon>
      <div class="screenInfo">云截图</div>
    </div>
    <div id="pip-warp">
      <div id="pip-content" style="
      overflow: hidden;
      text-align: center;">
        <div style="
        display: flex;
        justify-content: space-between;
        padding:14px 0 0 10px;
        box-sizing: border-box;
        color: #666666;
        line-height: 14px;
        font-size: 12px;
        width:230px;">
          <div @click="windowClick" style="
            display: flex;
            justify-content: space-between;">
            <img src="@/assets/images/screenhotbtn.png" style="width:24px;height:24px" alt="" class="reload_style">
            <div v-if="screenshotType==1" style="margin: 5px 0 0 5px;">云截图</div>
            <div v-else-if="screenshotType==2" style="margin: 5px 0 0 5px;color:#ff8200">截图中...</div>
            <div v-else-if="screenshotType==3" style="margin: 5px 0 0 5px;color:#1ab370">截图成功!</div>
            <div v-else-if="screenshotType==4" style="margin: 5px 0 0 5px;color:red">截图失败!</div>
          </div>
          <div style="margin: 5px 0 0 0;">{{timeinfo}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    fetchMeetControl,
    getCurrentTime
  } from '@/api/meet'
  import { loganLog } from '../../../YBMeetSDK/utils/log';
  import { CONTROL_COMMAND } from "@/constant/index";
  import throttle from 'lodash.throttle'
  import { getDate } from "@/utils/tool"

  export default {
    data() {
      return {
        //todo:meeting.vue中处理当前的主持人、联席主持人、其他人点击提示“暂无操作权限”
        dataURL: '',
        meetInfo: {},
        userId: '',
        name: '',
        time1: '',
        pipWindow : null,
        showCloswToast:true,
        timeinfo:'',
        getDateTimer:null,
        screenshotType:1
      };
    },
    computed: {
      userList() {
        // 成员列表
        return this.$store.getters["member/getRealUserList"]
      },
      isExitUserShare() { // 存在用户共享
        return this.userList.find(i => i.isShare)
      },
    },
     created() {
      this.meetInfo = this.$route.query
      this.userID = this.$route.params.userID
      this.isPip = () => "documentPictureInPicture" in window
    },
    methods: {
      async getDateTime(){
        let time = await getCurrentTime()
        let startTime = new Date(time)
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        this.timeinfo = `${y}.${m}.${d} ${ startTime.toTimeString().substr(0,5)}`
        console.error('当前时间',this.timeinfo)
      },
      screenHot: throttle(async function () {
        //todo主持人，联席主持人才有权限，其他人点击提示暂无权限
        // this.$parent.showToast('暂无操作权限')
        if(this.isExitUserShare && this.isExitUserShare.userId === this.$configs.peerId){
          if(this.pipWindow){
            this.$parent.showToast('请将当前界面最小化，返回共享桌面，使用云截图悬浮窗进行截图')
          }else{
            this.enterPiP()
          }
        }else{
          try {
            await fetchMeetControl({
              command: CONTROL_COMMAND.CLOUD_SCREENHOT,
            }, this.$configs)
            this.$parent.showToast('云截图成功，图片已保存至云端')
          } catch (error) {
            this.$parent.showToast('云截图错误')
            loganLog(`云截图错误：${JSON.stringify(error)}`)
          }
        }
      }, 500, {
        trailing: false
      }),
      windowClick: throttle(async function () {
        loganLog('小窗口点击云截图')
        if(this.screenshotType != 1) return
        this.screenshotType = 2
        this.showCloswToast = false
        try {
          await fetchMeetControl({
            command: CONTROL_COMMAND.CLOUD_SCREENHOT,
          }, this.$configs)
          this.$parent.showToast('云截图成功，图片已保存至云端')
          this.screenshotType = 3
          setTimeout(() => {
            this.screenshotType = 1
          }, 3000);
        } catch (error) {
          this.screenshotType = 4
          setTimeout(() => {
            this.screenshotType = 1
          }, 3000);
          this.$parent.showToast('云截图错误')
          loganLog(`云截图错误：${JSON.stringify(error)}`)
        }
      }, 500, {
        trailing: false
      }),

      //生成画中画
      async enterPiP() {
        if (!this.isPip) {
          loganLog("您的浏览器不支持画中画模式！");
          return;
        }
        this.getDateTime()
     
        this.showCloswToast = true

        const pipOptions = {
          lockAspectRatio: true,
          copyStyleSheets: false,
          disallowReturnToOpener:true,//隐藏“返回标签页”
          width:120,
          height:10
        };

        try {
          this.pipWindow = await documentPictureInPicture.requestWindow(pipOptions);
          this.getDateTimer = setInterval(() => {
            this.getDateTime()
          }, 5000);
        } catch (error) {
          loganLog(`截图失败--error:${JSON.stringify(error)}`)
          this.$parent.showToast('如需使用云截图，点击菜单栏"云截图"激活悬浮窗')
          return
        }
    
        // this.pipWindow.addEventListener("resize", (event)=> { //不生效
        //   console.log("画中画窗口大小变化");
        //   event.preventDefault()
        //   window.resizeTo(400, 200)
        // });

        this.pipWindow.innerHeight = '100px';
        // 从初始文档复制样式表
        // 使画中画里面的画面和初始文档保持一致
        [...document.styleSheets].forEach((styleSheet) => {
          try {
            const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join("");
            const style = document.createElement("style");
            style.textContent = cssRules;
            this.pipWindow.document.head.appendChild(style);
          } catch (e) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = styleSheet.type;
            link.media = styleSheet.media;
            link.href = styleSheet.href;
            this.pipWindow.document.head.appendChild(link);
          }
        });
        const target = document.getElementById('pip-content');
        // 将目标添加到PiP窗口
        this.pipWindow.document.body.append(target);
        
        // 监听pip点击事件
        // this.pipWindow.addEventListener('click', () => {
        //   console.error('点击截图');
        // });
        // 监听PiP结束事件，将目标放回原位
        this.pipWindow.addEventListener("unload", this.onLeavePiP.bind(), {
          once: true,
        });
      },
      // 当PiP窗口关闭时调用
      onLeavePiP() {
        this.getDateTimer && clearInterval(this.getDateTimer);
        if(this.showCloswToast) this.$parent.showToast('悬浮窗关闭，如需使用通过点击菜单栏云截图按钮打开')
        const target = this.pipWindow.document.getElementById('pip-content');
        const targetContainer = document.getElementById('pip-warp')
        targetContainer.append(target);
        this.pipWindow.close();
        this.pipWindow = null;
      },
    },
    watch: {
      isExitUserShare: {
        handler(newVal) {
          if(newVal && this.isExitUserShare.userId === this.$configs.peerId){ //本人共享
            if(!this.pipWindow) this.enterPiP()
          }else{
            this.showCloswToast = false
            if(this.pipWindow) this.pipWindow.close();
          }
        },
        immediate: true
      },
    },
    destroyed() {
      this.showCloswToast = false
      this.pipWindow && this.pipWindow.close();
      this.getDateTimer && clearInterval(this.getDateTimer);
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_style(){
    background: #fff;
    height: 48px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
      &:hover{
        background: rgba(0, 0, 0, 0.05) !important;
      }
      &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
      }
  }
  @mixin public_box {
    display: flex;
    justify-content: space-between;
    height: 48px;
    border-radius: 0px 100px 100px 0px;
  }
  @mixin public_flex(){
    display: flex;
    justify-content: space-between;
  }
  @mixin public_content(){
    content: "";
    width: 0;
    height: 0;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    position: absolute;
    top: -9px;
    left: 10px;
  }
  .screenBtn {
    @include public_box;
    float: left;
    position: relative;
    .btnLeft{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      &:hover {
        background: rgba(0, 0, 0, 0.05) !important;
      }
      &:focus {
        background: rgba(0, 0, 0, 0.1) !important;
      }
      .screenInfo {
        @include public_box;
        padding: 0 14px 0 4px;
        color: #333;
        font-size: 14px;
        cursor: pointer;
        align-items: center;
        transition-property: background-color;
        transition-duration: 0.5s;
        transition-timing-function: ease;
      }
    }
    .radius-no{
      border-radius: 0px 0 0 0px;
    }
    .btnRight {
      display: flex;
      align-items: center;
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_style;
    }

    .screenhotPanel {
      width: 102px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 82px;
      left: 16px;
      padding-bottom: 10px;
      .rowItem {
        @include public_flex;
        width: 100%;
        height: 36px;
        background: #ffffff;
        box-sizing: border-box;
        padding-right: 16px;
        margin: 14px 0 0 0;
        .name {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin: 9px 0 0 16px;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .red {
          color: #FF0000;
        }

        .green {
          color: #1AB370;
        }

        .selected {
          color: #1ab370;
        }

        .okIcon {
          margin-top: 9px;
          margin-left: 10px;
        }
      }
    }
    #pip-warp{
      position: absolute;
      bottom: -500px;
    }
  }
</style>