<template>
  <div :class="{'language': !isDefault}">
    <el-select  @change="langChange"  size="mini" v-model="langSelectVal" :placeholder="$t('setUp.change')">
      <el-option
        v-for="(item, index) in langOptions"
        :key="index"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { LANG_ZH, LANG_EN, LANG_AUTO } from '@/constant/index'
import { isZhLang, getLocalLanguage, setLocalLanguage } from "@/utils/auth"

export default {
  data() {
    return {
      langOptions: [
        {
          label: isZhLang() ? '中文' : 'Chinese',
          value: LANG_ZH,
        },
        {
          label: isZhLang() ? '英文' : 'English',
          value: LANG_EN,
        },
        {
          label: isZhLang() ? '自动' : 'Auto',
          value: LANG_AUTO
        }
      ],
      langSelectVal: getLocalLanguage()
    }
  },
  props: {
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    langChange(e) {
      setLocalLanguage(e)
      window.location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
.language {
  width: 90px;
  margin-left: 10px;
  position: relative;
  padding-top: 12px;
}
:deep .el-icon-arrow-up::before{
  font-family: 'iconfont' !important;
  content: "\e6eb" !important;
}
</style>
