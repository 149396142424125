<template>
  <div id="coreUserMain" class="core coreUserMain" @mouseover="overMenuBarHandle" @mouseleave="menuBarLeaveHandler"  :class="{
    isFullModel: isFullModel,
    col: type === 'col',
    row: type === 'row',
    rowHide: isHideMemberRow && type === 'row'
  }">
      <!-- 视频 -->
      <!-- widthAuto: userMain.userDevice && userMain.userDevice.flag && ['wx','iOS','android'].includes(userMain.userDevice.flag) -->

      <video
        autoplay
        playsinline
        disablePictureInPicture
        :style="{maxWidth:type === 'row' ? '' : maxVideoOneWidth }"
        v-if="isShowOneVideo()"
        :id="`main-video-${userMain.userId}`"
        :data-video="`video-${userMain.isUseShiPin}`"
        :data-share="`share-${userMain.isShare}`"
        class="main-video media-reset-flag"
        :class="{
          shareScale50: isSetShareView() && shareScale === 2,
          shareScaleIncrease: isSetShareView() && (shareScale === 4 || shareScale === 5 || shareScale === 6),
          heightAuto:false,
          widthAuto: true
        }"
      ></video>
      <!-- 用户没有开启视频时显示 -->
      <div
        class="parseVideoPanel"
        v-else
      ><div :title="userMain.userName" class="userName">{{ strMiddleEllipsis(userMain.userName, 10, 3, 2) }}<span :title="userMain.userName" class="parseUnit" v-if="userMain.hospital">{{ userMain.hospital }}</span></div>
      </div>
      <span v-if="isShowOneVideo()" id="main-video-loading-box" class="loading-box" :data-isCurrentShare="`${this.isCurrentShare}`"></span>
      <!-- 这里是不需要渲染audio标签的，因为在右侧列表已经有了 -->

      <!-- 底部条 -->
      <div class="videoBar" v-if='fullNotShow'>
        <!-- 主持人图标 -->
        <!-- <my-icon title="主持人" v-if="isShowIcon(ROLE_CODE.HOST)" class="compere" :fontSize="20" iconName="iconzhuchiren"></my-icon> -->
        <img class="compere" src="@/assets/images/iconzhuchiren.png" v-if="userMain.roleCode === ROLE_CODE.HOST" alt="">
        
        <!-- 联席主持人图标 -->
        <!-- <my-icon title="联席主持人" v-if="isShowIcon(ROLE_CODE.CO_HOST)" class="compere2" :fontSize="20" iconName="iconlianxizhuchiren"></my-icon> -->
        <img class="compere2" src="@/assets/images/iconlianxizhuchiren.png" v-if="userMain.roleCode === ROLE_CODE.CO_HOST" alt="">

        <!-- 话筒音波状态 -->
        <!-- <my-icon
          class="userAudioIcon"
          :fontSize="16"
          :iconName="getHuaTongIcon()"
        ></my-icon> -->
        <img class="userAudioIcon" :src="userMain.isUseHuaTongError ?  require('@/assets/images/iconshipinlan_shengyin_yichang_16_bai.png') : userMain.isUseHuaTong ?  getTimevolum(userMain.userId) == 0 ? require('@/assets/images/iconshipinlan_shengyin_16_bai.png') : require(`@/assets/images/iconshipinlan_shengyin_16_${getTimevolum(userMain.userId)}.png`) : require('@/assets/images/iconshipinlan_shengyin_guanbi_16_bai.png')" alt="">


        <!-- 用户名 -->
        <div :title="userMain.userName" class="userName">{{ strMiddleEllipsis(userMain.userName, 10, 3, 2) }}</div>
      </div>

      <div class="videoBar videoBarRight" v-if="userMain.hospital && userMain.isUseShiPin">
        <div :title="userMain.hospital" class="userName">{{ userMain.hospital }}</div>
      </div>


      <!-- 共享遮挡面板 -->
      <div class="shareing" v-if="isShowSelfShareView()">
        <div class="text">{{$t('meeting.youAreShare')}}</div>
      </div>

      <!-- 共享暂停面板 -->
      <div class="shareing" v-if="isShowSharePausedView()">
        <div class="text">{{$t('meeting.shareTop')}}</div>
      </div>

      <!-- 共享暂停面板 -->
      <div class="shareing" v-if="(userMain.isUseShiPin || userMain.isShare) && userMain.encodingError">
        <div class="text colorRed">AV1(视频格式不支持)</div>
      </div>

      <!-- 锁定解锁按钮 -->
      <div class="lock-btn" :class="$store.state.meet.layoutType === 4 ? 'lock_btn_top':''" v-if="isShowLockBtn() && fullNotShow" @click="toggleLockHandle" id="meeting_lock_btn_headless">
        <div class="icon lock" v-if="!userMain.isDbClick"></div>
        <div class="icon unlock" v-else></div>
        <div class="text">{{ userMain.isDbClick ? $t('meeting.unlockScreen') : $t('meeting.lockScreen') }}</div>
      </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { ROLE_CODE } from '@/constant/index'
import { strMiddleEllipsis } from "@/utils/str"

export default {
  props: {
    isFullModel: {
      type: Boolean,
      default: false
    },
    isShowMenuBar: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ROLE_CODE,
      isCurrentShare:false,//当前是否为共享流
      maxVideoOneWidth: '',
      fullNotShow:true
    };
  },
  created(){
    // this.initEnterHandle()//监听全屏时快捷键事件
  },
  computed: {
    isTopShare() {
      return this.$store.state.member.userList.find(i => i.isTopShare)
    },
    userList() { // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },
    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    },

    shareScale() { // 共享缩放级别 1: 适应窗口 2: 50% 3: 100% 4: 150% 5: 200% 6: 300%
      return this.$store.state.meet.shareScale
    },

    docShareUserId() { // 云共享真实的共享者ID
      return this.$store.state.meet.docShareUserId
    },

    speakUser() { // 发言的用户
      return this.$store.getters["member/getSpeakUser"]
    },

    lastSpeaker() { // 最晚的发言用户
      return this.$store.state.member.lastSpeaker
    },

    isFocusScreen() { //焦点画面
      return this.$store.state.meet.isFocusScreen;
    },

    userMain() {
      console.error('执行one computed------------');
      console.error(this.isFocusScreen)
      if (this.userList.length > 0) {
        // reset当前是否为共享流
        this.isCurrentShare = false
        //先考虑锁定 > 焦点 > 分享 > 发言 > 主持人 > 最早入会的人
        // 锁定
        const findDobuleClick = this.userList.find(item => {
          return item.isDbClick
        })

        if (findDobuleClick) {
          console.log('命中了双击----------')
          return findDobuleClick
        }

        //焦点
        if (this.isFocusScreen != 0) {
          console.log('命中了焦点----------')
          const findFocusScreen = this.userList.find(item => {
            return item.userId == this.isFocusScreen
          })
          // console.error(111111,findFocusScreen)
          return findFocusScreen
        }

        // 分享
        const findShare = this.userList.find(item => {
          return item.isShare
        })
        if (findShare) {
          console.log('命中了共享----------',findShare)
          this.isCurrentShare = true
          return findShare
        }

        if (this.speakUser) {
          console.log('命中了大画面中正在说话的人-----------')
          return this.speakUser
        } else { // 发言人数组为空，这时候来查看是否有最晚的发言人
          if (this.lastSpeaker) {
            console.log('命中了大画面中最晚发言人-----------')
            return this.lastSpeaker
          }
        }

        // 主持人
        const findHost = this.userList.find((item)=>{
          return item.roleCode === ROLE_CODE.HOST
        })
        if (findHost) {
          console.log('命中了主持人----------')
          return findHost
        }


        // 最早入会
        let minIndex = -1
        let minMs = ''
        this.userList.forEach((item, index) => {
          const { timeMs } = item

          if (!minMs) { // 不存在，第一次
            minMs = timeMs
            minIndex = index
          } else {
            if (minMs >= timeMs) {
              minMs = timeMs
              minIndex = index
            }
          }
        })
        if (minIndex > -1) {
          console.log('命中了最早入会----------')
          return this.userList[minIndex]
        }
      } else {
        console.error('userMain computed userList为空')
        return {}
      }
    },

    isHideMemberRow() { //大画面时，是否隐藏顶部成员列表
      return this.$store.state.meet.isHideMemberRow;
    }
  },

  watch: {
    userMain: {
      handler(newVal, oldVal) {
        const { userId, isShare, isUseShiPin} = newVal

        console.error('----------USERMAIN watch执行-----------')
        console.log(JSON.stringify(newVal))
        console.log(JSON.stringify(oldVal))

        if (!userId) {
          return
        }

        let isRun = false
        const _old = newVal._old || {}


        // 清理视频源操作
        if (!oldVal) {
        } else if (newVal.userId !== oldVal.userId) {
          console.error('newVal和oldval不是同一个人，执行清理操作--')
          this.clearVideoSource(oldVal.userId)
        } else { // 这个时候还是本人
          if (!newVal.isUseShiPin && !newVal.isShare) {
            console.error('newVal视频和分享都没开，执行清理操作--')
            this.clearVideoSource(newVal.userId)
          }
        }

        if (this.isTopShare) { // 存在顶部分享画面，特殊处理
          console.error('-------this.isTopShare-------')
          
          if (!oldVal) {
            isRun = true
          } else if (window._isLoadMainVideo) {
            window._isLoadMainVideo = false
            isRun = true
          } else if (newVal.userId !== oldVal.userId) { 
            isRun = true
          } else {
            if (newVal.isUseShiPin && !_old.isUseShiPin) {
              isRun = true
            }
          }

          if (isRun) {
            console.error('------执行 USERMAIN isRun22222------')

            this.$nextTick(async () => {
              const videoEl = document.getElementById(`main-video-${userId}`)
              console.log(videoEl)

              if (isUseShiPin) { // 锁定的分享用户开启了视频
                if (userId === this.$configs.peerId) {
                  this.$meetingControl.startLocalPreview(videoEl)
                } else { // 命中了其他用户，同理，再次订阅即可
                  this.$meetingControl.startRemoteVideo(userId, videoEl)
                }
              }
            })

            console.error('------执行 清理大画面loading------')
            this.$meetingControl.changeMainLoadingClass()
          } else {
            console.error('------不执行 USERMAIN isRun22222------')
          }
        } else { // 正常处理
          if (!oldVal) {
            isRun = true
            console.error('!oldVal')
          } else if (newVal.userId !== oldVal.userId) { // 换人，这时候下面得逻辑要执行
            // 要执行
            isRun = true
            console.error('换人，这时候下面得逻辑要执行')
          } else if (window._isClickTopShare) {
            console.error('_isClickTopShare为true')
            window._isClickTopShare = false
            isRun = true
          } else { // 这个时候还是本人，不过其他属性改变了，这个时候要对isShare和isUseShiPin进行判断
            if (newVal.isShare && !_old.isShare) { // 旧的没开启分享，新的开启了分享
              console.error('旧的没开启分享，新的开启了分享')
              isRun = true
            } else if (!newVal.isShare && _old.isShare) { // 旧的开启了分享，但是新的关闭了
              console.error(' 旧的开启了分享，但是新的关闭了')
              console.error('之前分享，现在不分享了----')
              isRun = true
            } else if (newVal.isUseShiPin && !_old.isUseShiPin) {
              console.error('之前没开视频，现在开启了, 此时还要看当前的用户有没有在共享----')
              console.error(newVal.isShare)
              if (!newVal.isShare) { // 新用户未开启分享
                isRun = true
              }
            }
          }
          // alert(isRun)
          if (isRun) {
            console.error('------执行 USERMAIN isRun------')

            this.$nextTick(async () => {
              console.log('userMain isRun--nextTick---');
              const videoEl = document.getElementById(`main-video-${userId}`)
              // console.log(videoEl)
              // console.log(userId)
              
              
              if (isShare) { // 正在分享
                if (userId !== this.$configs.peerId) {
                  console.log('CORE ONE 分享的人不是自己，这时候要开启其他人的分享---')
                  console.log(videoEl)

                  this.$meetingControl.startRemoteShare(userId, videoEl)

                  if (this.shareScale === 4 || this.shareScale === 5 || this.shareScale === 6) {
                    console.error(`需要恢复百分比-----${this.shareScale}`)
                    this.removeShareDrag()
                    this.setVideoItem(this.shareScale)
                    this.initShareDrag()
                  }
                }
              } else if (isUseShiPin) { // 命中的人是开启视频的
                if (userId === this.$configs.peerId) { // 命中的是自己，这时候要重新调用startlocalvideo，将大画面的el传入进去
                  console.log('命中了大画面中的自己')
                  this.$meetingControl.startLocalPreview(videoEl)
                } else { // 命中了其他用户，同理，再次订阅即可

                  console.log('远端其他人命中了左侧，要开启视频')
                  console.log(videoEl)
                  this.$meetingControl.startRemoteVideo(userId, videoEl)
                }
              }
            })
            
            console.error('------执行 清理大画面loading------')
            this.$meetingControl.changeMainLoadingClass()
          } else {
            console.log('------不执行 USERMAIN isRun------')
          }
        }
      },
      deep: true,
      immediate: true
    },

    shareScale: {
      handler(newVal) {

        console.error('shareScale---变化了----')
        // console.log(newVal)

        this.$nextTick(() => {
          if (newVal === 4 || newVal === 5 || newVal === 6) { // 150% 200% 300%
            console.error('先执行清理操作----')
            this.removeShareDrag()

            if (this.isSetShareView()) {
              console.error('可以进行设置video');
              this.setVideoItem(newVal)
              this.initShareDrag()
            } else {
              console.error('不可以进行设置video');
            }
          } else {
            console.error('执行清理操作--1111111111--')
            this.removeShareDrag()
          }
        })
      },
      immediate: true
    },

    isFullModel: {
      handler(newVal) {
        if (!newVal) {
          this.fullNotShow = true
        }

        console.log('isFullModel:', this.isFullModel)
      },
      immediate: true
    },
    isShowMenuBar: {
      handler(newVal) {
        if (this.isFullModel) {
          this.fullNotShow = newVal
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.setVideoMaxWidth()
    window.onresize = throttle(() => {
      this.setVideoMaxWidth()
    }, 100)
  },

  methods: {
    strMiddleEllipsis,

    clearVideoSource(userId) {
      const videoEl = document.getElementById(`main-video-${userId}`)

      console.error('执行main video清理工作---')
      console.error(videoEl)

      videoEl && (videoEl.srcObject = null)
    },

    // initEnterHandle() {
    //   window.addEventListener('keydown', this.keydownHandle)
    // },

    // keydownHandle(e){
    //   // ctrl+alt+h：  h 72 
    //   if(e.ctrlKey && e.altKey && e.keyCode == 72 && this.isFullModel){
    //     this.fullNotShow =  !this.fullNotShow
    //   }
    // },
    // removeEnterHandle(){
    //   window.removeEventListener('keydown', this.keydownHandle)
    // },

    isSetShareView() { // 不存在锁定人像，并且是分享状态
      return !this.isExitUserLock && this.userMain.isShare
    },

    overMenuBarHandle(){
      if(this.isFullModel) this.fullNotShow = true
    },
    menuBarLeaveHandler(){
      if(this.isFullModel) this.fullNotShow = false
    },
    removeShareDrag() {
      console.error('执行removeShareDrag------------------')

      const dragDom = document.querySelector('.main-video')

      if (dragDom) {
        const dragDomMaxWidth = dragDom.style.maxWidth
        dragDom.style = ''
        dragDom.style.maxWidth = dragDomMaxWidth

        dragDom.onmousedown = null
      }

      document.onmousemove = null
      document.onmouseup = null
    },

    setVideoItem(type) {

      const dragDom = document.querySelector('.main-video')
      const diagWrapperDom = document.getElementById('coreUserMain')

      if (dragDom && diagWrapperDom) {
        if (type === 4) { // 1.5倍
          // console.log(dragDom.offsetWidth) // TODO: 这里有点问题
          // console.log(dragDom.offsetWidth * 1.5)

          dragDom.style.width = dragDom.offsetWidth * 1.5 + 'px'
          dragDom.style.height = dragDom.offsetHeight * 1.5 + 'px'
        } else if (type === 5) { // 2倍
          // console.log(dragDom.offsetWidth)
          // console.log(dragDom.offsetWidth * 2)

          dragDom.style.width = dragDom.offsetWidth * 2 + 'px'
          dragDom.style.height = dragDom.offsetHeight * 2 + 'px'
        } else if (type === 6) { // 3倍
          // console.log(dragDom.offsetWidth)
          // console.log(dragDom.offsetWidth * 2)

          dragDom.style.width = dragDom.offsetWidth * 3 + 'px'
          dragDom.style.height = dragDom.offsetHeight * 3 + 'px'
        }

        dragDom.style.top = -(dragDom.offsetHeight - diagWrapperDom.offsetHeight) / 2 + 'px'
        dragDom.style.left = -(dragDom.offsetWidth - diagWrapperDom.offsetWidth) / 2 + 'px'
      }
    },

    initShareDrag() { // 初始化video拖动
      const dragDom = document.querySelector('.main-video')
      const diagWrapperDom = document.getElementById('coreUserMain')

      if (dragDom) {
        dragDom.style.cursor = 'move'

        dragDom.onmousedown = (e) => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dragDom.offsetLeft
          const disY = e.clientY - dragDom.offsetTop

          document.onmousemove = function(e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX
            let t = e.clientY - disY

            if (l > 0) {
              l = 0
            }
            const lDist = dragDom.offsetWidth - diagWrapperDom.offsetWidth
            if (l <= - lDist) {
              l = - lDist
            }


            if (t > 0) {
              t = 0
            }
            const tDist = dragDom.offsetHeight - diagWrapperDom.offsetHeight
            if (t <= - tDist) {
              t = - tDist
            }

            // 移动当前元素
            dragDom.style.left = `${l}px`
            dragDom.style.top = `${t}px`
          }

          document.onmouseup = function(e) {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    },

    setVideoMaxWidth() {
      const el = document.getElementById('coreUserMain')

      if (el) {
        const videoOneHeight = el.offsetHeight
        this.maxVideoOneWidth = (16 * videoOneHeight / 9) + 'px'
      }
    },

    isShowLockBtn() { // 是否显示锁定解锁按钮
      // return !this.userMain.isShare

      if (!this.userMain.isShare || this.isFocusScreen) { // 当前用户没有分享或者当前是焦点画面
        return true
      } else { // 当前用户在分享
        return this.isExitUserLock
      }
    },


    isShowIcon(code) { // 是否显示CoreOne video标签
      const { roleCode, userId } = this.userMain
      if(userId.indexOf('cloudshare') > -1) {
        let _userData = this.$store.getters["member/getUser"](this.docShareUserId);
        if(_userData && _userData.roleCode){
          return code == _userData.roleCode
        }else{
          return false
        }
      }else{
        return code == roleCode
      }
    },
    getTimevolum(userId){
      let _volumNum = this.$store.state.meet.realTimevolumeData[userId] || 0
      let num  = Math.ceil(_volumNum / 7);
      _volumNum = num ? num+2 > 15 ? 15 : num+2 :0;
      return _volumNum
    },

    getHuaTongIcon() { // 是否显示CoreOne video标签
      const { userId, isUseHuaTongError, isUseHuaTong, } = this.userMain
      if(userId.indexOf('cloudshare') > -1) {
        let _userData = this.$store.getters["member/getUser"](this.docShareUserId);
        return _userData.isUseHuaTongError ? 'iconshipinlan_shengyin_yichang_16_bai' : _userData.isUseHuaTong ? this.getTimevolum(userId) == 0 ? 'iconshipinlan_shengyin_16_bai': 'iconshipinlan_shengyin_16_'+ this.getTimevolum(userId) : 'iconshipinlan_shengyin_guanbi_16_bai'
      }else{
        return isUseHuaTongError ? 'iconshipinlan_shengyin_yichang_16_bai' : isUseHuaTong ? this.getTimevolum(userId) == 0 ? 'iconshipinlan_shengyin_16_bai': 'iconshipinlan_shengyin_16_'+ this.getTimevolum(userId) : 'iconshipinlan_shengyin_guanbi_16_bai'
      }
    },
    
    isShowOneVideo() { // 是否显示CoreOne video标签

      const { isUseShiPin, isShare, encodingError } = this.userMain

      if (this.isTopShare) { // 存在顶部共享锁定用户
        return isUseShiPin && !encodingError
      } else {
        return (isUseShiPin || isShare ) && !encodingError
      }
    },


    // 是否显示自己的共享提示
    isShowSelfShareView() {
      if (this.isTopShare) {
        return false
      } else {
        return this.userMain.isShare && (this.userMain.userId === this.$configs.peerId)
      }
    },

    // 是否显示共享暂停
    isShowSharePausedView() {
      if (this.isTopShare) {
        return false
      } else { // 共享人不是自己，正在共享，共享是暂停状态，
        return (this.userMain.userId !== this.$configs.peerId) && this.userMain.isShare && this.userMain.sharePaused
      }
    },

    toggleLockHandle() {
      // 提示
      console.error('点击取消锁定')
      if (this.userMain.isDbClick) {
        this.$parent.$parent.showToast(this.$t('meeting.screenUnlock'))
      } else {
        this.$parent.$parent.showToast(this.$t('meeting.unLocking'))
      }

      this.$store.commit('member/updateUser', {
        userId: this.userMain.userId,
        isDbClick: !this.userMain.isDbClick
      })

      if (!this.isFocusScreen && this.isTopShare) { // 存在共享画面
        window._isClickTopShare = true
        this.$store.commit('member/removeShareView')
      }
      if(this.isFocusScreen && this.isExitUserShare && !this.isTopShare){
          console.error('取消锁定时存在共享的用户同时存在焦点画面');
          window._loadTopShare = true
          this.$store.commit('member/unshiftUser', {
            userId: this.isExitUserShare.userId,
            isTopShare: true,
            sharePaused: this.isExitUserShare.sharePaused
          })
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.coreUserMain {
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.col {
    height: 100%;
  }

  &.row {
    height: calc(100% - 118px);
  }
  &.rowHide {
    height: 100%;
  }

  &.isFullModel {
    height: 100%;
  }

  .loading-box {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    z-index: 10;
    width: 60px;
    height: 60px;
    background: url(~@/assets/images/loading.png) no-repeat;
    background-size: 100% 100%;
    animation: rotate 1s linear infinite;
  }
  .loading-box-show{
    display: block;
  }
  .main-video {
    width: 100%;
    height: 100%;

    &.shareScale50 {
      transition: all 0.2s;
      transform: scale(0.5);
    }

    &.shareScaleIncrease {
      position: absolute;
      max-width: inherit !important;
    }
  }
  .heightAuto{
    height: auto;
  }
  .widthAuto{
    width: auto;
    max-width: 100% !important;
  }

  .lock-btn {
    position: fixed;
    top: 65px;
    left: 40px;

    // width: 112px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    cursor: pointer;

    padding: 0 12px;

    font-size: 14px;

    background-color: rgba(51, 51, 51, 0.4);
    border-radius: 16px;

    display: flex;
    align-items: center;

    .icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;

      &.unlock {
        background: url(~@/assets/images/unlock.png) no-repeat;
        background-size: 100% 100%;
      }

      &.lock {
        background: url(~@/assets/images/lock.png) no-repeat;
        background-size: 100% 100%;
      }
    }

  }
  .lock_btn_top{
    position: absolute;
    top: 65px;
    left: 40px;

  }

  .shareing {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;

    .text {
      color: #fff;
      font-size: 22px;
    }

    .colorRed{
      color: #FF4D4F;
    }
  }
}




.core .videoBar {
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
  border-radius: 0px 4px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding-right: 4px;
  align-items: center;

  .compere, .compere2 {
    // float: left;
    margin-right: 4px;
  width:20px;
  height: 20px;
  }

  .userAudioIcon {
    // margin: 2px 0 2px 4px;
    // float: left;
    margin-right: 2px;
    width: 20px;
    height: 20px;
  }

  .userName {
    // height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 12px;
    text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
    // float: left;
    // margin: 2px 6px 2px 2px;
  }

  
}

.core .videoBarRight{
  left: auto;
  padding: 0 10px;
  right: 0;
}
.core .parseVideoPanel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .userName {
    font-size: 50px;
    color: #ffffff;
    position: relative;
    width: 100%;
    text-align: center;
    .parseUnit{
      position: absolute;
      bottom: -30px;
      text-align: center;
      width: 100%;
      left: 0;
      color: #C0C0C0;
      font-size: 18px;
    }
  }
}
</style>