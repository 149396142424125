export const emojiUrl = '@/assets/emotion/'
export const emojiMap = {
  '[微笑]': 'emoji_0.png',
  '[大笑]': 'emoji_1.png',
  '[难过]': 'emoji_2.png',
  '[防疫]': 'emoji_3.png',
  '[疑问]': 'emoji_4.png',
  '[强]': 'emoji_5.png',
  '[握手]': 'emoji_6.png',
  '[鼓掌]': 'emoji_7.png',
  '[玫瑰]': 'emoji_8.png',
  '[爱心]': 'emoji_9.png'
}
export const emojiName = [
  '[微笑]',
  '[大笑]',
  '[难过]',
  '[防疫]',
  '[疑问]',
  '[强]',
  '[握手]',
  '[鼓掌]',
  '[玫瑰]',
  '[爱心]'
]
