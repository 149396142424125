/**
 * 订阅错误处理manager
 */
import { loganLog } from "@/utils/log"

function ExceptionManager(vueInstance) {
  this.vue = vueInstance
  // this.roomClient = this.vue.$roomClient
    
  // 重试任务队列 command：执行重试的命令, userId：参会人id, type：audio/video/share
  this.retryJobList = [];
  // 定时任务
  this.Timer = null
  this.initEvent()
}

// 初始化方法
ExceptionManager.prototype.initEvent = function() {
  // 重试任务定时器
  this.setTimer();
}

// 增加重试任务
ExceptionManager.prototype.addRetryJob = function(userId, element, type) {
  if(userId && element && type){
    try {
      let index = this.isRetryJobExisted(userId, type)
      if(index !== -1){
        loganLog(`已经存在的重试任务,--- userid:${userId}--- type:${type}`)
      } else {
        this.retryJobList.push({
          userId:userId,
          element:element,
          type:type
        })
        loganLog(`addRetryJob,--- userid:${userId}--- type:${type}`)
      }

    } catch (error) {
      loganLog(`addRetryJob error 参数错误,---error:${error}`)
    }
  } else {
    loganLog(`addRetryJob error 参数错误,---userId:${userId}---element:${element}---type:${type}`)
  }
}


// 删除重试任务
ExceptionManager.prototype.deleteRetryJob = function(userId, type) {
  if(userId && type){
      let index = this.isRetryJobExisted(userId, type)
      if(index !== -1){
        this.retryJobList.splice(index, 1); 
        console.error(index)
        loganLog(`deleteRetryJob,--- userid:${userId}--- type:${type}`)
        this.setTimer()
      } else {
        console.log("不存在的重试任务")
      }
  } else {
    loganLog(`deleteRetryJob error 参数错误,---userId:${userId}---type:${type}`)
  }
}

// 清空重试任务
ExceptionManager.prototype.deleteRetryJobAll = function() {
  clearInterval(this.Timer)
  this.retryJobList = []
  this.setTimer()
}

// 清空视频重试任务
ExceptionManager.prototype.deleteRetryJobVideoAll = function() {
  clearInterval(this.Timer)
  for (let i = this.retryJobList.length - 1; i >= 0; i--) {
    if (this.retryJobList[i].type === 'video') {
      this.retryJobList.splice(i, 1)
    }
  }
  this.setTimer()
}


// 判断是否存在重试任务
ExceptionManager.prototype.isRetryJobExisted = function(userId, type) {
  let index = -1
  if(!this.retryJobList || this.retryJobList.length < 1){
    return index
  }
  this.retryJobList.forEach((item,i)=>{
    if(item.userId === userId && item.type === type){
      index = i
    }
  })
  return index
}


// 设置定时任务
ExceptionManager.prototype.setTimer = function() {
  return new Promise(async (resolve, reject) => {
    try {
      this.Timer = setInterval(() => {
        if(this.retryJobList.length < 1){
          return false
        }
        let item
        while(item = this.retryJobList.shift()){ 
          if(!item){
            continue
          }if(item.type === 'share'){
            loganLog(`重新订阅share---userid:${item.userId}`)
            this.vue.$meetingControl.startRemoteShare(item.userId, item.element)
          }else if(item.type === 'audio'){
            loganLog(`重新订阅audio---userid:${item.userId}`)
            this.vue.$meetingControl._handleRemoteAudio(item.userId)
          }else if(item.type === 'video'){
            loganLog(`重新订阅video---userid:${item.userId}`)
            this.vue.$meetingControl.startRemoteVideo(item.userId, item.element)
          }
        }
      }, 10000);
    } catch (error) {
      reject(error)
    }
  })
}


export default ExceptionManager