<!--
* 医百会议-主界面-底部-话筒按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-01
-->
<template>
  <div class="audioBtn">
    <div class="btnLeft" @click="clickLeft" id="meeting_audio_btn_left_headless">
      <my-icon :iconName=" isUseHuaTong ? selfTimevolum == 0 ? 'iconshengyin_24_hei':'iconshengyin_yinliang'+selfTimevolum + '_24_hei' : 'iconshengyin_guanbi_24_hei'"></my-icon>
      <div class="audioInfo">{{ isUseHuaTong ? $t('meeting.audioMute') : $t('meeting.audioUnMute') }}</div>
    </div>
    <div class="btnRight" @click="clickRight" id="meeting_audio_btn_right_headless">
      <my-icon class="audioArrowIcon" :target="'audioArrowIcon'" :fontSize="16"
        :iconName="'iconjiantou_xiajiantou_hei'"></my-icon>
    </div>

    <div class="audioPanel" v-show="isShowPanel" v-extend-panel-manager="onClose" id="audioPanel">
      <div class="title">{{$t('meeting.selectMicrophone')}}</div>

      <template v-for="(device, idx) in micDevicesList">
        <div class="rowItem hover_item" :key="idx" @click="selectedDevice(device)" :title="device.deviceName">
          <div :class="['name',{'selected':device.deviceId === currentSelectedDevice.deviceId }]">
            {{device.deviceName}}</div>
          <my-icon v-if="device.deviceId === currentSelectedDevice.deviceId" class="okIcon" :fontSize="16"
            :iconName="'iconduigou_16_lv'"></my-icon>
        </div>

        <div class="rowBar" :key="idx+'_bar'" v-if="device.deviceId === currentSelectedDevice.deviceId">
          <div class="barTitle" :class="$i18n.locale === 'en-US' ? 'barWidth' : ''">{{$t('setUp.voice')}}</div>

          <el-slider class="slider" @change="volumeChange" input-size="mini" :min="0" :max="100"
            v-model="audioVolumeValue" :show-tooltip="false"></el-slider>

          <div class="valItem">{{audioVolumeValue}}</div>
        </div>
      </template>

      <div class="rowItem" @click="openSetting" id="meeting_audio_win_setting_headless">
        <div class="name">{{$t('setUp.seeting')}}</div>
      </div>

      <div class="rowItem" @click="clickLeft" id="meeting_audio_win_switch_headless">
        <div class="name red" v-if="isUseHuaTong">{{$t('meeting.closeMicrophone')}}</div>
        <div class="name green" v-else>{{$t('meeting.openMicrophone')}}</div>
      </div>
    </div>

    <!-- 通用提示弹窗 -->
    <el-dialog :title="$t('login.tips')" class="commonElDialog" :modal="false" :visible.sync="commonTipDialog"
      width="380px">
      <span>{{ commonTipText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonTipDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import throttle from 'lodash.throttle'
  import { ROLE_CODE } from "@/constant/index";
  import { loganLog } from "@/utils/log"

  export default {
    data() {
      return {
        isShowPanel: false, // 是否显示面板
        selected: "", // 选择值
        commonTipDialog: false,
        commonTipText: '',

        isLock: false,

        audioVolumeValue: 0
      };
    },
    computed: {
      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId);
      },
      isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
      },
      isOrdinaryUser() {
        return this.selfInfo.roleCode === ROLE_CODE.USER;
      },
      allowSelfUnmute() {
        return this.$store.state.meet.allowSelfUnmute
      },
      isUseHuaTong() {
        return this.$store.state.meet.isUseHuaTong;
      },
      micDevicesList() {
        return this.$store.state.device.micDeviceList
      },
      currentSelectedDevice() {
        return this.$store.state.device.currentMicDevice
      },
      audioVolumeState() {
        return this.$store.state.device.audioVolumeValue
      },
      micNoGrant() {
        return this.$store.state.meet.micNoGrant
      },
      selfTimevolum(){
        let _volumNum = this.$store.state.meet.realTimevolumeData[this.$configs.peerId] || 0
        let num  = Math.ceil(_volumNum / 7);
        _volumNum = num ? num+2 > 15 ? 15 : num+2 :0;
        return _volumNum
      }
    },
    watch: {
      isShowPanel(value) {
        this.$store.commit('isShowDeviceSmallWin', value)
      },
      audioVolumeValue(value) {
        this.$store.commit('device/setAudioVolumeValue', value)
      },
      audioVolumeState: {
        immediate: true,
        handler(value) {
          this.audioVolumeValue = value
        }
      }
    },
    
    methods: {

      // 静音操作
      clickLeft: throttle(async function () {
        if(this.$store.state.meet.isNetworkDisconnect){
          this.$parent.showToast(this.$t('meeting.netWorkBad'))
          return
        }
      
        if (this.isLock) {
          console.error('上次开启音频还没完成，直接返回---')
          return
        }
        this.isLock = true

        // 当前isUseHuaTong为false，说明为静音状态，代表用户想要解决静音
        // 不是主持人或者联席主持人
        // 允许成员自我解除静音 1是；0：否
        if (!this.isUseHuaTong && this.isOrdinaryUser && !this.allowSelfUnmute) {
          this.commonTipText = this.$t('meeting.noVoiceFail')
          this.commonTipDialog = true
          this.isLock = false
          return
        }
        //无麦克风权限
        if(this.micNoGrant && this.micNoGrant.code){
          const meetDialogInfo = {
            tips: this.micNoGrant.title,
            showClose : true,
            isGoIndex : false,
            commonText : `错误码:${this.micNoGrant.code}`,
            describe:this.micNoGrant.describe,
            isGoLogin: false,
            ensureText: '',
            conferenceNo: '',
            title: ''
          }
          this.$store.commit("meet/updateGlobalMeetState", {
            meetDialogInfo:meetDialogInfo
          })
          this.isLock = false
          return
        }
        try {
          await this.$meetingControl.muteLocalAudio(this.isUseHuaTong)

          // update vuex isUseHuaTong
          this.$store.commit('member/updateUser', {
            userId: this.$configs.peerId,
            isUseHuaTong: !this.isUseHuaTong
          })
          this.$store.commit("meet/updateGlobalMeetState", {
            isUseHuaTong: !this.isUseHuaTong,
          })

          this.isLock = false
        } catch (error) {
          this.isLock = false
          loganLog(`麦克风点击操作失败。error:${error}`)
          this.$parent.showToast(this.$t('meeting.operationFailed'))
        }
      }, 500, {
        trailing: false
      }),

      // 点击按钮
      async clickRight() {
        if(!this.isShowPanel && !this.$store.state.isShowDeviceSmallWin) {
          this.isShowPanel = true
        } else {
          this.isShowPanel = false
        }  
      },
      // 鼠标点击关闭面板
      onClose(mouseup, mousedown) {
        // if (
        //   mousedown.target.className === "btnRight over" ||
        //   mousedown.target.className.baseVal === "icon16 audioArrowIcon" ||
        //   mousedown.target.className === "audioArrowIcon"
        // ) {
        //   return;
        // }
        this.isShowPanel = false;
      },


      volumeChange(e) {
        // 设置设备音量值
        this.$deviceControl.setCurrentMicDeviceVolume(this.audioVolumeValue)
        loganLog(`会场设置麦克风音量：${this.audioVolumeValue}`)

        // if (this.audioVolumeValue === 0) {
        //   if (this.isUseHuaTong) {
        //     this.clickLeft()
        //   }
        // }
      },

      /**
       * 选中其他设备
       */
      async selectedDevice(device) {
        if (device.deviceId === this.currentSelectedDevice.deviceId) {
          console.log('点击了当前，不做任何操作')
          return
        }

        await this.$deviceControl.setCurrentMicDevice(device.deviceId)
      },

      //打开设置窗组件
      openSetting() {
        this.isShowPanel = false
        this.$emit('openSetting')
      }
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_style(){
    background: #fff;
    height: 48px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
      &:hover{
        background: rgba(0, 0, 0, 0.05) !important;
      }
      &:focus{
       background: rgba(0, 0, 0, 0.1) !important;
      }
  }
    @mixin public_flex(){
      display: flex;
      justify-content: space-between;
    }
    @mixin public_content(){
      content: "";
      width: 0;
      height: 0;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      position: absolute;
      top: -9px;
      left: 10px;
    }
  .audioBtn {
    position: relative;
    @include public_flex;
    height: 48px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    .btnLeft {
      @include public_flex;
      align-items: center;
      padding-left: 10px;
      border-radius: 100px 0px 0px 100px;
      @include public_style;
      .audioInfo {
        width: 56px;
        height: 20px;
        font-size: 14px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px; 
      }
    }

    .btnRight {
      display: flex;
      align-items: center;
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_style;
    }

    .audioPanel {
      width: 192px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 82px;
      left: 16px;
      padding-bottom: 10px;

      .title {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin: 17px 0 4px 16px;
      }
      .hover_item:hover{
        background: rgba(0, 0, 0, 0.05);
      }

      .rowItem {
        @include public_flex;
        width: 100%;
        height: 36px;
        background: #ffffff;
        box-sizing: border-box;
        padding-right: 16px;

        .name {
          height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin: 9px 0 0 16px;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .red {
          color: #FF0000;
        }

        .green {
          color: #1AB370;
        }

        .selected {
          color: #1ab370;
        }

        .okIcon {
          margin-top: 9px;
          margin-left: 10px;
        }
      }

      .rowBar {
        width: calc(100% - 18px);
        height: 47px;
        background: #fafafa;
        border: 1px solid rgba(0, 0, 0, 0.05);
        margin: 9px 0 0 9px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
         @include public_content;
          border-bottom: 9px solid #fafafa;
          z-index: 8;
        }

        &::after {
         @include public_content;
          border-bottom: 9px solid rgba(0, 0, 0, 0.05);
          z-index: 7;
        }

        .barTitle {
          width: 24px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
          // float: left;
          // margin: 16px 0 0 7px;
          margin-left: 7px;
        }

        .barWidth {
          width: 38px;
        }

        .slider {
          flex-grow: 1;
          // margin: 0 5px;
          margin-left: 10px;
          margin-right: 5px;
        }

        .barItem {
          width: 100px;
          height: 17px;
          line-height: 17px;
          margin-top: 14px;
          margin-left: 5px;
          float: left;
        }

        .valItem {
          display: block;
          width: 30px;
          // margin-top: 15px;
          // margin-left: 5px;
          // float: left;
          height: 17px;
          line-height: 17px;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
</style>