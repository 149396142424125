<template>
  <div class="countdown-tip" v-show="isShowCountDownTip" v-drag="'.tips-content'">
    <div class="tips-content">
      <div class="title">
        <span class="closeCountDownTip" @click="closeTip" id="meeting_countdowntip_close_headless">{{$t('setUp.close')}}</span>
        <span :class="['titleColor', getColor]">{{getTitleText}}</span>
      </div>
      <div class="minutes">
        <my-icon class="timeIcon" :target="'timeIcon'" :fontSize="44" :iconName="getColorIcon"></my-icon>

        <span :class="['tip-minutes', getColor]">{{fillZero(minutes)}}:{{fillZero(seconds)}}</span>
      </div>
    </div>
    <div class="tips-button">
      <div class="tips-button-item" @click="resetTip" id="meeting_countdowntip_reset_headless">{{$t('setUp.reset')}}</div>
      <div class="tips-button-item set" @click="openSetPanel" id="meeting_countdowntip_set_headless">{{$t('setUp.set')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 分钟
    minutes: {
      default: "0",
    },
    // 秒
    seconds: {
      default: "0",
    },
    // 正计时还是倒计时
    type: {
      default: "-",
    },
  },
  computed: {
    // 获取样式
    getColor() {
      let that = this;
      let colorName = "";
      if (that.minutes === 0 && that.seconds >= 0) {
        colorName = "CountDownTipColor1";
      }

      if (that.type === "+") {
        colorName = "CountDownTipColor2";
      }

      return colorName;
    },
    // 获取样式
    getColorIcon() {
      let that = this;
      let colorName = "icondaojishi_zhengchang_16_lv";
      if (that.minutes === 0 && that.seconds >= 0) {
        colorName = "icondaojishi_jinggao_16_cheng";
      }

      if (that.type === "+") {
        colorName = "icondaojishi_chaoshi_16_hong";
      }

      return colorName;
    },
    // 获取文本
    getTitleText() {
      return this.type === "-" ? this.$t('meeting.countDown') : this.$t('meeting.timedOut');
    },

    isShowCountDownTip() {
      return this.$store.state.meet.isShowCountDownTip
    }
  },
  methods: {
    // 关闭弹窗
    closeTip() {
      let that = this;

      this.$store.commit("meet/updateGlobalMeetState", {
        isShowCountDownTip: false
      })

      that.$emit("close");
    },
    // 重置时间
    resetTip() {
      let that = this;
      that.$emit("reset");
    },
    // 打开设置时间框
    openSetPanel() {

      // that.$store.commit("isShowCountDownPanel", true);
      this.$store.commit("meet/updateGlobalMeetState", {
        isShowCountDownPanel: true
      })
    },
    // 补0操作
    fillZero(str) {
      let realNum;
      if (str < 10) {
        realNum = "0" + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown-tip {
  width: 300px;
  height: 78px;
  background: rgba(51, 51, 51, 0.65);
  border-radius: 4px;
  z-index: 8;
  position: fixed;
  top: 70px;
  left: 10px;
  display: flex;
  overflow: hidden;

  .tips-content {
    flex: 1 1 auto;
    box-sizing: border-box;
    padding: 8px 10px 0 8px;
    border-right: 2px solid rgba(0, 0, 0, 0.15);
    .title {
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        display: inline-block;
        &:first-child {
          color: #ffffff;
          width: 44px;
          height: 16px;
          padding-left: 6px;
        }
        &:last-child {
          color: #1ab370;
          margin-left: 10px;
          /* width: 48px; */
          height: 16px;
        }
      }
    }
    .minutes {
      .clock-icon {
        display: inline-block;
        width: 44px;
        height: 44px;
        margin-top: 4px;
      }
      .tip-minutes {
        display: block;
        width: 170px;
        font-size: 48px;
        font-weight: normal;
        color: #1ab370;
        line-height: 48px;
        margin-left: 50px;
      }
    }
  }
  .tips-button {
    width: 56px;
    .tips-button-item {
      text-align: center;
      height: 50%;
      line-height: 38px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      &:first-child {
        border-bottom: 2px solid rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.countdown-tip .closeCountDownTip {
  cursor: pointer;
}

.countdown-tip .timeIcon {
  float: left;
}

.titleColor {
  color: #1ab370;
}

.CountDownTipColor1 {
  color: #ff9610 !important;
}

.CountDownTipColor2 {
  color: #ff5656 !important;
}
</style>
