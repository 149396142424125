<template>
  <div class="userManagerBtn" :class="$i18n.locale === 'en-US' ? 'userManagerBtnWidth' : ''">
    <div class='btnLeft' @click="clickLeft" id="meeting_usermanager_btn_headless">
      <img  v-if="lockedState" class='lock-icon' src="~@/assets/images/iconLock.png" alt="">
      <my-icon v-else :target="'userManagerIcon'" :iconName="'iconchengyuan_24_hei'"></my-icon>
      <div class="raise-hand-tip" v-if="isRaiseHand && !isHideHand">
        <div>
          <my-icon class="hand-icon" :iconName="'iconjushou_24_bai'"></my-icon>
          <span>{{raiseHandMsg}}{{$t('meeting.handsIng')}}</span>
        </div>
        <i class="triangle"></i>
      </div>
        <img src="~@/assets/images/menu/choice.svg" alt class="hide-button-choice" />
        <img src="~@/assets/images/menu/choice_checked.svg" alt class="hide-button-choice" />
      <div class="userManagerInfo">{{ (isHost || isCoHost) ? $t('meeting.ManagingMembers') : $t('meeting.member') }}</div>
      <div v-if="userList.length>0" class="user-count-tip">{{userList.length > 999 ? '999+' : userList.length}}</div>
    </div>
    <el-dialog
      v-dialogDragResizable
      custom-class="member-list-dialog el-dialog-drag el-dialog-member border-dialog"
      :modal="false"
      :visible.sync="isShowPanel"
      width="600px"
      :title="$t('meeting.member')+ `(${userList.length + $t('meeting.ren')})`"
      :append-to-body="true"
      :close-on-click-modal="false"
      @closed="dialogClosedHandle"
    >
      <member ref="childMember"></member>
    </el-dialog>
  </div>
</template>

<script>
import { ROLE_CODE } from '@/constant/index'
import Member from "@/components/member/Member";

export default {
  components: { Member },
  data() {
    return {
      isShowPanel: false, // 是否显示面板
      isHideHand:false,// 点击成员列表隐藏举手的toast
      raiseHandMsg: '',
      isRaiseHand:false,
      preRaiseHandUserList:[] // 上一次举手列表，打开一次成员列表后不展示举手状态
    };
  },
  computed:{
    lockedState() {
      return this.$store.state.meet.lockedState
    },

    totolUserNumber() {
      return this.$store.state.member.totolUserNumber
    },

    userList() {
      // 成员列表
      return this.$store.getters["member/getRealUserList"].filter(i => i.userId.indexOf('cloudshare') == -1)
    },

    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    }
  },
  
  watch:{
      userList: {
      handler(newVal) {
        let raiseHandUserList = []
        newVal.forEach(item => {
          if(item.isRaiseHand){
            raiseHandUserList.push(item.userName)
          }
        });

        //如果userList有变化 但是举手的数据没有变化就不操作
        if(this.preRaiseHandUserList.toString() === raiseHandUserList.toString()){
          return
        }

        this.preRaiseHandUserList = raiseHandUserList

        if(raiseHandUserList.length > 0){
            this.isRaiseHand = true
        }else{
            this.isRaiseHand = false
        }
        if (raiseHandUserList.length === 1) {
          if(this.$configs.peerId === raiseHandUserList[0].userId){
            this.isRaiseHand = false
          }else{
            this.raiseHandMsg = raiseHandUserList[0]
          }
        } else {
          this.raiseHandMsg = `${raiseHandUserList.length}` + this.$t('meeting.geren')
        }
        if(!this.isShowPanel){
          this.isHideHand = false
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    // 显示成员列表操作
    clickLeft() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.isHideHand = true 
      this.isShowPanel = !this.isShowPanel
    },
    dialogClosedHandle() {
      const el = document.querySelector('.el-dialog-member')
      this.$refs.childMember.dialogCloseClick()
      if (el) {
        el.style.top = 'initial'
        el.style.left = 'initial'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.userManagerBtn {
  display: flex;
  justify-content: space-between;
  height: 48px;
  position: relative;
  .btnLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 5px 0 16px;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  .raise-hand-tip{
    position: absolute;
    top: -50px;
    left: -50%;
    & > div{
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 12px;
      color: #ffffff;
      background-color: #1ab370;
      border-radius: 4px;
      white-space: nowrap;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      .hand-icon{
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        margin-right: 8px;
        height: 30px;
      }
    }
      .triangle{
        position: absolute;
        left: 47%;
        top: 35px;
        width: 10px;
        height: 10px;
        background-color: #1ab370;
        transform: rotate(45deg);
      }
    }
    .lock-icon {
        display: inline-block;
        width:24px;
        height:24px;
    }
    .userManagerInfo {
      display: flex;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 14px 6px;
    }
    .hide-button-choice{
      width: 0;
      height: 0;
      opacity: 0;
    }

    .user-count-tip {
      height: 18px;
      min-width: 18px;
      border-radius: 12px;
      font-weight: 500;
      color: #FFFFFF;
      background: #1AB370;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      padding-left: 3.5px;
      padding-right: 3.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(.8);

      position: absolute;
      top: 3px;
      left: 26px;
      z-index: 1;
    }
  }
}
</style>
<style scoped lang="css">
/deep/ .member-list-dialog > .el-dialog__header button{
  top: 14px;
  right: 14px;
  z-index: 1;
}
/deep/ .member-list-dialog > .el-dialog__header button i{
  font-size: 17px;
  color: #222;;
}
/deep/ .member-list-dialog > .el-dialog__header button:hover i{
  color: #222;
}
/deep/ .member-list-dialog > .el-dialog__body{
  padding: 0;
  height: calc(100% - 48px);
  overflow: hidden;
}
</style>
