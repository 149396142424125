<template>
  <div class="lecturerColumn">
    <topShareControl v-show="isShowMenuBar"/>

    <!-- 中间大画面区域 -->
    <div class="layoutCoreOne">
      <layoutCoreOne
        ref="layoutCoreOne"
        :isFullModel="isFullModel"
        :isShowMenuBar="isShowMenuBar"
        type="col"
      ></layoutCoreOne>

      <div v-if="isShareDoc" class="share-warp" data-html2canvas-ignore  onselectstart="return false;" style="-moz-user-select:none;">
        <div class="share" id="shareDocument"></div>
        <div class="doc-control-warp">
          <div class="doc-content left">
            <my-icon class='host_img'  :fontSize="20"  @click.stop="throttleHandle(prevPageClick)" iconName="iconjiantou_zuojiantou_24_bai" />
            <span onselectstart="return false;" style="-moz-user-select:none;">{{curPage}}/{{totalPage}}</span>
            <my-icon class='host_img'  :fontSize="20" @click.stop="throttleHandle(nextPageClick)" iconName="iconjiantou_youjiantou_24_bai" />
          </div>
          <div class="doc-content mid"> <span @click.stop="debounceHandle(switchFileHandle)" onselectstart="return false;" style="-moz-user-select:none;">切换文档</span> <span id="stopShareElement"  @click="debounceHandle(stopShareClick)"  onselectstart="return false;" style="-moz-user-select:none;">关闭文档</span> </div>
          <div class="right"><div class="doc-content" :class="isShowThumbnail?'isShowThumbnail':''"  @click='changeShow'><span id="stopShareElement">{{isShowThumbnail?'收起缩略图':'缩略图'}}</span></div></div>
        </div>
        <div class="thumbnail" v-if="isShowThumbnail && thumbData && thumbData.length>0">
          <div class="thumbnail-ul" v-for="(item,index) in thumbData" :key='index' @click.stop="pageJump(index+1)">
            <div class="imgwarp" :class="index+1 == curPage? 'on-checked':''">
              <div class="checked-icon" v-if="index+1 == curPage">
                <my-icon class='iconwangluo' iconName="iconfuxuankuang_yixuanze_16_lv" :fontSize="16" />

              </div>
              <img :src="item" crossOrigin="anonymous" alt="缩略图">
            </div>
            <span :class="index+1 == curPage? 'on-checked-text':''">{{index+1}}</span>
          </div>
        </div>
      </div>
      <div id="shareDocumentMask" v-if="isShareDoc" @click.stop="pptHandlerClick"></div>
      <div id="stopDocBtn" v-if="isShareDoc && curPage == totalPage && totalPage !=1" @click.stop="debounceHandle(stopShareClick)">关闭文档共享</div>

    </div>
    
    <!-- 右侧列表视频区域 -->
    <layoutList
      :isFullModel="isFullModel"
      type="col"
    ></layoutList>
  </div>
</template>

<script>
import LayoutCoreOne from "./Meeting.Layout.CoreOne.vue"
import LayoutList from "./Meeting.Layout.List.vue"
import topShareControl from "./topShareControl.vue"
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import { loganLog } from "@/utils/log"

import {THROTLE_TIME } from "@/constant/index";

export default {
  props:['isShowMenuBar'],
  components: {
    layoutCoreOne: LayoutCoreOne,
    layoutList: LayoutList,

    topShareControl
  },
  data () {
    return {
      isShowThumbnail:false,//是否打开缩略图
    }
  },
  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    },
    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    totalPage() {
      return this.$store.state.meet.shareDocTotalPage
    },
    curPage() {
      return this.$store.state.meet.curPage
    },
    shareType() {
      return this.$store.state.meet.shareType
    },

    thumbData() {
      return this.$store.state.meet.thumbData
    },
    
  },
  methods: {
    debounceHandle: debounce(fn => fn && fn(), 1000, { leading: true, trailing: false }),
    throttleHandle: throttle(fn => fn && fn(), 1000, { leading: true, trailing: false }),
    prevPageClick(){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.$documentManager.pageTurningPre()
    },
    nextPageClick(){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.$documentManager.pageTurningNext()
    },
    pptHandlerClick:throttle(function(e){
        // if (lockDocPage.value){ 
        //   return;
        // }  
        if(this.$store.state.meet.isNetworkDisconnect){
          this.$parent.showToast(this.$t('meeting.netWorkBad'))
          return
        }

        let clickLeft = e.x;
        let clickTop = e.y;
        const sliderDom= document.querySelector('#shareDocumentMask');
        const offset = this.getOffset(sliderDom);
        console.log('current offset:', offset);
        // const sliderLeft = sliderDom.
        clickLeft = clickLeft - offset?.left;
        clickTop = clickTop - offset?.top;
        let server = this.formatServerPos(clickLeft, clickTop, null);
          let data = {
            x: clickLeft,
            y: clickTop,
            server: {
              x: server.x,
              y: server.y
            }
          }
        this.$documentManager.clickAction(data)
    }, 1000, {
      trailing: false
    }),
    getOffset(curEle) {
      if(!curEle) return;
        let totalLeft = 0,
        totalTop = 0,
        par = curEle.offsetParent;

        //首先加自己本身的左偏移和上偏移
        totalLeft+= curEle.offsetLeft;
        totalTop+= curEle.offsetTop
        //只要没有找到body，我们就把父级参照物的边框和偏移也进行累加
        while(par){
        if(navigator.userAgent.indexOf("MSIE 8.0")===-1){
            //累加父级参照物的边框
            totalLeft+= par.clientLeft;
            totalTop+= par.clientTop
        }
        //累加父级参照物本身的偏移
        totalLeft+= par.offsetLeft;
        totalTop+= par.offsetTop
        par = par.offsetParent;
        }
        return{
        left:totalLeft,
        top:totalTop
      }
    },
    formatServerPos(x, y, screen) {
      let screenWidth, screenHeight;
      if(!screen) {
        const pptWrap = document.querySelector('#shareDocumentMask');
        screenWidth = pptWrap.offsetWidth;
        screenHeight = pptWrap.offsetHeight;
      }
      if(x > screenWidth || y > screenHeight) return;
      // x / screenX = targetX / 1920
      // targetX = 1920 * bits
      let targetX = (x / +screenWidth) * 1920;
      let targetY = (y / +screenHeight) * 1080;
      console.log('目标对象：', Math.floor(targetX), Math.floor(targetY))
      return {x:  Math.floor(targetX), y: Math.floor(targetY)}
    },
    async switchFileHandle(){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      if(this.shareType == 2){
        this.$parent.isOneySharePermis()
      } else {
        this.$parent.isShowDocumentPanel = true
      }
    },
    pageJump(num){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.$documentManager.pageJump(num)
    },

    async stopShareClick(){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.$confirm('确认关闭正在演示的文档?', '关闭文档', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async() => {
        try {
          this.isShowThumbnail = false
          await this.$documentManager.docShareStopFn(this.$configs.roomId,this.$configs.userId)
        } catch (error) {
          loganLog(`关闭文档错误---${JSON,stringify(error)}`)
        }
      }).catch(() => {});
    },
    async changeShow(){
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      
      if(this.thumbData.length>0){
        this.isShowThumbnail = !this.isShowThumbnail
        this.$nextTick(() => {
          // 处理刷新时页码不在第一屏时
          let hei = document.querySelector(".thumbnail");
          let heiUl = document.querySelector(".thumbnail-ul");
          if(this.curPage > 6) hei.scrollLeft = this.curPage * (heiUl.offsetWidth + 8) - heiUl.offsetWidth
        })
      } else{
        let res = await this.$documentManager.getThumbData()

        if(res && res.length > 0){
          this.isShowThumbnail = !this.isShowThumbnail
          this.$nextTick(() => {
          // 处理刷新时页码不在第一屏时
            let hei = document.querySelector(".thumbnail");
            let heiUl = document.querySelector(".thumbnail-ul");
            if(this.curPage > 6) hei.scrollLeft = this.curPage * (heiUl.offsetWidth + 8) - heiUl.offsetWidth
          })
        }else{
          this.$parent.showToast('缩略图正在生成中，请稍后重试')
        }
      }
    }
  },
  created() {
    this.$store.commit("member/updatePageIndex", 1)
    this.$store.commit("member/updatePageSize", 4)

    document.onkeydown = (event)=>{
      if(event.key === 'ArrowLeft'||event.key === 'ArrowUp'||event.key === 'PageUp'){
        this.throttleHandle(this.prevPageClick)
      }else if (event.key === 'ArrowRight'||event.key === 'ArrowDown'||event.key === 'PageDown'){
        this.throttleHandle(this.nextPageClick)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lecturerColumn {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.layoutCoreOne{

  position: relative;
}
#shareDocumentMask{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  z-index: 2;
}
#stopDocBtn{
  width: 144px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 20px 50px 0px rgba(0,0,0,0.3), 0px 0px 16px 0px rgba(0,0,0,0.5);
  border-radius: 43px;
  color: #FF4D4F;
  font-size: 16px;
  margin-top: -24px;
  margin-left: -72px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top:50%;
  left: 50%;
  z-index: 4;
}

.share-warp{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  #shareDocument{
    width: 100%;
    height: 100%;
  }
  .doc-control-warp{
    position: absolute;
    z-index:3;
    width: 100%;
    bottom: 100px;
    display: flex;
    justify-content: space-around;
    .doc-content{
      display: flex;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 12px;
      overflow: hidden;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0 16px 0;
      line-height: 24px;
      height: 24px;
      cursor: pointer;
      span{
        padding: 0 8px;
        &:hover{
          background: #000000;
        }
      }
      .iconfont-content{
        &:hover{
          background: #000000;
        }
      }
    }
    .right{
      width: 90px;
      text-align: center;
      .doc-content{
        width: 58px;
        text-align: center;
      }
      .isShowThumbnail{
        width: 78px;
      }
    }
  }

  .thumbnail{
    position: absolute;
    max-width: 540px;
    z-index: 10;
    background:rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    bottom: 128px;
    padding: 8px 0 0 8px;
    right: 24px;
    overflow-x: scroll;
    padding-bottom: 8px;
    overflow-y: hidden;
    display: flex;
    .thumbnail-ul{
      margin-right: 8px;
      span{
        height: 12px;
        display: block;
        width: 86px;
        text-align: center;
        line-height: 12px;
        margin-top: 8px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .imgwarp{
        width: 86px;
        height: 48px;
        border-radius: 2px;
        position: relative;
        .checked-icon{
          position: absolute;
          top: 0;
          right: 0;
          width: 16px;
          height: 16px;
          background: #1AB370;
          border-radius: 0px 0 0px 12px;
          .iconwangluo{
            position: absolute;
            top: -2px;
            right: -2px;
            transform: scale(0.7);
          }
          .iconfont-content{
            margin: -2px 0 0 2px;
          }
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
      .on-checked-text{
        color:#1AB370;
      }
      .on-checked{
        border: 1px solid #1AB370;
      }
    }
  }
  .thumbnail::-webkit-scrollbar{
    width: 5px;
  }
  .thumbnail::-webkit-scrollbar-thumb{
    background: #666;
    border-radius: 5px;
    height: 10px;
    width: 2px;
  }

}
</style>