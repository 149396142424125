import { render, staticRenderFns } from "./Meeting.Layout.tab2.vue?vue&type=template&id=27c1edf6&scoped=true"
import script from "./Meeting.Layout.tab2.vue?vue&type=script&lang=js"
export * from "./Meeting.Layout.tab2.vue?vue&type=script&lang=js"
import style0 from "./Meeting.Layout.tab2.vue?vue&type=style&index=0&id=27c1edf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c1edf6",
  null
  
)

export default component.exports