<template>
  <div>
    <el-dialog :visible.sync="messageBox.isShow" :modal="false" :width="$i18n.locale === 'en-US' ? '530px' : '512px'"
      center @closed="closePanel">
      <div class="setting-network">
        <div class="network-result">
          <div class="network-result-header">
            <div class="status-icon">
              <my-icon v-if="!showNetworkResult" class="trans-icon-48" :iconName="'iconshezhi_wangluojiance_64_lv'"
                :fontSize="64"></my-icon>
              <my-icon v-else class="trans-icon-48" :iconName="statusIconClassName" :fontSize="64"></my-icon>
            </div>
            <div class="status-content">
              <div class="status-title" v-if="showNetworkResult">{{$t('setUp.networkStatus')}}{{statusTitle}}</div>
              <div class="status-title" v-if="!showNetworkResult">{{$t('setUp.inNet')}}<span
                  v-if="currentNetworkProgressValue">（{{currentNetworkProgressValue + '/' + networkProgressValue}}）</span>
              </div>
              <div class="status-sub-title" v-if="showNetworkResult && errorCount === 0">
                {{$t('setUp.netOk')}}{{statusTitle}}</div>
              <div class="status-sub-title" v-if="showNetworkResult && errorCount > 0">{{$t('setUp.Detected')}}<span
                  class="error">{{errorCount}}</span>{{$t('setUp.someQuestion')}}</div>
            </div>
          </div>
          <div class="network-result-status">
            <ul>
              <!-- <li class="result-item">
                  <div class="result-name">网络权限</div>
                  <div class="result-status">正常</div>
                </li> -->
              <li class="result-item">
                <div class="result-name">{{$t('setUp.netConnection')}}</div>
                <div class="result-status loading" v-if="currentNetworkProgressValue === 0">{{$t('setUp.detecting')}}
                </div>
                <div class="result-status" v-if="currentNetworkProgressValue > 0"
                  :class="{ 'error' : localNetworkTime <= 0 }">
                  {{localNetworkTime > 0 ? $t('setUp.ok') :  $t('setUp.overTime')}}</div>
              </li>
              <li class="result-item">
                <div class="result-name">{{$t('setUp.serverConnection')}}</div>
                <div class="result-status wait" v-if="currentNetworkProgressValue === 0">{{$t('setUp.tobeTested')}}
                </div>
                <div class="result-status loading" v-if="currentNetworkProgressValue === 1">{{$t('setUp.detecting')}}
                </div>
                <div class="result-status" v-if="currentNetworkProgressValue > 1"
                  :class="{ 'error' : serverNetworkTime <= 0 }">
                  {{serverNetworkTime > 0 ? $t('setUp.ok') :  $t('setUp.overTime')}}</div>
              </li>
              <li class="result-item">
                <div class="result-name">{{$t('setUp.mediaConnection')}}</div>
                <div class="result-status wait" v-if="currentNetworkProgressValue < 2">{{$t('setUp.tobeTested')}}</div>
                <div class="result-status loading" v-if="currentNetworkProgressValue === 2">{{$t('setUp.detecting')}}
                </div>
                <div class="result-status" v-if="currentNetworkProgressValue > 2"
                  :class="{ 'error' : serverNetworkTime <= 0 }">
                  {{serverNetworkTime > 0 ? $t('setUp.ok') :  $t('setUp.overTime')}}</div>
              </li>
              <!-- <li class="result-item">
                  <div class="result-name">域名解析耗时</div>
                  <div class="result-status">正常</div>
                </li> -->
              <li class="result-item">
                <div class="result-name">{{$t('setUp.connectionTime')}}</div>
                <div class="result-status wait" v-if="currentNetworkProgressValue < 3">{{$t('setUp.tobeTested')}}</div>
                <div class="result-status loading" v-if="currentNetworkProgressValue === 3">{{$t('setUp.detecting')}}
                </div>
                <div class="result-status" v-if="currentNetworkProgressValue > 3"
                  :class="{ 'error' : serverNetworkTime <= 0 || serverNetworkTime >= 300 }">
                  {{serverNetworkTime > 0 ? serverNetworkTime + 'ms' : $t('setUp.overTime')}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="button-area">
          <el-button class="exit" v-if="networkLoading" :plain="true" @click="closePanel">{{$t('setUp.tuiTest')}}
          </el-button>
          <el-button class="reload" v-if="!networkLoading" @click="startNetworkDetection">{{$t('setUp.againDec')}}
          </el-button>
          <el-button class="close" v-if="!networkLoading" @click="closePanel">{{$t('setUp.close')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    signalUrl
  } from '@/config/index';
  import {
    NETWORK_TEST_URL
  } from "@/constant/index";
  import {
    ping
  } from "@/utils/network"
  export default {
    data() {
      return {
        messageBox: {
          isShow: false
        },
        networkProgressValue: 4,
        currentNetworkProgressValue: 0,
        showNetworkResult: false,
        networkLoading: true,
        localNetworkTime: 0,
        serverNetworkTime: 0,
        statusIconClassName: '',
        statusTitle: '',
        errorCount: 0
      }
    },
    props: {
      isShowPanel: {
        default: true
      }
    },
    computed: {},
    watch: {
      isShowPanel(value) {
        this.messageBox.isShow = value
        value && this.startNetworkDetection()
      },
      currentNetworkProgressValue(value) {
        if (value === this.networkProgressValue) {
          this.showNetworkResult = true
          this.networkLoading = false
        }
      },
      serverNetworkTime(value) {
        if (value < 0 || this.errorCount > 0) { //-1 超时
          this.statusIconClassName = 'iconwangluojiance_wuwang_48_hong'
          this.statusTitle = this.$t('setUp.abnormal')
        } else if (value > 0 && value < 100) { //正常 良好
          this.statusIconClassName = 'iconwangluojiance_wancheng_48_lv'
          this.statusTitle = this.$t('setUp.ok')
        } else if (value >= 100 && value < 300) { //较差
          this.statusIconClassName = 'iconwangluojiance_jiaocha_48_cheng'
          this.statusTitle = this.$t('setUp.poor')
        } else if (value >= 300) { //极差
          this.statusIconClassName = 'iconwangluojiance_jiaocha_48_cheng'
          this.statusTitle = this.$t('setUp.range')
        }
      }
    },
    created() {},
    methods: {
      closePanel() {
        this.$emit('close')
      },
      init() {
        this.currentNetworkProgressValue = 0
        this.showNetworkResult = false
        this.networkLoading = true
        this.localNetworkTime = 0
        this.serverNetworkTime = 0
        this.statusIconClassName = ''
        this.statusTitle = ''
        this.errorCount = 0
      },
      //网络检测
      async startNetworkDetection() {
        this.init()
        // 1.网络连接情况
        const local = await ping(NETWORK_TEST_URL, 0.3)
        this.localNetworkTime = local.pingTime
        this.currentNetworkProgressValue = 1
        if (this.localNetworkTime <= 0) {
          this.errorCount++
        }

        // 2.服务器连接 + 连接耗时 
        const server_url = 'https://' + signalUrl.replace('/signal-server', '') + '/test/test.png'
        const server = await ping(server_url, 0.3)
        this.serverNetworkTime = server.pingTime
        this.currentNetworkProgressValue = 2
        if (this.serverNetworkTime <= 0) {
          this.errorCount++
        }
        setTimeout(() => {
          this.currentNetworkProgressValue = 3
          if (this.serverNetworkTime <= 0) {
            this.errorCount++
          }
        }, 1000)
        setTimeout(() => {
          this.currentNetworkProgressValue = 4
          if (this.serverNetworkTime <= 0) {
            this.errorCount++
          }
        }, 2000)
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 0;
  }

  @mixin status_style($size, $weight, $color, $line) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
    line-height: $line;
  }
  @mixin btn_style($border, $color, $hover, $focus, $bg:#fff) {
    width: 100px;
    height: 36px;
    color: $color;
    background: $bg;
    border: $border;
    padding: 10px 0;
    border-radius: 4px;
    &:hover {
      background: $hover;
    }
    &:focus {
      background: $focus;
    }
  }
  @mixin dis_style(){
    display: flex;
    align-items: center;
  }
  @mixin base_style($w,$h){
    width: $w;
    height: $h;
  }

  .setting-network {
    position: relative;
    box-sizing: border-box;
    height: 100%;

    .network-result {
      box-sizing: border-box;
      padding-left: 24px;
      padding-right: 24px;

      .network-result-header {
        @include dis_style;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        height: 82px;
        .status-icon {
          @include base_style(48px,48px);
        }

        .trans-icon-48 {
          transform: scale(.75);
          transform-origin: top;
        }

        .status-content {
          flex: 1 1 auto;
          box-sizing: border-box;
          padding-left: 20px;

          .status-title {
            @include status_style(16px, 600, #000, 18px)
          }

          .status-sub-title {
            @include status_style(12px, 400, #333, 14px);
            margin-top: 6px;
          }
        }
      }

      .network-result-status {
        padding-top: 30px;
        box-sizing: border-box;

        .result-item {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          .result-name {
            @include  status_style(14px,400,#333,20px);
          }

          .result-status {
            @include  status_style(14px,400,#1AB370,20px);
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .error {
      color: #FF4D4F !important;
    }

    .loading {
      color: #FF8200 !important;
    }

    .wait {
      color: rgba(153, 153, 153, 1) !important;
    }

    .button-area {
      @include dis_style;
      @include base_style(100%,80px);
      justify-content: center;
      border-top: 1px solid #E5E5E5;
      margin-top: 75px;
    }

    .exit {
      @include btn_style(1px solid #FF4D4F, #FF4D4F, rgba(255, 77, 79, 0.1), rgba(255, 77, 79, 0.2), 1px solid #FF4D4F)
    }

    .reload {
      @include btn_style(#1AB370, #FFF, #3BBF82, #0E8C5A, #1AB370);
    }

    .close {
      @include btn_style(1px solid rgba(0, 0, 0, 0.2), #333, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1));
    }
  }
</style>