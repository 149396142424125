<!--
* 医百会议-主界面-顶部右边-时间功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div class="time">
    <div class="timeValue">{{currentTime}}</div>
  </div>
</template>

<script>
  import {
    getLocalDeviceInfo
  } from "@/utils/device"
  export default {
    mounted() {
      let that = this
      // 开始计数器功能
      that
        .$timer(1, true)
        .setUpdate(function (time) {
          that.currentTime = time;
        })
        .start();
    },
    data() {
      return {
        currentTime: "00:00", // 时间
      };
    },
  };
</script>

<style lang="scss" scoped>
  .time {
    width: 58px;
    height: 24px;
    background: rgba(51, 51, 51, 0.4);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    margin-right: 10px;

    // margin: 16px 0 0 31px;
    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }

    &:focus {
      background: rgba(153, 153, 153, 0.3);
    }

    .timeValue {
      width: 50px;
      height: 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 16px;
      text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
      margin: 4px;
      text-align: center;
    }
  }
</style>