<template>
  <div
      style="width:16px;height:16px;"
      :class="messageIconClass"
      @click="handleIconClick"
  >{{messageIconClass==='message-send-fail'? '!':''}}
  </div>
</template>

<script>
export default {
  name: 'MessageStatusIcon',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    messageIconClass () {
      switch (this.message.status) {
        case 'unSend':
          return 'el-icon-loading'
        case 'fail':
          return 'message-send-fail'
        default:
          return ''
      }
    }
  },
  methods: {
    handleIconClick () {
      if (this.messageIconClass === 'message-send-fail') {
        console.error('失败重发----')
        console.log(this.message)

        this.$emit('reSend', this.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .message-send-fail {
    display: inline-block;
    background-color: #f35f5f;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    position: absolute;
    left: -22px;
    vertical-align: middle;
    top: 0;
    bottom: 0;
    margin: auto;
  }
</style>
