function toDub(n) {
    return n < 10 ? ("0" + n) : String(n)
}
  
export const countDownTimer = (maxMinutes, changeCb, endCb) => {
    let maxTime = maxMinutes * 60 // 秒

    let minutes = 0
    let seconds = 0

    const timer = setInterval(() => {
        if (maxTime >= 0) { // 倒计时进行中
            minutes = Math.floor(maxTime / 60)
            seconds = Math.floor(maxTime % 60)

            maxTime = maxTime - 1

            changeCb(toDub(minutes), toDub(seconds))
        } else { // 倒计时结束
            clearInterval(timer)
            endCb()
        }
    }, 1000)

    return timer
}
