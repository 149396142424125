/**
 * 文档管理manager
 */
import { loganLog } from "@/utils/log"
import { queryDocumentShareData,qureyFileUpload,queryDocFileData,documentShareStop,documentShareStart,queryDocumentThumbnail } from '@/api/meet'
import YB_Doc from "@ybmeet/yb_doc_sdk";
import {getToken} from '@/utils/auth';
import { LAYOUT_CONFIG } from "@/constant/index";
import { imEnv } from '@/config/index'

function DocumentManager(vueInstance) {
  this.vue = vueInstance
  this.curPage = 0
  this.clickActionReady = false
  this.fileId = ''
  // console.error('new DocumentManager')
}

// 恢复档共享
DocumentManager.prototype.startDocRecovery = async function(fileData,userId) {
  // console.error('[DocumentManager] recovery')
  //获取云分享文件信息
  this.startDocShare({
    curPage:fileData.curPage,
    docId:fileData.id,
    fileId:fileData.fileId,
    url:fileData.url,
  },userId)
}

// 结束文档共享
DocumentManager.prototype.docShareStopFn =  async function(roomid,userId){
  const fileData = await queryDocumentShareData({
      roomid: roomid
  })
  if(fileData.id && fileData.userId == userId){
      loganLog(`关闭文档--roomid${roomid}--userId${userId}-fileData${fileData.id}`)
      await documentShareStop({
          fileId: fileData.id,
          userId:userId
      })
  }else{
    return
  }
  //释放sdk中文档变量、事件等
  this.ybDoc.destory()
  // 结束的时候释放 文档SDK 句柄
  this.ybDoc = null;

  this.vue.$store.commit("meet/updateGlobalMeetState", {
    isShareDoc:false,
    shareFireId:null,
    thumbData:[]
  })
}
// 文件应用
DocumentManager.prototype.queryFile = async function(_data){
  try {
      // const resData = await queryDocFileData({
      //     fileId: _data.id
      // })
      console.error('文件应用isShareDoc:',_data)
      if(_data.isShareDoc){ //应用的时候如果有共享先停止
          await this.docShareStopFn(_data.meetInfo.roomId,_data.meetInfo.userId)
      }

      let params = {
          type:"all",
          roomId:_data.meetInfo.roomId,
          url:_data.officeUrl,
          userId:_data.meetInfo.userId,
          businessCode:_data.businessCode,
          totalPage:_data.totalPage,
          fileId: _data.id,
          metadata:{
              displayName:`${_data.meetInfo.userName}`
          },
      }

      console.error('shareStart satrt',params)
      //获取docId
      const shareData = await documentShareStart(params)

      this.vue.$store.commit("meet/updateGlobalMeetState", {
        layoutType: LAYOUT_CONFIG.COLUMN,
        isShareDoc:true,
        shareFireId:_data.id,
        shareDocTotalPage:_data.totalPage,
        curPage:1
      });

      let fileData = {
        url:encodeURI(params.url),
        docId:shareData,
        curPage:1,
        fileId:_data.id
      }
      this.vue.$nextTick(() => {
        this.startDocShare(fileData,_data.meetInfo.userId)
      })
  } catch (error) {
    loganLog('应用文档 error',error)
  }
}

// 上传方法
DocumentManager.prototype.uploadFileFn = async (uploadData, onUploadProgress) => {
  if(uploadData.cancel){
      return qureyFileUpload(uploadData)
  }
  const formData = new FormData()
  formData.append('file', uploadData.file)
  const fileUploadDTO = {
      businessCode: 10002,
      userId: uploadData.userId,
      saveForever:uploadData.saveForever,
      type:uploadData.type,
      conferenceId:uploadData.conferenceId,
      conferenceNo:uploadData.conferenceNo,
  }

  loganLog(`uploadFileFn run --fileUploadDTO:${JSON.stringify(fileUploadDTO)}`)
  const json = JSON.stringify(fileUploadDTO);
  const blob = new Blob([json], {type: 'application/json'});
  formData.append('fileUpload', blob);
  try {
      const resData = await qureyFileUpload(formData, onUploadProgress)
      const data = {
          classId:uploadData.guestId,
          shareFileId:resData && resData.id,
          name:resData && resData.fileName
      }
      return Promise.resolve(resData)
  } catch (error) {
      loganLog('uploadFileFn error',error)
      return Promise.reject();
  }
}


// 实例文档SDK
DocumentManager.prototype.startDocShare = async function(fileData,userId) {
  console.error('[DocumentManager] startDocShare',fileData,userId)
  this.clickActionReady = false
  
  let ele,_el = document.getElementById('shareDocument');
  this.curPage = fileData.curPage;
  while ((ele = _el && _el.firstChild)) {
      ele.remove();
  }

  if(this.ybDoc) {
      this.ybDoc = null;
  }

  if(!fileData.url) return;
  console.log('new yb_doc------',getToken(),this.ybDoc)
  let _reqData = {
    url:fileData.url,
    domId: "#shareDocument",
    docId: fileData.docId,
    userId: userId,
    initPage: this.curPage
  }, _token = getToken()

  // if(_token) _reqData.token = `Bearer ${_token}` //这里先注释掉 云共享先不传token 这里各种401的问题  不传token反而翻页没有问题
  loganLog(`应用文档参数：${JSON.stringify(_reqData)}`)

  // 每次发去设置一下doc_env 异常情况下sessions里没有环境变量会导致请求接口环境错误
  sessionStorage.setItem('doc_env', imEnv)
  
  this.ybDoc = new YB_Doc({..._reqData})

  try {
    console.log('更新文档id: ', fileData.docId);
    await this.ybDoc.updateInfo({ docId: fileData.docId });
  } catch (error) {
    loganLog('更新文档sdk--docId信息失败：', error);
  }

  this.ybDoc.on('initd', (datas) => {
    // 文档 - 初始化完毕
    console.log('[debug info] document initd', datas, this.curPage);
    const timmer =  setTimeout(() => {
          this.ybDoc.jumppage(this.curPage, true);
          console.log('[debug info]', '跳转到 ')
          clearTimeout(timmer) 
      }, 200)
  })

  await this.ybDoc.initPage()

  setTimeout(() => {
      document.getElementsByTagName('iframe')[0].blur();
  }, 1800)

  this.ybDoc.on('pagechange',(datas)=>{
    this.curPage = datas;
    try{
        document.body.dispatchEvent(new Event('click'));
    } catch(e) {
      loganLog('翻页遇到异常', e)
    }
    console.error('pagechange',datas)
    
    this.vue.$store.commit("meet/updateGlobalMeetState", {
      curPage:datas
    })
  })
  
  setTimeout(() => {
    this.clickActionReady = true
  }, 2000);


  this.fileId = fileData.fileId
  this.getThumbData()
}


DocumentManager.prototype.getThumbData = async function(){
  // let thumbData = await this.ybDoc.getThumb(fileId)
  let fileId = this.fileId
  let thumbData = await queryDocumentThumbnail({fileId: fileId})
  loganLog(`getThumbData --fileId:${fileId} --thumbData:${thumbData}`)
  if(thumbData && thumbData.length > 0){
    this.vue.$store.commit("meet/updateGlobalMeetState", {
      thumbData:thumbData
    })
    return thumbData
  }
}

DocumentManager.prototype.pageTurningPre = function(){
  if(this.clickActionReady){
    this.ybDoc.prevPage()
  }
}
DocumentManager.prototype.pageTurningNext= function(){
  if(this.clickActionReady){
    this.ybDoc.nextPage()
  }
}

DocumentManager.prototype.pageJump = function(num){
  console.error('执行pageJump',num)
  if(this.ybDoc.jumppage){
      this.ybDoc.jumppage(num)
  }else{
      setTimeout(() => {
          loganLog(`恢复页数jumppage方法不存在--${num}`)
          this.pageJump(num)
      }, 500);
  }
}

DocumentManager.prototype.resumePageJump = async function(){
  this.clickActionReady = false
  console.error('执行resumePageJump')
  const fileData = await queryDocumentShareData({
    roomid: this.vue.$route.query.roomID
  })
  this.ybDoc.jumppage(fileData.curPage)
  setTimeout(() => {
    this.clickActionReady = true
  }, 2000);
}


DocumentManager.prototype.clickAction = function(data){
  console.error('clickAction',data)
  if(this.clickActionReady){
    this.ybDoc.click(data);
  }else{
    loganLog('[document ] clickAction 没准备好')
  }
}

export default DocumentManager