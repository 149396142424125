<!--
* 医百会议-主界面-底部-倒计时按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-04
-->
<template>
  <div class="countdownBtn">
    <div class="btnLeft" @click="clickLeft" id="meeting_countdown_btn_headless">
      <my-icon :target="'countdownIcon'" :iconName="'icondaojishi_24_hei'"></my-icon>
      <div class="countdownInfo" >{{$t('meeting.timerDown')}}</div>
    </div>

    <el-dialog
      custom-class="countdown-dialog el-dialog-drag"
      :modal="false"
      top="30vh"
      :visible.sync="isShowCountDownPanel"
      width="360px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"

      :title="$t('meeting.timerDown')"
      v-dialogDrag
    >
      <div class="countdownPanel">
        <div class="countdown-container">
          <div class="countdown-header">
            <my-icon
                class="countdown-close closeIcon"
                :iconName="'iconxitonglan_guanbi_hei'"
                :fontSize="16"
                @click="handleCancel"
            ></my-icon>
          </div>
          <div class="countdown-content">
            <div class="countdown-item">
              <span class="title">{{$t('meeting.countDownTime')}}</span>
              <el-input-number :precision="0" @blur="valBlurHandle" size="mini" v-model="initTimeVal" :min="1" :max="1000"></el-input-number>
              <span class="countdown-unit">{{$t('login.mintues')}}</span>
            </div>
            <div class="place_style">{{$t('reg.countTime')}}</div>
            <div class="countdown-item">
              <span class="errorTip" ref="errorTip"></span>
            </div>
          </div>
          <div class="countdown-footer">
            <el-button size="small" @click="handleCancel" id="meeting_countdown_cancel_headless">{{$t('login.cancel')}}</el-button>
            <el-button class='onSure' type="primary" size="small" @click="handleStart" id="meeting_countdown_save_headless">{{$t('login.save')}}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  mounted() {},
  data() {
    return {
      initTimeVal: 30,
      minutes: 0,
      seconds: 0,
      timer: null
    };
  },
  computed: {
    isShowCountDownPanel() {
      return this.$store.state.meet.isShowCountDownPanel
    }
  },
  methods: {
    valBlurHandle() {
      if (!this.initTimeVal) {
        this.initTimeVal = 1
      }
    },
    clickLeft() {

      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      this.$store.commit("meet/updateGlobalMeetState", {
        isShowCountDownPanel: !this.isShowCountDownPanel
      })
    },

    // 取消
    handleCancel() {
      this.$store.commit("meet/updateGlobalMeetState", {
        isShowCountDownPanel: false
      })

      this.initTimeVal = 30
    },

    // 确定
    handleStart() {
      this.$store.commit("meet/updateGlobalMeetState", {
        isShowCountDownPanel: false,
        isShowCountDownTip: true
      })
      this.countDown();
    },

    // 倒计时
    countDown() {
      let that = this;
      that.minutes = that.initTimeVal
      that.seconds = 0;
      that.$emit("handleCount", that.minutes, that.seconds);

      that.seconds = 60;
      if (that.timer) {
        clearInterval(that.timer);
        that.seconds = 60;
      }
      if (parseInt(that.minutes) === 1) {
        that.minutes = 0;
      } else {
        that.minutes -= 1;
      }

      that.timer = setInterval(() => {
        if (that.seconds === 0 && that.minutes !== 0) {
          that.seconds = 59;
          that.minutes -= 1;
        } else if (that.minutes === 0 && that.seconds === 0) {
          that.seconds = 0;
          clearInterval(that.timer);
          that.countUp();
        } else {
          that.seconds -= 1;
        }
        that.$emit("handleCount", that.minutes, that.seconds, "-");
      }, 1000);
    },

    // 正计时
    countUp() {
      let that = this;
      that.minutes = 0;
      that.seconds = 0;
      that.timer = setInterval(() => {
        if (that.seconds === 59 && that.minutes != 1000) {
          that.seconds = 0;
          that.minutes += 1;
        } else if (that.minutes === 1000) {
          that.seconds = 0;
          clearInterval(that.timer);
        } else {
          that.seconds += 1;
        }

        that.$emit("handleCount", that.minutes, that.seconds, "+");
      }, 1000);
    },

    // 关闭时间定时器
    close() {
      let that = this;
      clearInterval(that.timer);
      that.minutes = 0;
      that.seconds = 0;
    },

    // 重置时间
    reset() {
      let that = this;
      that.close();
      that.countDown();
    },
  },
};
</script>

<style lang="scss" scoped>
  @mixin public_box{
    display: flex;
   justify-content: space-between;
    height: 48px;
    /* border-radius: 0px 100px 100px 0px; */
  }
  @mixin public_font{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
.countdownBtn {
  @include public_box;
  float: left;
  .btnLeft {
      @include public_box;
      align-items: center;
      padding-left:10px;
      transition-property: background-color;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
      &:hover{
        background: rgba(0, 0, 0, 0.05) !important;
      }
      &:focus{
        background: rgba(0, 0, 0, 0.1) !important;
      }
      .countdownInfo {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding:0 14px 0 8px;
      }
  }
}
  .countdownPanel {
  
      .countdown-container {
        background: #ffffff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        position: relative;

        .countdown-header {
          text-align: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          box-sizing: border-box;

          .countdown-close {
            position: absolute;
            top: -32px;
            right: 16px;
            cursor: pointer;
          }
        }

        .countdown-content {
          padding-top: 30px;
          .countdown-item {
            .title {
              @include public_font;
              margin-right: 10px;
            }

            .countdown-form {
              display: inline-block;
              vertical-align: middle;
              margin-left: 10px;
            }

            .countdown-position {
              display: inline-block;
              vertical-align: text-bottom;
              margin-left: 10px;
            }

            .countdown-unit {
              @include public_font;
              margin-left: 10px;
            }

            &:first-child {
              margin-left: 24px;
            }

            &:last-child {
              margin-left: 48px;
              margin-top: 30px;
            }
          }
              .place_style{
                font-size: 12px;
                color: #999999;
                text-align: center;
                width:94%;
                margin-top: 8px;
              }
        }
        .countdown-footer {
          text-align: right;
          padding: 10px 24px 20px;
          box-sizing: border-box;
          width: 100%;
          .onSure{
            margin-left:10px;
          }
        }
      }
    }
    .errorTip {
      margin-right: 10px;
    }
</style>
<style scoped lang="css">
/deep/ .countdown-dialog{
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}
/deep/ .countdown-dialog > .el-dialog__body{
  padding: 0;
}
</style>
