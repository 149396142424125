<template>
  <div class="lecturerRow">
    <topShareControl v-show="isShowMenuBar"/>

    <!-- 顶部列表视频区域 -->
    <layoutList
      :isFullModel="isFullModel"
      type="row"
    ></layoutList>

    <!-- 中间大画面区域 -->
    <layoutCoreOne
      ref="layoutCoreOne"
      :isFullModel="isFullModel"
      :isShowMenuBar="isShowMenuBar"
      type="row"
    ></layoutCoreOne>
  </div>
</template>

<script>
import LayoutCoreOne from "./Meeting.Layout.CoreOne.vue"
import LayoutList from "./Meeting.Layout.List.vue"
import topShareControl from "./topShareControl.vue"

export default {
  props:['isShowMenuBar'],
  components: {
    layoutCoreOne: LayoutCoreOne,
    layoutList: LayoutList,
    topShareControl
  },
  data () {
    return {

    }
  },
  
  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    }
  },

  created() {
    this.$store.commit("member/updatePageIndex", 1)
    this.$store.commit("member/updatePageSize", 5)
  },

  
};
</script>

<style lang="scss" scoped>
.lecturerRow {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>