export const galleryConfig = {
	1: { xLen: 1, yLen: 1},
	2: { xLen: 2, yLen: 1},
	3: { xLen: 2, yLen: 2},
	4: { xLen: 2, yLen: 2},
	5: { xLen: 3, yLen: 2},
	6: { xLen: 3, yLen: 2},
	7: { xLen: 3, yLen: 3},
	8: { xLen: 3, yLen: 3},
	9: { xLen: 3, yLen: 3}
} 

export const galleryMCUConfig = {
	...galleryConfig,
	10: { xLen: 4, yLen: 3},
	11: { xLen: 4, yLen: 3},
	12: { xLen: 4, yLen: 3},

	13: { xLen: 4, yLen: 4},
	14: { xLen: 4, yLen: 4},
	15: { xLen: 4, yLen: 4},
	16: { xLen: 4, yLen: 4},
	17: { xLen: 5, yLen: 4},
	18: { xLen: 5, yLen: 4},
	19: { xLen: 5, yLen: 4},
	20: { xLen: 5, yLen: 4},
	21: { xLen: 5, yLen: 5},
	22: { xLen: 5, yLen: 5},
	23: { xLen: 5, yLen: 5},
	24: { xLen: 5, yLen: 5},
	25: { xLen: 5, yLen: 5}
}
