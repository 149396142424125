<template>


  <div class="list drag-list" :class="{
    isFullModel: isFullModel,
    col: type === 'col',
    row: type === 'row',
    hideRow: isHideMemberRow && type === 'row',
    hideCol: isHideMemberCol && type === 'col'
  }">

    <!-- 非全屏模式下的列表的折叠按钮 -->
    <div v-if="!isFullModel"
      class="extend-handle"
      @click="updateHide"
    >
        <i :class="{
          'left-sm-arrow': type === 'col' && isHideMemberCol,
          'right-sm-arrow': type === 'col' && !isHideMemberCol,
          'bottom-sm-arrow': type === 'row' && isHideMemberRow,
          'top-sm-arrow': type === 'row' && !isHideMemberRow,
         }"></i>
    </div>

  <!-- 全屏模式下 :preventActiveBehavior="!isRunDrag"-->
  <!-- :isResizable="isRunDrag"
  :isActive="isRunDrag" -->
  <VueDragResize
    v-if="isFullModel"
    :isActive="true"
    :w="vw" :h="vh" :minw="240" :minh="minh" :y="vy"
    :x="vx" :sticks="isRunDrag?['br', 'bl']:[]"
    v-on:resizing="resize" v-on:dragging="resize">
    <div class="top-control" :class="this.controlType !== 1 ? 'top-control-hide' : ''">
      <div :title="$t('meeting.shouVideo')" @click="controlClickHandle(1)" class="item item1">
        <!-- <my-icon
          :fontSize="16"
          :iconName="'iconbuju_yincangshipin_16_lv'"
          v-if="controlType==1"
        ></my-icon>
        <my-icon
          :fontSize="16"
          :iconName="'iconbuju_yincangshipin_16_hei'"
          v-else
        ></my-icon> -->
        <img class="iconquanpin" src="@/assets/images/yincangshipin_16_lv.png" v-if="controlType==1" alt="">
        <img class="iconquanpin" src="@/assets/images/yincangshipin_16_hei.png" v-else alt="">
      </div>
      <div :title="getTab2Title()" @click="controlClickHandle(2)" class="item item2">
        <!-- <my-icon
            :fontSize="16"
            :iconName="'iconbuju_dangeshipin_16_lv'"
            v-if="controlType==2"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconbuju_dangeshipin_16_hei'"
            v-else
          ></my-icon> -->
        <img class="iconquanpin" src="@/assets/images/dangeshipin_16_lv.png" v-if="controlType==2" alt="">
        <img class="iconquanpin" src="@/assets/images/dangeshipin_16_hei.png" v-else alt="">

      </div>
      <div :title="$t('meeting.lookAllVideo')" @click="controlClickHandle(3)" class="item item3">
          <!-- <my-icon
            :fontSize="16"
            :iconName="'iconbuju_duogeshipin_16_lv'"
            v-if="controlType==3"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconbuju_duogeshipin_16_hei'"
            v-else
          ></my-icon> -->
        <img class="iconquanpin" src="@/assets/images/duogeshipin_16_lv.png" v-if="controlType==3" alt="">
        <img class="iconquanpin" src="@/assets/images/duogeshipin_16_hei.png" v-else alt="">
      </div>
      <div title="查看焦点画面" v-if="isFocusScreen && isSelfShare"  @click="controlClickHandle(4)" class="item item4">
          <!-- <my-icon
            :fontSize="16"
            :iconName="'iconjiaodian_24_lv'"
            v-if="controlType==4"
          ></my-icon>
          <my-icon
            :fontSize="16"
            :iconName="'iconjiaodian_24_hui'"
            v-else
          ></my-icon> -->

        <img class="iconquanpin" src="@/assets/images/iconjiaodian_24_lv.png" v-if="controlType==4" alt="">
        <img class="iconquanpin" src="@/assets/images/iconjiaodian_24_hui.png" v-else alt="">
      </div>
    </div>

    <!-- 非全屏模式下的列表，全屏模式下的第三种按钮显示的样式 -->
    <div class="list-box" v-show="isShowListBox()">
      <div class="list-box-center">
        <!-- 上一页 -->
        <div
          v-if="isPrev"
          class="page-icon-box prev-box"
          @click="prevHandle"
        >
          <my-icon class="gongge9PrevIcon" :iconName="getPrevIconName()"></my-icon>
        </div>

        <videoItem
          :key="getItemKey(user)"
          v-for="(user) in pageList"
          :user="user"
          @dobClick="dobuleListItemClick"
        />

        <!-- 下一页 -->
        <div
          v-if="isNext"
          class="page-icon-box next-box"
          @click="nextHandle"
        >
          <my-icon class="gongge9NextIcon" :iconName="getNextIconName()"></my-icon>
        </div>
      </div>
    </div>

    <!-- 中间一路画面 -->
    <tab2 v-if="isShowOneView()" />


    <!-- 焦点画面 -->
    <tab3 v-if="isShowFocusScreen()" />

  </VueDragResize>

    <!-- 非全屏模式下的列表，全屏模式下的第三种按钮显示的样式 -->
    <div  v-if="!isFullModel" class="list-box" v-show="isShowListBox()">
      <div class="list-box-center">
        <!-- 上一页 -->
        <div
          v-if="isPrev"
          class="page-icon-box prev-box"
          @click="prevHandle"
        >
          <my-icon class="gongge9PrevIcon" :iconName="getPrevIconName()"></my-icon>
        </div>

        <videoItem
          :key="getItemKey(user)"
          v-for="(user) in pageList"
          :user="user"
          @dobClick="dobuleListItemClick"
        />

        <!-- 下一页 -->
        <div
          v-if="isNext"
          class="page-icon-box next-box"
          @click="nextHandle"
        >
          <my-icon class="gongge9NextIcon" :iconName="getNextIconName()"></my-icon>
        </div>
      </div>
    </div>

    <!-- 中间一路画面 -->
    <tab2 v-if="!isFullModel && isShowOneView()" />

  </div>

</template>

<script>
import { ROLE_CODE } from '@/constant/index'
import layoutMixins from "./layout.mixins"
import dobuleClickMixins from "@/mixins/dobuleClick.mixins"

import VideoItem from './video.item'
import Tab2 from './Meeting.Layout.tab2'
import Tab3 from './Meeting.Layout.tab3'
import VueDragResize from 'vue-drag-resize' //缩放、拖拽

import { getRightMemberBarHide,
         setRightMemberBarHide,
         getTopMemberBarHide,
         setTopMemberBarHide  } from '@/utils/auth'

export default {
  mixins: [layoutMixins, dobuleClickMixins],

  components: {
    videoItem: VideoItem,
    tab2: Tab2,
    tab3: Tab3,
    VueDragResize
  },

  props: {
    isFullModel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    userList() {
      return this.$store.getters["member/getRealUserList"]
    },
    isPrev() {
      return this.$store.getters['member/isPrev']
    },
    isNext() {
      return this.$store.getters['member/isNext'](this.isShareDoc)
    },
    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    pageList() {
      return this.$store.getters['member/pageList'](this.isShareDoc,this.isFocusScreen)
    },

    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },

    isExitUserLock() { // 存在锁定用户
      return this.userList.find(i => i.isDbClick)
    },

    isFocusScreen() { // 存在焦点画面
      return this.$store.state.meet.isFocusScreen
    },

    isSelfShare() { // 是否为本人共享 bugID: 28493
      let ownerPeerId = this.$configs.peerId;
      let memberList = this.$store.getters["member/getRealUserList"];

      let currentShareUser = memberList.find(user => user.isShare);

      if(currentShareUser){
        return currentShareUser?.userId == ownerPeerId || this.isShareDoc
      }else{
        return false;
      }
    },

    isHideMemberRow() { //大画面时，是否隐藏顶部成员列表
      return this.$store.state.meet.isHideMemberRow;
    },

    isHideMemberCol() { //大画面时，是否隐藏右侧成员列表
      return this.$store.state.meet.isHideMemberCol;
    }
  },

  watch: {
    isFocusScreen: {
      handler(newVal) {
        if (!newVal && this.controlType == 4) {
          this.controlType = 2
        }
      },
      immediate: true
    },

    isFullModel: {
      handler(newVal,oldVal) {
        if (newVal) {
          this.isRunDrag = true
          console.error('全屏触发---isFullModel---true---',this.isRunDrag)
          if(this.isFocusScreen && this.isExitUserShare && (this.isExitUserShare.userId == this.$configs.peerId || this.isShareDoc)){
            this.controlType = 4
          }else{
            this.controlType = 2
          }
        }
        // 全屏切换重新触发订阅逻辑
        this.$nextTick(() => {
          oldVal != undefined && this.reSetUserItem(newVal)
        })
      },
      immediate: true
    },

    isExitUserShare: {
      handler(newVal) {
        if(!newVal && this.controlType == 4){
          this.controlType = 2
        }
      },
      immediate: true
    },
    type: {
      handler(newVal) {
        //从记忆里初始折叠状态
        //右侧栏状态
        const hideCol = getRightMemberBarHide() || ''
        //顶部栏状态
        const hideRow = getTopMemberBarHide() || ''
        if (newVal === 'col') {
          this.$store.commit("meet/updateGlobalMeetState", {
            isHideMemberCol: hideCol === 'true' ? true : false
          })
        }
        if (newVal === 'row') {
          this.$store.commit("meet/updateGlobalMeetState", {
            isHideMemberRow: hideRow === 'true' ? true : false
          })
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      ROLE_CODE,
      controlType: 2, // 1 最小化 2 单个画面 3 list模式
      vw: 240,
      vh: 134,
      vy: 40,
      minh:134,
      vx:-100,
      isRunDrag:true
    };
  },

  methods: {
    resetData(height=134) {// 初始化渲染。
      this.vh = height
      this.minh = height
      // this.vy = 40
      setTimeout(() => {
        this.vw = 240
        this.vh = height
        this.minh = height
        // this.vy = 40
      }, 0);
      setTimeout(() => {
        this.vw = 240
        this.vh = height
        this.minh = height
        // this.vy = 40
      }, 100);
    },
    //缩小
    resize(newRect) {
      this.vw = newRect.width;
      this.vh = newRect.height;
      this.vy = newRect.top;
      this.vx = newRect.left;
    },

    getItemKey(user) {
      if (user.isTopShare) {
        return `top-share-${user.userId}`
      }

      return user.userId
    },

    dobuleListItemClick(user) {
      this.dobuleClick('list', user)
    },

    getTab2Title() {
      if (!this.isExitUserShare) { // 不是共享状态
        return this.$t('meeting.selfVideo')
      } else { // 是共享状态
        if (!this.isExitUserLock) { // 不存在锁定用户
          return this.$t('meeting.lookSpeakVideo')
        } else { // 存在锁定
          return this.$t('meeting.lookShare')
        }
      }
    },

    async reSetUserItem(isFullModel){
      console.error('全屏重新订阅isFullModel:',isFullModel)
      await this.$meetingControl.muteRemoteVideoAll()
      this.pageList.forEach(async (newUser) => {
            let isRun = true
            // isRun逻辑
            if (isRun) { // 执行相关代码
              // console.log('执行list isRun -----------' + newUser.userId);
              const { isTopShare, userId, isUseShiPin } = newUser
              if (isTopShare) { // 顶部的分享画面
                console.error('要开启顶部的分享画面-----');
                const shareVideEl = document.getElementById(`top-share-${userId}`)
                console.log(shareVideEl)
                if (userId !== this.$configs.peerId) { // 开启其他用户的共享画面
                  this.$meetingControl.startRemoteShare(userId, shareVideEl)
                }
              } else { // 正常处理
                if (isUseShiPin) { // 要开启视频
                  const videoEl = document.getElementById(`video-${userId}`)

                  if (userId === this.$configs.peerId) {
                    this.$meetingControl.startLocalPreview(videoEl)
                  } else {
                    // 这里变更成同步调用
                    console.error('list将要开启远程视频-------')
                    console.log(userId)
                    console.log(videoEl)
                    // console.error('[视频闪烁问题排查] vidoe开启',`video-${userId}`)

                    this.$meetingControl.startRemoteVideo(userId, videoEl)
                  }
                }
              }
            } else {
              // console.log('不执行list isRun ----------' + newUser.userId)
            }
          })
    },

    getPrevIconName() {
      if (this.isFullModel) { // 全屏模式
        return 'iconjiantou_shangjiantou_24_bai'
      } else {
        return this.type === 'row' ? 'iconjiantou_zuojiantou_24_bai' : 'iconjiantou_shangjiantou_24_bai'
      }
    },
    getNextIconName() {
      if (this.isFullModel) { // 全屏模式
        return 'iconjiantou_xiajiantou_24_bai'
      } else {
        return this.type === 'row' ? 'iconjiantou_youjiantou_24_bai' : 'iconjiantou_xiajiantou_24_bai'
      }
    },

    isShowOneView() { // 是否显示中间一路画面
      if (!this.isFullModel) { // 非全屏模式，一定不显示
        return false
      } else { // 全屏模式下，只有controlType为2
        return this.controlType === 2
      }
    },


    isShowFocusScreen() { // 是否显示中间一路画面
      if (!this.isFullModel) { // 非全屏模式，一定不显示
        return false
      } else { // 全屏模式下，只有controlType为2
        return this.controlType === 4 && this.isFocusScreen
      }
    },

    isShowListBox() { // 是否显示listBox

      if (!this.isFullModel && this.type === 'col') { // 如果不是全屏模式，显示状态折叠控制
        return !this.isHideMemberCol
      } else if (!this.isFullModel && this.type === 'row') {
        return !this.isHideMemberRow
      } else { // 全屏模式下，只有controlType为3时显示
        return this.controlType === 3
      }
    },

    controlClickHandle(type) { // 点击三个控制按钮
      this.controlType = type
      this.resetData()
      if(type == 1 || type == 3){
        this.isRunDrag = false
      }else{
        this.isRunDrag = true
      }
    },

    updateHide() {
      if (this.type === 'col') {
        this.$store.commit("meet/updateGlobalMeetState", {
          isHideMemberCol: !this.isHideMemberCol
        })
        setRightMemberBarHide(this.isHideMemberCol)
      }
      if (this.type === 'row') {
        this.$store.commit("meet/updateGlobalMeetState", {
          isHideMemberRow: !this.isHideMemberRow
        })
        setTopMemberBarHide(this.isHideMemberRow)
      }
    }
  },

  destroyed() {

  }
};
</script>

<style lang="scss" scoped>
  @mixin ali_style(){
    display: flex;
    align-items: center;
  }
  @mixin dis_style(){
    @include ali_style;
    justify-content: center;
  }
  @mixin base_style($width,$height){
    width:$width;
    height:$height;
  }
.list {
  position: relative;
  background: #111111;

  &.col {
    @include base_style(232px,100%);
    transition: width .5s;
    // flex-shrink: 0;
    .extend-handle {
      width: 16px;
      height: 48px;
      background:#FFFFFF;
      border-radius: 8px 0px 0px 8px;
      position: absolute;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      line-height: 50px;
      font-weight: lighter;
      z-index: 15;
    }

    .list-box {

      padding-bottom: 10px;
      box-sizing: border-box;
      .list-box-center {
        width: 100%;
      }
      .user-item {
        @include base_style(230px,130px);
        border: 2px solid #000000;
        border-right: none;
        border-bottom: none;
        aspect-ratio: 16/9;
        video{
          height:100% !important;
          aspect-ratio: 16/9;
        }
      }
    }

    .user-item {
      @include base_style(230px,130px);
      border: 2px solid #000000;
      border-right: none;
      border-bottom: none;
      aspect-ratio: 16/9;
      video{
        height:100% !important;
        aspect-ratio: 16/9;
      }
      &:last-child {
        border-bottom: 2px solid #000000;
      }


    }

    .page-icon-box {
      height: 24px;
      line-height: 12px;

      &.prev-box {
        margin-bottom: 2px;
      }

      &.next-box {
        margin-top: 2px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }
  }

  &.row {
    @include base_style(100%,118px);
    transition: height 0.5s;

    .extend-handle {
      width: 48px;
      height: 16px;
      background:#FFFFFF;
      border-radius: 0px 0px 8px 8px;
      position: absolute;
      left: 22%;
      bottom: -16px;
      transform: translateX(-50%);
      cursor: pointer;
      font-weight: lighter;
      z-index: 10;
      @include dis_style;
    }

    .list-box {
      .list-box-center {
        height: 100%;
        display: flex;
      }
    }

    .user-item {
    @include base_style(210px,100%);
      flex-shrink: 0;
      border: 2px solid #000000;
      border-top: none;
      border-right: none;

      &:last-child {
        border-right: 2px solid #000000;
      }
    }

    .page-icon-box {
      &.prev-box {
        margin-right: 19px;
      }

      &.next-box {
        margin-left: 19px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }
  }

  &.isFullModel { // 全局模式下的布局
    position: absolute;
    top: 50px;
    right: 40px;
    @include base_style(240px,auto);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index:10;
    background: transparent;
    .vdr.active:before {
      outline:none;
    }
    .top-control{
      .item4{
        position: absolute;
        right: 10px;
      }
    }
    .top-control-hide{
      opacity: 1;
      -webkit-transition-delay: 1s;
      -moz-transition-delay: 1s;
      transition-delay: 1s;
    }
    &:hover{
      .top-control-hide{
        opacity: 1;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
    .page-icon-box {
      &.prev-box,&.next-box {
        // margin: 0;
        margin-right: 0px;
        margin-left: 0 !important;
        // margin-bottom: 19px;
      }
      &:focus,&:hover{
        background: rgba(51, 51, 51, 0.45);
      }
    }

    &.col, &.row {
      .list-box-center {
        width: 100%;
      }
      .user-item {
        background: #222;
        min-height:134px;
        border: none;
        width: 100%;

        border-bottom: 2px solid #000000;
        &.speaking{
          box-sizing: border-box !important;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &.row {
      .list-box-center {
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  .list-box {
    @include dis_style;
    flex-wrap: wrap;
    height: 100%;
  }

  .page-icon-box {
    cursor: pointer;
    text-align: center;
    width: 100%;
    @include dis_style;
  }

  .top-control {
    height: 24px;
    padding: 0 8px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: -24px;
    @include ali_style;
    background-color: #fff;
    .item {
      @include base_style(16px,16px);
      // background-color: #1ab370;
      cursor: pointer;
      margin-right: 10px;
      img{
        display: block;
        width: 100%;
      }
    }
  }

  &.col.hideCol {
    width: 0;
    transition: width 0.5s;
  }
  &.row.hideRow {
    height: 0;
    transition: height 0.5s;
  }

  //箭头
  .left-sm-arrow {
      width: 7px;
      height: 7px;
      border-top: 1px solid rgb(0,0,0,.3);
      border-right: 1px solid rgb(0,0,0,.3);
      transform: rotate(-135deg);
      margin-left: 5px;
      display: inline-block;
   }

   .right-sm-arrow {
      width: 7px;
      height: 7px;
      border-top: 1px solid rgb(0,0,0,.3);
      border-right: 1px solid rgb(0,0,0,.3);
      transform: rotate(45deg);
      margin-left: 2px;
      display: inline-block;
   }

   .top-sm-arrow {
      width: 7px;
      height: 7px;
      border-top: 1px solid rgb(0,0,0,.3);
      border-right: 1px solid rgb(0,0,0,.3);
      transform: rotate(-45deg);
      display: inline-block;
      margin-top: 3.5px;
   }

   .bottom-sm-arrow {
      width: 7px;
      height: 7px;
      border-top: 1px solid rgb(0,0,0,.3);
      border-right: 1px solid rgb(0,0,0,.3);
      transform: rotate(135deg);
      margin-top: -3.5px;
      display: inline-block;
   }
}
</style>