<template>
  <layout-grid :grid-type="9"></layout-grid>
</template>

<script>
import LayoutGrid from './Meeting.Layout.Grid'
export default {
  components: {
    LayoutGrid
  }
}
</script>
