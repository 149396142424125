<template>
  <div class="shareBtnBox" :class="isExitUserShare && $configs.peerId === isExitUserShare.userId || isShareDoc?'orangeBtn':''">
    <div :class="['btnLeft',isOrdinaryUser ? 'noshare' : '']" @click="debounceHandle(clickLeft)" id="meeting_share_btn_headless">
      <my-icon :iconName="'icongongxiang_24_bai'"></my-icon>
      <div class="shareInfo">{{isExitUserShare && $configs.peerId === isExitUserShare.userId || isShareDoc ? $t('meeting.endShare'): $t('meeting.share')}}</div>
    </div>
    <div v-if="isShowShareSet()" class='btnRight' @click="clickRight">
      <my-icon :target="'shareArrowIcon'" :fontSize="16" :iconName="'iconjiantou_xiajiantou_bai'"></my-icon>
    </div>

    <div class="sharePanel" :class="$i18n.locale === 'en-US' ? 'shareWidth' : ''" v-show="isShowPanel"
      v-extend-panel-manager="onClose">
      <div>
        <div class="sharePanel-title">共享方式</div>

        <div class="no-share-row">
          <div class="item" @click="uploadFileHandle(1)" v-if="![10008].includes($configs.businessType*1)">
              <img src="@/assets/images/share/main-preview-doc.svg" alt="在线文档">
              <my-icon v-if="shareTypeIconChecked == 1 && isShareDoc" class="okIcon" :fontSize="16" :iconName="'iconduigou_16_lv'"></my-icon>
              <span :class="shareTypeIconChecked == 1 && isShareDoc ?'checked':''">在线文档</span>
              <div class="popover">
                <span class="jt"></span>
                云端上传课件，打开文档即所见即所得
              </div>
          </div>
          <div class="item" @click="uploadFileHandle(2)" v-if="![10008].includes($configs.businessType*1)">
              <img src="@/assets/images/share/main-preview-burn-doc.svg" alt="用后即焚">
              <my-icon v-if="shareTypeIconChecked == 2 && isShareDoc" class="okIcon" :fontSize="16" :iconName="'iconduigou_16_lv'"></my-icon>
              <span :class="shareTypeIconChecked == 2  && isShareDoc ?'checked':''">用后即焚</span>
              <div class="popover">
                <span class="jt"></span>
                会后文档自动删除，更好的保护隐私信息
              </div>
          </div>
          <div class="item" @click="uploadFileHandle(3)">
              <img src="@/assets/images/share/main-preview-desktop-share.svg" alt="共享屏幕"/>
              <my-icon v-if="shareTypeIconChecked == 3 && isExitUserShare && $configs.peerId === isExitUserShare.userId" class="okIcon" :fontSize="16" :iconName="'iconduigou_16_lv'"></my-icon>
              <span :class="shareTypeIconChecked == 3 && isExitUserShare && $configs.peerId === isExitUserShare.userId?'checked':''">共享屏幕</span>
              <div class="popover">
                <span class="jt"></span>
                选择设备屏幕、软件、窗口进行分享
              </div>
          </div>
        </div>
      </div>

      <div v-if="!isOrdinaryUser">
        <div class="sharePanel-title">{{$t('meeting.sharingRights')}}</div>

        <div class="radio-box">
          <el-radio-group @change="shareSetChange" v-model="shareVal">
            <el-radio :label="0" class="radio-item">{{$t('meeting.allPeoples')}}</el-radio>
            <el-radio :label="1" class="radio-item">{{$t('meeting.onlyMain')}}</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div v-if="isShowStopShare()" class="stop-btn" @click="stopCurrentShareClick">{{$t('meeting.stopThisShare')}}
      </div>
    </div>

    <!-- 停止共享 -->
    <el-dialog :title="$t('meeting.endShare')" 
      class="commonElDialog" 
      :modal="false" 
      width="380px" 
      :append-to-body="true"
      style="z-index: 3000"
      :visible.sync="stopShareDialog">
      <div v-if="!stopAndStartShare">{{$t('meeting.isStopShare')}}</div>
      <div v-else>{{$t('meeting.stopSharing')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stopShareBtnCance">{{$t('meeting.noStop')}}</el-button>
        <el-button type="primary" @click="stopShareHandle">{{$t('meeting.isStop')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { fetchMeetControl,queryDocumentShareData} from '@/api/meet'
  import { ROLE_CODE, CONTROL_COMMAND } from "@/constant/index"
  import { loganLog } from "@/utils/log"
  import debounce from 'lodash.debounce'
  import { getDocFireDialog,} from "@/utils/auth";
  
  export default {
    data() {
      return {
        isShowPanel: false, // 是否显示面板
        shareVal: "",
        stopShareDialog: false,
        stopShareBtnCanceType:'click',//点击取消停止其他人的共享的按钮类型 click、checked
        stopAndStartShare: false,
        shareTypeIconChecked:0,//点击共享类型
      };
    },
    computed: {
      userList() {
        return this.$store.getters["member/getRealUserList"]
      },

      selfInfo() {
        return this.$store.getters["member/getUser"](this.$configs.peerId)
      },

      isHost() {
        return this.selfInfo.roleCode === ROLE_CODE.HOST;
      },

      isCoHost() {
        return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
      },

      isOrdinaryUser() {
        return this.selfInfo.roleCode === ROLE_CODE.USER;

      },

      isShareDoc() {
        return this.$store.state.meet.isShareDoc
      },

      sharePermission() {
        return this.$store.state.meet.sharePermission;
      },

      isExitUserShare() { // 存在用户共享
        return this.userList.find(i => i.isShare)
      },
      shareType() {
        return this.$store.state.meet.shareType
      },
    },
    methods: {
      debounceHandle: debounce(fn => fn && fn(), 1000, { leading: true, trailing: false }),

      uploadFileHandle : debounce(async function (type) {
          if(this.$store.state.meet.isNetworkDisconnect){
            this.$parent.showToast(this.$t('meeting.netWorkBad'))
            return false
          }

          this.isShowPanel = false
          if(type == 1){// 上传文档
            this.$parent.isShowDocumentPanel = true
          }else if(type == 2){ //阅后即焚
            if(!this.$parent.isOneySharePermis()) return
            let skipDialog = getDocFireDialog()
            // console.error(22222,skipDialog)
            this.$store.commit("meet/updateGlobalMeetState", {
              shareType: 2
            });
            if(skipDialog && skipDialog == 'true'){ //是否
              try {
                this.$nextTick(() => {
                  let _element = document.getElementById('uploadBtnElement')
                    _element && _element.click()
                })
              } catch (error) {
                loganLog(`发起阅后即焚错误--error:${JSON.stringify(error)}`)
              }
            } else {
              this.$parent.docFireDialog = true
            }
          } else if(type == 3){
            if(!this.$parent.isOneySharePermis()) return
            try {
              await this.$meetingControl.openShare()
            } catch (error) {
              this.openShareErrHandle(error)
            }
          }
        this.shareTypeIconChecked = type
      }, 500, {
          leading: true,
          trailing: false
      }),
    
      
      openShareErrHandle(error) {
        loganLog(`openShare错误 error:${JSON.stringify(error)}`)

        const meetDialogInfo = {
          commonText: '开启屏幕共享失败'
        }

        if (error.errorCode === 70003) { // 点击了分享取消按钮，不做处理
          return
        }

        if (error.errorCode === 70005) { // mac端未开启录制权限
          meetDialogInfo.tips = this.$t('meeting.allShare')
          meetDialogInfo.commonText = this.$t('meeting.openShareDeniedHelp')
        }

        this.$store.commit("meet/updateGlobalMeetState", {
          meetDialogInfo
        })
      },

      stopCurrentShareClick() { // 停止共享
        this.stopShareDialog = true
        this.stopShareBtnCanceType = 'click' //取消按钮类型为点击
      },
      stopShareBtnCance(){
        this.stopShareDialog = false
        //如果是下拉框选择的仅主持人共享又取消了需要把状态重置
        if(this.stopShareBtnCanceType === 'checked'){
          this.shareVal = 0;
        }
      },
      async stopShareHandle() {
        this.stopShareDialog = false
        if (this.stopAndStartShare) {
          this.stopAndStartShare = false;
        }
        if(this.isExitUserShare.userId === this.$configs.peerId){
          // 结束自己的共享不走会控
          this.$meetingControl.stopShare()
        }else{
          try {
            let _userid = this.isExitUserShare.userId
            if(this.isExitUserShare.userId.indexOf('cloudshare') > -1) {
              const fileData = await queryDocumentShareData({
                  roomid: this.$route.query.roomID
              })
              _userid = fileData.roomId + '_' + fileData.userId
            }
              
            await fetchMeetControl({
              command: CONTROL_COMMAND.STOP_SHARE,
              toPeerId: _userid
            }, this.$configs)

            if (this.stopAndStartShare) {
              this.stopAndStartShare = false;
            }
          } catch (error) {
            console.log(error)
          }
        }
      },

      dialogClosedHandle() {
        const el = document.querySelector('.el-dialog-member')
        // this.$refs.documentMember.dialogCloseClick()
        if (el) {
          el.style.top = 'initial'
          el.style.left = 'initial'
        }
      },
      isShowStopShare() { // 是否出现停止共享按钮：存在有人共享并且当前用户是主持人或者联席主持人
        // return this.isExitUserShare && !this.isOrdinaryUser
        if (this.isExitUserShare) { // 存在共享
          if (this.isHost) { // 当前用户为主持人，一定显示
            return true
          } else if (this.isCoHost) { // 当前用户为联席主持人，分情况，如果分享的人为主持人，则不显示。
            return this.isExitUserShare.roleCode !== ROLE_CODE.HOST
          } else { // 普通用户，看是否共享人为自己
            // return false
            return this.isExitUserShare.userId === this.$configs.peerId
          }
        } else {
          return false
        }
      },

      isShowShareSet() { // 是否显示分享按钮右侧设置
        // return !this.isOrdinaryUser

        if (!this.isOrdinaryUser) { // 主持人和联席
          return true
        } else { // 普通用户
          return this.isExitUserShare && this.isExitUserShare.userId === this.$configs.peerId
        }
      },
      
      async shareSetChange(e) {
        //主持人选择仅主持可共享，弹窗是否结束当前非主持人的共享
        if(!this.isShareDoc && this.isExitUserShare && e === 1 && this.isExitUserShare.roleCode !== ROLE_CODE.CO_HOST && this.isExitUserShare && this.isExitUserShare.roleCode !== ROLE_CODE.HOST){
          this.stopShareDialog = true
          this.stopShareBtnCanceType = 'checked' //取消按钮类型为下拉框选择
        }
        await fetchMeetControl({
          command: e ?
            CONTROL_COMMAND.SHARE_PERMISSIONS_HOST : CONTROL_COMMAND.SHARE_PERMISSIONS_ALL,
        }, this.$configs)
      },
       // 点击按钮
      clickLeft() {
        if(this.isExitUserShare && this.$configs.peerId === this.isExitUserShare.userId){
          //判断当前共享人是不是自己，是的话提示共享结束
          this.stopCurrentShareClick()
        } else if(this.isShareDoc){
          // 结束文档共享
          this.$documentManager.docShareStopFn(this.$configs.roomId,this.$configs.userId)
        } else{
          // 点击共享
          this.isShowPanel=true;
          this.$parent.isShowDocumentPanel=false
        }
      },
      // 点击按钮
      clickRight() {
        this.isShowPanel = !this.isShowPanel;
      },
      // 鼠标点击关闭面板
      onClose(mouseup, mousedown) {
        if (
          mousedown.target.className === "btnRight over" ||
          mousedown.target.className.baseVal === "icon16 shareArrowIcon" ||
          mousedown.target.className === "shareArrowIcon"
        ) {
          return;
        }

        this.isShowPanel = false;
      }
    },
    watch: {
      sharePermission(newVal) {
        this.shareVal = newVal;
      },
      selfInfo:{
        handler(newVal) {
          //自己的共享关闭 如果没有主持人权限关闭isShowPanel  不然会有一个小白快
          if(newVal.isShare === false && this.isOrdinaryUser){
            this.isShowPanel = false
          }
        },
        deep: true,
        immediate: true
      }
    },
    async created() {
      this.shareVal = this.sharePermission;
    }
  };
</script>

<style lang="scss" scoped>
  @mixin public_hover{
    display: flex;
    align-items: center;
    height: 48px;
    background: #1ab370;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }

  @mixin public_hover_orange{
    display: flex;
    align-items: center;
    height: 48px;
    background: #ff8200;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
  .shareBtnBox {
    display: flex;
    justify-content: space-between;
    height: 48px;
    background: #1ab370;
    border-radius: 24px;
    position: relative;

    .stop-btn {
      font-size: 13px;
      // padding-top: 9px;
      // border-top: 1px solid rgba(2, 2, 2, 0.05);
      cursor: pointer;
      border-top: 1px solid rgba(2, 2, 2, 0.05);
      padding-top: 9px;
    }

    .btnLeft {
      justify-content: space-between;
      padding-left: 10px;
      border-radius:100px 0 0 100px;
      @include public_hover;
      .shareInfo {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        margin: 14px 8px;
      }
    }
    .noshare {
      padding-right: 10px;
      border-radius: 100px;
    }

    .btnRight {
      padding-right: 10px;
      border-radius: 0px 100px 100px 0px;
      @include public_hover;
    }

    .sharePanel {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding: 16px;
      // height: 117px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      position: absolute;
      bottom: 70px;
      left: -35px;

      .sharePanel-title {
        color: #666666;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .no-share-row {
        width: 100%;
        color: #999;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        .item{
          cursor: pointer;
          margin: 0 12px;
          position: relative;
          border-radius: 4px;
          color: #fff;
          width: 48px;
          text-align: center;
          .okIcon{
            position: absolute;
            top: 16px;
            right: 6px;
            border-radius: 2px;
            transform:scale(0.7);
            background: rgba(0, 0, 0, 0.5);
          }
          img{
            width: 32px;
            height: 32px;
          }
          span{
            display: block;
            font-size: 12px;
            padding-top: 7px;
            color: #333;
          }
          .popover{
            position: absolute;
            box-sizing: border-box;
            z-index: 30;
            left: 50%;
            margin-left: -104px;
            top: 44px;
            width: 208px;
            text-align: center;
            padding: 4px;
            border-radius: 4px;
            background: rgba(255,255,255,0.9);
            border: 1px solid #1AB370;
            color: #1AB370;
            display: none;
            .jt{
              width: 6px;
              height: 0px;
              display: block;
              position: absolute;
              border-top: 1px solid #1AB370;
              border-left: 1px solid #1AB370;
              top: -4.5px;
              left: 50%;
              background: #fff;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
  
            }
          }
          .checked{
            color: #1ab370;
          }
          &:hover{
            .popover{
              display: block;
            }
          }
        }
      }
      .radio-box {
        display: flex;
        flex-direction: column;

        .radio-item {
          display: flex;
          align-items: center;
          height: 36px;
        }
      }
    }
  }
  .orangeBtn{
    background: #ff8200;
    .btnLeft{
      @include public_hover_orange;
    }
    .btnRight {
      @include public_hover_orange;
    }
  }
</style>