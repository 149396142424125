<!--
* 医百会议-主界面-顶部左边-信息图标功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div>
    <!-- <my-icon class="iconhuiyixinxi" :iconName="'iconhuiyixinxi_24'" @click.stop="togglePanel"  id="meeting_info_btn_headless"></my-icon> -->
    <img class="iconhuiyixinxi" src="@/assets/images/meetInfo.png" @click.stop="togglePanel" alt="">

    <div class="iconhuiyixinxiPanel" v-show="panel">
      <div class="xinxiTitle">{{meetName}}</div>
      <table>
        <!-- 会议号 -->
        <tr v-if="![10007,10008].includes($configs.businessType*1)">
          <td>
            <div class="xinxiMeetingIDName">{{ $t('meeting.meetID') }}:</div>
          </td>
          <td>
            <div class="xinxiMeetingIDValue">{{$tools.formatCode(conferenceNo)}}</div>
            <div class="xinxiMeetingIDCopy" v-clipboard:copy="conferenceNo" v-clipboard:success="onCopy"
              v-clipboard:error="onError">{{ $t('meeting.copy') }}</div>
          </td>
        </tr>

        <!-- 主持人 -->
        <tr v-if="![10007,10008].includes($configs.businessType*1)">
          <td>
            <div class="xinxiZhuChiRenName">{{ $t('meeting.host') }}:</div>
          </td>
          <td>
            <div :title="$store.state.meet.ownerName" class="xinxiZhuChiRenValue">
              {{ strMiddleEllipsis($store.state.meet.ownerName, 15, 5, 3) }}</div>
          </td>
        </tr>
        <!-- 会议密码 -->
        <tr v-if='$store.state.meet.passwordEnable && $store.state.meet.password'>
          <td>
            <div class="xinxiZhuChiRenName">{{ $t('login.meetPsd') }}</div>
          </td>
          <td>
            <div :title="$store.state.meet.ownerName" class="xinxiZhuChiRenValue">{{ $store.state.meet.password }}</div>
          </td>
        </tr>

        <!-- 链接 -->
        <tr v-if="$configs.businessType != 10007">
          <td>
            <div class="xinxiLinkName">{{ $t('meeting.invite') }}:</div>
          </td>
          <td v-if="$configs.businessType == 10008">
            <div class="xinxiZhuChiRenValue" v-extend-max-length="[24, '...', links]"></div>
            <div class="xinxiLinkCopy" v-clipboard:copy="mopanLinkMsg" v-clipboard:success="onCopy"
              v-clipboard:error="onError">{{ $t('meeting.copy') }}</div>
          </td>
          <td v-else>
            <div class="xinxiZhuChiRenValue" v-extend-max-length="[24, '...', $store.state.meet.links]"></div>
            <div class="xinxiLinkCopy" v-clipboard:copy="$store.state.meet.links" v-clipboard:success="onCopy"
              v-clipboard:error="onError">{{ $t('meeting.copy') }}</div>
          </td>
        </tr>
        <!-- 参会者ID -->
        <tr>
          <td>
            <div class="xinxiUserName">{{ $t('meeting.attendee') }}:</div>
          </td>
          <td>
            <div class="xinxiZhuChiRenValue">{{userID}}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    strMiddleEllipsis
  } from '@/utils/str'
  import {
    getDate
  } from "@/utils/tool"
  export default {
    data() {
      const userID = this.$route.params.userID
      const conferenceNo = this.$route.query.conferenceNo

      return {
        userID,
        conferenceNo,
        timer: null,
        panel: false,
        mopanLinkMsg:'',
        links:''
      };
    },
    computed: {
      meetName() {
        return this.$store.state.meet.meetName
      }
    },
    mounted() {
      this.$parent.$el.addEventListener("click", () => {
        this.panel = false
      })
    },
    watch: {      
      mopanLink(newVal) {
        if(newVal){
         this.links = newVal
         this.mopanLinkMsg = newVal
         if(!this.mopanLinkMsg){
          this.mopanLinkMsg = newVal
         }
        }
        // console.error(8888887,newVal)
      },
      mopanInfo(newVal) {
        if(newVal){
          let startTime = new Date(newVal.planStartTime)
          let endTime = new Date(newVal.planEndTime)
          let ymd = getDate(startTime)
          let _endYmd = getDate(endTime)
          let timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
          this.mopanLinkMsg = `${newVal.username}邀请您参加会议\n会议主题：${newVal.topic}\n会议时间：${timeinfo}(GMT+08：00)中国标准时间-北京\n会议链接：建议使用Chrome浏览器打开链接\n${this.mopanLink}`
        }
        // console.error(88888871,newVal)
      }
    },
    props: {
      mopanInfo: {
        default: {}
      },
      mopanLink: {
        default: ''
      },
    },
    methods: {
      strMiddleEllipsis,

      onCopy(e) {
        const {
          text
        } = e

        let copyText = ''
        if (text.indexOf('https') > -1) { // 邀请信息
          copyText = this.$t('seeting.inviteInfo')
        } else {
          copyText = this.$t('meeting.meetID')
        }

        this.$parent.showToast(`${copyText}` + this.$t('seeting.succCopy'))
      },
      // 复制失败时的回调函数
      onError(e) {},
      togglePanel() {
        // 防止弹窗堆叠
        !this.panel && this.$parent.$el.click()
        this.panel = !this.panel
      }
    },
  };
</script>

<style lang="scss" scoped>
  @mixin public_font {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
  }

  .iconhuiyixinxi {
    color: #1ab370;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    padding-top: 2px;
    position: relative;
    top: 1px;
    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }

    &:hover {
      background: rgba(153, 153, 153, 0.3);
    }
  }

  .iconhuiyixinxiPanel {
    //width: 360px;
    //min-height: 192px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 144px;
    top: 58px;
    padding: 24px;
    box-sizing: border-box;

    table {
      width: 100%;

      td:first-child {
        padding-right: 12px;
      }
    }

    .xinxiTitle {
      max-width: 310px;
      min-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .xinxiMeetingIDName {
      @include public_font;
      color: #999999;
      margin-top: 16px;
    }

    .xinxiMeetingIDValue {
      @include public_font;
      color: #333333;
      float: left;
      margin: 16px 0 0 0;
    }

    .xinxiMeetingIDCopy {
      @include public_font;
      width: 28px;
      color: #1AB370;
      float: left;
      margin: 16px 0 0 16px;
      cursor: pointer;
    }

    .xinxiZhuChiRenName {
      @include public_font;
      color: #999999;
      margin-top: 8px;
    }

    .xinxiZhuChiRenValue {
      @include public_font;
      max-width: 210px;
      color: #333333;
      float: left;
      margin: 8px 0 0;
    }

    .xinxiLinkName {
      @include public_font;
      color: #999999;
      margin: 8px 0 0;
    }

    .xinxiLinkCopy {
      @include public_font;
      width: 28px;
      color: #1AB370;
      float: left;
      margin: 8px 0 0 17px;
      cursor: pointer;
    }

    .xinxiUserName {
      @include public_font;
      color: #999999;
      margin: 6px 0 0;
      float: left;
    }
  }
</style>