<!--
* 医百会议-主界面-顶部右边-帮助功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div class='help' v-show="!isFullModel" @click="help">
    <!-- <my-icon
      class="myIcon"
      :fontSize="16"
      :iconName="'icondaohanglan_bangzhu_bai'"
    ></my-icon> -->
    <img class="myIcon" src="@/assets/images/icondaohanglan_bangzhu_bai.png" @click.stop="togglePanel" alt="">
    <div class="desc">{{ $t('meeting.help') }}</div>
  </div>
</template>

<script>

import { helpUrl } from "@/utils/tool";
export default {
  data() {
    return {
      isShow: true, // 是否显示
    };
  },
  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    },
  },
  methods: {
    //跳转帮助链接
    help() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      window.open(helpUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  width: 60px;
  height: 24px;
  background: rgba(51, 51, 51, 0.4);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  // margin: 16px 0 0 10px;
  margin-right: 10px;
  cursor: pointer;
  &:hover{
    background: rgba(102, 102, 102, 0.3);
  } 
  &:focus{
    background: rgba(153, 153, 153, 0.3);
  }
  .myIcon {
    margin: 4px 0 4px 6px;
    float: left;
    width:16px;
    height:16px;
  }
  .desc {
    width: 26px;
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 18px;
    text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
    float: left;
    margin: 3px 6px 3px 2px;
}
}
</style>
