<!--
* 医百会议-主界面-顶部右边-全屏功能
* version: 1.0
* author:  SunAo
* time: 2021-01-26
-->
<template>
  <div class="screenfull" @click="click" :title="getTitle()" id="meeting_screenfull_btn_headless">
    <!-- <my-icon class="myIcon"
      :iconName="isFullModel ? 'iconhuamian_suofangquanping_24_bai' : 'iconhuamian_quanping_24_bai'"></my-icon> -->
    <img class="myIcon" :src="isFullModel ? suofangquanping : quanping ">
  </div>
</template>

<script>
  import screenfull from "screenfull"

  export default {
    computed: {
      isFullModel() {
        return this.$store.state.meet.isFullModel
      }
    },

    data() {
      return {
        suofangquanping:require('@/assets/images/iconhuamian_quanping_24_bai.png'),
        quanping:require('@/assets/images/iconhuamian_suofangquanping_24_bai.png')
      };
    },
    methods: {
      getTitle() {
        return this.isFullModel ? '退出全屏' : '进入全屏'
      },

      // 点击
      click() {
        if(this.$store.state.meet.isNetworkDisconnect){
          // 网络异常，请检查网络设置 
          this.$parent.showToast(this.$t('meeting.netWorkBad'))
          return
        }
        if (!this.isFullModel) {
          screenfull.request();
        } else {
          screenfull.exit();
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .screenfull {
    width: 24px;
    height: 24px;
    background: rgba(51, 51, 51, 0.4);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    // margin: 16px 0 0 8px;
    cursor: pointer;

    &:hover {
      background: rgba(102, 102, 102, 0.3);
    }

    &:focus {
      background: rgba(153, 153, 153, 0.3);
    }

    .myIcon{
      width:24px;
      height:24px;
      /* background:none; */
    }
  }
</style>