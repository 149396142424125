var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var BeautyAppType;
(function (BeautyAppType) {
    BeautyAppType[BeautyAppType["test"] = 0] = "test";
    BeautyAppType[BeautyAppType["test100"] = 1] = "test100";
    BeautyAppType[BeautyAppType["online100"] = 2] = "online100"; //www.test.100meet.cn
})(BeautyAppType || (BeautyAppType = {}));
export var YRTCErrorInfo;
(function (YRTCErrorInfo) {
    //进入房间相关
    YRTCErrorInfo[YRTCErrorInfo["ERR_ROOM_ENTER_FAIL"] = 80000101] = "ERR_ROOM_ENTER_FAIL";
    YRTCErrorInfo[YRTCErrorInfo["ERR_ENTER_ROOM_PARAM_NULL"] = 80000102] = "ERR_ENTER_ROOM_PARAM_NULL";
    YRTCErrorInfo[YRTCErrorInfo["ERR_SDK_APPID_INVALID"] = 80000103] = "ERR_SDK_APPID_INVALID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_ROOM_ID_INVALID"] = 80000104] = "ERR_ROOM_ID_INVALID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_USER_ID_INVALID"] = 80000105] = "ERR_USER_ID_INVALID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_USER_SIG_INVALID"] = 80000106] = "ERR_USER_SIG_INVALID";
    //请求超时相关
    YRTCErrorInfo[YRTCErrorInfo["ERR_REQUEST_TIMEOUT"] = 80000201] = "ERR_REQUEST_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_CREATE_RTC_TRANSPORT_TIMEOUT"] = 80000202] = "ERR_CREATE_RTC_TRANSPORT_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_CONNECT_RTC_TRANSPORT_TIMEOUT"] = 80000203] = "ERR_CONNECT_RTC_TRANSPORT_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_CONSUME_RTC_TIMEOUT"] = 80000204] = "ERR_CONSUME_RTC_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_PRODUCE_RTC_TIMEOUT"] = 80000205] = "ERR_PRODUCE_RTC_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_ENTER_ROOM_TIMEOUT"] = 80000206] = "ERR_ENTER_ROOM_TIMEOUT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_LEAVE_RTC_ROOM_TIMEOUT"] = 80000207] = "ERR_LEAVE_RTC_ROOM_TIMEOUT";
    //设备操作相关
    YRTCErrorInfo[YRTCErrorInfo["ERR_CAMERA_START_FAIL"] = 80000301] = "ERR_CAMERA_START_FAIL";
    YRTCErrorInfo[YRTCErrorInfo["ERR_MIC_START_FAIL"] = 80000302] = "ERR_MIC_START_FAIL";
    YRTCErrorInfo[YRTCErrorInfo["ERR_CAMERA_NO_GRANT"] = 80000303] = "ERR_CAMERA_NO_GRANT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_MIC_NO_GRANT"] = 80000304] = "ERR_MIC_NO_GRANT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_MIC_INPUT_INTERRUPT"] = 80000305] = "ERR_MIC_INPUT_INTERRUPT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_SPEAKER_OUTPUT_INTERRUPT"] = 80000306] = "ERR_SPEAKER_OUTPUT_INTERRUPT";
    YRTCErrorInfo[YRTCErrorInfo["ERR_MIC_INSERT_OR_REMOVE"] = 80000307] = "ERR_MIC_INSERT_OR_REMOVE";
    YRTCErrorInfo[YRTCErrorInfo["ERR_SPEAKER_INSERT_OR_REMOVE"] = 80000308] = "ERR_SPEAKER_INSERT_OR_REMOVE";
    YRTCErrorInfo[YRTCErrorInfo["ERR_CAMERA_INSERT_OR_REMOVE"] = 80000309] = "ERR_CAMERA_INSERT_OR_REMOVE";
    //媒体通道相关
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ICE_CONNECTED"] = 80000501] = "NETWORK_ICE_CONNECTED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ICE_DISCONNECTED"] = 80000502] = "NETWORK_ICE_DISCONNECTED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ICE_FAILED"] = 80000503] = "NETWORK_ICE_FAILED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ICE_CLOSED"] = 80000504] = "NETWORK_ICE_CLOSED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_NO_FIRST_FRAME_IN3S"] = 80000505] = "NETWORK_CONSUME_NO_FIRST_FRAME_IN3S";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_PRODUCE_NO_FIRST_FRAME_IN3S"] = 80000507] = "NETWORK_PRODUCE_NO_FIRST_FRAME_IN3S";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_NO_FRAME_IN5S"] = 80000508] = "NETWORK_CONSUME_NO_FRAME_IN5S";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_FRAME_RECOVERY"] = 80000518] = "NETWORK_CONSUME_FRAME_RECOVERY";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_NO_PACKET_IN5S"] = 80000528] = "NETWORK_CONSUME_NO_PACKET_IN5S";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_PACKET_RECOVERY"] = 80000538] = "NETWORK_CONSUME_PACKET_RECOVERY";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_CONSUME_PACKET_DROP_UPTO"] = 80000509] = "NETWORK_CONSUME_PACKET_DROP_UPTO";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_PRODUCE_PACKET_DROP_UPTO"] = 80000510] = "NETWORK_PRODUCE_PACKET_DROP_UPTO";
    //网络相关
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ERR_WEBSOCKET_CLOSED"] = 80000901] = "NETWORK_ERR_WEBSOCKET_CLOSED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ERR_WEBSOCKET_FAILED"] = 80000902] = "NETWORK_ERR_WEBSOCKET_FAILED";
    YRTCErrorInfo[YRTCErrorInfo["NETWORK_ERR_WEBSOCKET_ERROR"] = 80000903] = "NETWORK_ERR_WEBSOCKET_ERROR";
    //新添加需求
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_CAMERA"] = 80000332] = "ERR_NO_CAMERA";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_MICPHONE"] = 80000333] = "ERR_NO_MICPHONE";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO__SPEAKER"] = 80000334] = "ERR_NO__SPEAKER";
    //服务器返回错误
    YRTCErrorInfo[YRTCErrorInfo["ERR_ERROR_RESPONSE_FROM_SERVER"] = 80000801] = "ERR_ERROR_RESPONSE_FROM_SERVER";
    YRTCErrorInfo[YRTCErrorInfo["ERR_MCU_INVALID_PARAMETERS"] = 80000802] = "ERR_MCU_INVALID_PARAMETERS";
    //信令返回异常码
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_FIND_LOCAL_SERVER"] = 70001] = "ERR_NO_FIND_LOCAL_SERVER";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_FIND_CORE_BY_SERVER_ID"] = 70005] = "ERR_NO_FIND_CORE_BY_SERVER_ID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_FIND_SERVER_ID_BY_TRANSPORT_ID"] = 70006] = "ERR_NO_FIND_SERVER_ID_BY_TRANSPORT_ID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_FIND_MCU_SERVER_ID_BY_PEER_ID"] = 70007] = "ERR_NO_FIND_MCU_SERVER_ID_BY_PEER_ID";
    YRTCErrorInfo[YRTCErrorInfo["ERR_AUTH_FAIL"] = 70008] = "ERR_AUTH_FAIL";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NOT_SUPPORT_MSG"] = 70009] = "ERR_NOT_SUPPORT_MSG";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_SFU"] = 70010] = "ERR_NO_SFU";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_RTMP"] = 70011] = "ERR_NO_RTMP";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NO_MCU"] = 70012] = "ERR_NO_MCU";
    YRTCErrorInfo[YRTCErrorInfo["ERR_NOT_SUPPORT_DIRECTION"] = 70013] = "ERR_NOT_SUPPORT_DIRECTION"; //不支持的流方向
})(YRTCErrorInfo || (YRTCErrorInfo = {}));
export var QRTCVideoResolution;
(function (QRTCVideoResolution) {
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_120_120"] = 0] = "QRTCVideoResolution_120_120";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_160_160"] = 1] = "QRTCVideoResolution_160_160";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_270_270"] = 2] = "QRTCVideoResolution_270_270";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_480_480"] = 3] = "QRTCVideoResolution_480_480";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_160_120"] = 4] = "QRTCVideoResolution_160_120";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_240_180"] = 5] = "QRTCVideoResolution_240_180";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_280_210"] = 6] = "QRTCVideoResolution_280_210";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_320_240"] = 7] = "QRTCVideoResolution_320_240";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_400_300"] = 8] = "QRTCVideoResolution_400_300";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_480_360"] = 9] = "QRTCVideoResolution_480_360";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_640_480"] = 10] = "QRTCVideoResolution_640_480";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_960_720"] = 11] = "QRTCVideoResolution_960_720";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_160_90"] = 12] = "QRTCVideoResolution_160_90";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_256_144"] = 13] = "QRTCVideoResolution_256_144";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_320_180"] = 14] = "QRTCVideoResolution_320_180";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_480_270"] = 15] = "QRTCVideoResolution_480_270";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_640_360"] = 16] = "QRTCVideoResolution_640_360";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_960_540"] = 17] = "QRTCVideoResolution_960_540";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_1280_720"] = 18] = "QRTCVideoResolution_1280_720";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_1920_1080"] = 19] = "QRTCVideoResolution_1920_1080";
    QRTCVideoResolution[QRTCVideoResolution["QRTCVideoResolution_2560_1440"] = 20] = "QRTCVideoResolution_2560_1440";
})(QRTCVideoResolution || (QRTCVideoResolution = {}));
export var QRTCVideoResolutionMode;
(function (QRTCVideoResolutionMode) {
    QRTCVideoResolutionMode[QRTCVideoResolutionMode["QRTCVideoResolutionModeLandscape"] = 0] = "QRTCVideoResolutionModeLandscape";
    QRTCVideoResolutionMode[QRTCVideoResolutionMode["QRTCVideoResolutionModePortrait"] = 1] = "QRTCVideoResolutionModePortrait"; //竖屏分辨率
})(QRTCVideoResolutionMode || (QRTCVideoResolutionMode = {}));
export var QRTCVideoStreamType;
(function (QRTCVideoStreamType) {
    QRTCVideoStreamType[QRTCVideoStreamType["QRTCVideoStreamTypeBig"] = 0] = "QRTCVideoStreamTypeBig";
    QRTCVideoStreamType[QRTCVideoStreamType["QRTCVideoStreamTypeSmall"] = 1] = "QRTCVideoStreamTypeSmall";
    QRTCVideoStreamType[QRTCVideoStreamType["QRTCVideoStreamTypeSub"] = 2] = "QRTCVideoStreamTypeSub"; //辅流 屏幕分享
})(QRTCVideoStreamType || (QRTCVideoStreamType = {}));
export var QRTCMediaDeviceType;
(function (QRTCMediaDeviceType) {
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeUnknown"] = 0] = "QRTCMediaDeviceTypeUnknown";
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeAudioInput"] = 1] = "QRTCMediaDeviceTypeAudioInput";
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeAudioOutput"] = 2] = "QRTCMediaDeviceTypeAudioOutput";
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeVideoCamera"] = 3] = "QRTCMediaDeviceTypeVideoCamera";
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeVideoWindow"] = 4] = "QRTCMediaDeviceTypeVideoWindow";
    QRTCMediaDeviceType[QRTCMediaDeviceType["QRTCMediaDeviceTypeVideoScreen"] = 5] = "QRTCMediaDeviceTypeVideoScreen";
})(QRTCMediaDeviceType || (QRTCMediaDeviceType = {}));
// 网络状态枚举
export var QRTCQuality;
(function (QRTCQuality) {
    QRTCQuality[QRTCQuality["QRTCQuality_Unknown"] = 0] = "QRTCQuality_Unknown";
    QRTCQuality[QRTCQuality["QRTCQuality_Excellent"] = 1] = "QRTCQuality_Excellent";
    QRTCQuality[QRTCQuality["QRTCQuality_Good"] = 2] = "QRTCQuality_Good";
    QRTCQuality[QRTCQuality["QRTCQuality_Poor"] = 3] = "QRTCQuality_Poor";
    QRTCQuality[QRTCQuality["QRTCQuality_Bad"] = 4] = "QRTCQuality_Bad";
    QRTCQuality[QRTCQuality["QRTCQuality_Vbad"] = 5] = "QRTCQuality_Vbad";
    QRTCQuality[QRTCQuality["QRTCQuality_Down"] = 6] = "QRTCQuality_Down"; // 不可用
})(QRTCQuality || (QRTCQuality = {}));
export var QRTCVideoFillMode;
(function (QRTCVideoFillMode) {
    QRTCVideoFillMode[QRTCVideoFillMode["QRTCVideoFillMode_Fill"] = 0] = "QRTCVideoFillMode_Fill";
    QRTCVideoFillMode[QRTCVideoFillMode["QRTCVideoFillMode_Fit"] = 1] = "QRTCVideoFillMode_Fit";
})(QRTCVideoFillMode || (QRTCVideoFillMode = {}));
export var QRTCMediaDeviceChangeEvent;
(function (QRTCMediaDeviceChangeEvent) {
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_WebCamAdded"] = 0] = "QRTCMediaDevice_WebCamAdded";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_WebCamRemoved"] = 1] = "QRTCMediaDevice_WebCamRemoved";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_MicAdded"] = 2] = "QRTCMediaDevice_MicAdded";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_MicRemoved"] = 3] = "QRTCMediaDevice_MicRemoved";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_SpeakerAdded"] = 4] = "QRTCMediaDevice_SpeakerAdded";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_SpeakerRemoved"] = 5] = "QRTCMediaDevice_SpeakerRemoved";
    QRTCMediaDeviceChangeEvent[QRTCMediaDeviceChangeEvent["QRTCMediaDevice_Unknown"] = 6] = "QRTCMediaDevice_Unknown";
})(QRTCMediaDeviceChangeEvent || (QRTCMediaDeviceChangeEvent = {}));
export var QRTCCodecType;
(function (QRTCCodecType) {
    QRTCCodecType[QRTCCodecType["QRTCCodec_H264Base"] = 0] = "QRTCCodec_H264Base";
    QRTCCodecType[QRTCCodecType["QRTCCodec_H264Main"] = 1] = "QRTCCodec_H264Main";
    QRTCCodecType[QRTCCodecType["QRTCCodec_H264High"] = 2] = "QRTCCodec_H264High";
    QRTCCodecType[QRTCCodecType["QRTCCodec_H265"] = 3] = "QRTCCodec_H265";
    QRTCCodecType[QRTCCodecType["QRTCCodec_VP9"] = 4] = "QRTCCodec_VP9";
    QRTCCodecType[QRTCCodecType["QRTCCodec_VP8"] = 5] = "QRTCCodec_VP8";
    QRTCCodecType[QRTCCodecType["QRTCCodec_AV1"] = 6] = "QRTCCodec_AV1";
})(QRTCCodecType || (QRTCCodecType = {}));
var YRTCError = /** @class */ (function (_super) {
    __extends(YRTCError, _super);
    function YRTCError(errorName, message, errorCode) {
        var _this = _super.call(this, message) || this;
        _this.name = 'YRTCError';
        _this.errorName = errorName;
        _this.errorCode = errorCode;
        return _this;
    }
    return YRTCError;
}(Error));
export { YRTCError };
// export type NeedConsumeInfo = {
// 	peerId: string,
// 	producerId: string,
// 	kind?: 'audio' | 'video',
// 	appData: any //TODO by pcg 需要确认服务器给了这个数据
// }
