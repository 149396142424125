<!--
* 医百会议-主界面-底部-聊天按钮功能
* version: 1.0
* author:  SunAo
* time: 2021-02-03
-->
<template>
  <div class="chatBtn">
    <div class='btnLeft' @click="clickLeft" id="meeting_chat_btn_headless">
      <my-icon :target="'chatIcon'" :iconName="'iconliaotian_24_hei'"></my-icon>
      <div class="chatInfo">
        <div>{{$t('meeting.chat')}}</div>
        <div v-if="unreadMessageCount>0" class="message-count-tip">{{unreadMessageCount > 999 ? '999+' : unreadMessageCount}}</div>
        <!-- <div class='circle' v-if="isShowTips"></div> -->
      </div>
    </div>
    <!-- 上传组件 -->
    <el-upload
      v-show="0"
      :show-file-list="false"
      :multiplet="false"
      accept=".jpg,.jpeg,.png"
      class="upload-demo"
      ref="upload"
      action=""
      :http-request="fileUploadFn"
      :before-upload="beforeUpload"
      :onProgress="handleChangeProgress"
      >
        <el-button size="small" id="uploadImgBtnElement" data-type="" type="success">上传图片</el-button>
    </el-upload>

    <el-dialog
      v-dialogDragResizable
      custom-class="IM-dialog el-dialog-drag el-dialog-im"
      :modal="false"
      :visible.sync="isShowPanel"
      width="600px"
      :title="$t('meeting.chat')"
      :append-to-body="true"
      :close-on-click-modal="false"
      @closed="dialogClosedHandle"
    >
      <div class="chat-wrapper" @mouseenter="onEnterHandler" @mouseleave="onLeaveHandler">
        <div class="sys-msg" v-if="showGroupMsg">
          <i class="font_family iconnotice_icon"></i>
          <span>{{ this.groupSendMsg }}</span>
        </div>

        <div id="message-send-box-wrapper">
          <div class="message-list" ref="message-list" @scroll="this.onScroll">

            <div ref="message-list-inline">

              <!--<div class="message-wrapper" v-for="(message, index) in currentMessageList" :key="message.id" :id="message.id">
                
                <div class="msg-time" v-if="isShowTime(message.timestamp, index)">{{ getTimeStampText(message.timestamp) }}
                </div>
                -->
              <div class="message-wrapper" v-for="(message,index) in currentMessageList" :key="index">
                <div :class="message['uid'] === userID ? 'message-box':'message-box-other'">
                  <!--
                  <img v-if="showAvatar(message.type)" class="message-img" :src="message.avatar"
                      @error="imgError(message)">
                  -->
                  <div class="message-item" v-if="textMsg(message.type)">
                    <div class="message-nick-box">
                      <p v-if="message.msgType !== 0">
                        <span v-if="message['uid'] === userID">
                          <span class="message-nick">{{ message['timestamp'] | formatdate }}</span>
                          <span class="message-date">{{getUserName(message)}}</span>&nbsp;<span class="message-date">{{$t('meeting.toEveryone')}}</span>
                        </span>
                        <span v-else>
                          <span class="message-nick">{{ getUserName(message) }}</span>
                          <span class="message-date">{{message['timestamp'] | formatdate}}</span>
                        </span>
                      </p>
                      <div v-else class="private-message-nick">
                        <p v-if="message['uid'] === userID">
                          <span class="nick-info">{{$t('meeting.me')}}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{ message['toUserNick'] }}</span> <span
                            class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                        </p>

                        <p v-else>
                          <span class="nick-info">{{ message['nick'] }}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{$t('meeting.me')}}</span> <span
                            class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="message-container">
                      <message-status-icon v-if="message.status === 'fail'" :message="message"
                                          @reSend="onResend"></message-status-icon>
                      <div class="triangle"></div>
                      <template v-for="(item, index) in contentList(message.payload.text)">
                        <span :key="index" class="message-text"
                              v-if="item.name === 'text'" v-html="item.text"></span>
                        <img v-else-if="item.name === 'img'" :src="require(`@/assets/emotion/${item.src}`)" width="20px"
                            height="20px"
                            :key="index"/>
                      </template>
                    </div>

                  </div>
                  <div class="message-item" v-if="picMsg(message.type)">
                    <div class="message-nick-box">
                      <p v-if="message.msgType !== 0" class="message-nick">
                        {{ getUserName(message) }}
                        <span class="message-date">{{message['timestamp'] | formatdate}}</span>
                      </p>
                      <div v-else class="private-message-nick">
                        <p v-if="message['uid'] === userID">
                          <span class="nick-info">{{$t('meeting.me')}}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{ message['toUserNick'] }}</span> <span
                            class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                        </p>

                        <p v-else>
                          <span class="nick-info">{{ message['nick'] }}</span>{{$t('meeting.sendTo')}}<span class="nick-info">{{$t('meeting.me')}}</span> <span
                            class="p-txt rubik-medium">{{$t('meeting.PrivateChat')}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="pic-container">
                      <!--
                      <div v-html="message.payload.text"/>
                      -->
                      <el-image @load="imgLoad(message)" class="maxHeight" :src="message.payload.text" style="max-width: 300px; max-height: 300px" :fit="'contain'"
                          :preview-src-list="[message.payload.text]"></el-image>
                      <message-status-icon v-if="message.status === 'fail'" :message="message"
                                          @reSend="onResend"></message-status-icon>
                    </div>

                  </div>
                  <div class="tip-leave" v-if="leaveMsg(message.type)">
                    <span>{{ getGroupTipContent(message) }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="send-header-bar">
            <div class="at-tip" v-if="showAtTip" @click="onShowAtTip">{{$t('meeting.aite')}}</div>

            <div class="send-header-button">
              <span class="title">{{$t('meeting.toSend')}}<span>{{$t('meeting.allPeople')}}</span> <span class="errorTip">{{errorTips}}</span></span>
              <div class="emojo-wrap">
                <div class="emojis-btn" @click="imgclick" @mouseover="hover2 = true" @mouseleave="hover2 = false">
                  <my-icon :iconName="!hover2 ? 'icontongyong_tupian_16' : 'icona-tongyong_tupian_162'" :fontSize="16">
                  </my-icon>
                </div>
                <div class="emojis-btn"  @mouseover="hover = true" @mouseleave="hover = false" @mousedown='isfocus = true' @mouseup='onMouse'>
                  <my-icon :iconName="!hover ? 'iconbiaoqing_16_hui' : isfocus ? 'iconbiaoqing_16_lvdianji' : 'iconbiaoqing_16_lv'" :fontSize="16">
                  </my-icon>
                </div>
                <div class="emojis-box" v-if="isShowEmoj">
                  <div class="emojis">
                    <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
                      <img :src="require(`@/assets/emotion/${emojiMap[item]}`)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-area-box">
              <el-input  v-model="chatContent" :placeholder="$t('meeting.inputChat')" id="meeting_chat_msg_input_headless"></el-input>
              <el-button class="send" @click="sendTextMessage" id="meeting_chat_send_btn_headless">{{$t('meeting.send')}}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import imMixins from './im.mixins'
import { loganLog } from "@/utils/log"
import { imImgUpload} from '@/api/meet'

export default {
  mixins: [imMixins],
  data() {
    return {
      hover:false,
      isfocus:false,
      hover2:false,
      isShowPanel: false, // 是否显示面板
      isShowTips: false, //是否显示提示
    };
  },
  methods: {
    onMouse(){
      this.isfocus = false;
      this.showEmojHandler()
    },
    // 上传文件前处理
    beforeUpload (file) {
      const isLt100M = file.size / 1024 / 1024
      if (isLt100M > 100) {
          this.$message.error('文件大小100M以内');
          return false;
      }
    },
    handleChangeProgress(info,file){
        // console.error(8888,info,file)
        if(info && info.percent){
            let percent = Number(Number(info.percent).toFixed());
            if(this.shareType == 2){
              this.uploadPercent = percent === 100 ? 99 : percent;
              if(percent >= 100){
                setTimeout(() => {
                  this.isUploading = false;
                  this.uploadPercent = 0
                }, 1000);
              }
            }else{
              this.$refs.documentMember.handleChangeProgress(Object.assign(file,{docType:0,status:"uploading",percent:percent,createTime:new Date()}))
            }
        }

        // let uploadStatus = info.file.status; // 上传状态
        // this.uploadStatus = uploadStatus;
        // switch (uploadStatus) {
        //     case 'uploading':
        //         if(info.event && info.event.percent){
        //             let percent = Number(Number(info.event.percent).toFixed());
        //             this.uploadPercent = percent === 100 ? 99 : percent;
        //         }
        //         break;
        //     case 'done':
        //         this.isUploading = false;
        //         alert(`${info.file.name} 上传成功`);
        //         break;
        //     case 'error':
        //         this.isUploading = false;
        //         alert(`${info.file.name} 上传失败`);
        //         break;
            
        //     default:
        //         break;
        // }
    },
    async fileUploadFn (options) {
        let { file, onProgress, onSuccess, onError } = options;

        const encodedName = this.$configs.userId + Math.floor(Math.random() * 1000) + '.' + file.name.split('.').pop()
        // 创建一个新的文件对象，以确保文件名编码正确
        const newFile = new File([file], encodedName, {
          type: file.type,
        });
        // 阻止直接使用 file 上传，避免重复处理
        file = newFile

        const routeQuery = this.$route.query
        console.error('上传im图片开始')
        let uploadData= {
            file:file,
            userId: this.$configs.userId,
            saveForever: this.shareType == 2 ? false : true,
            type: 2, //1：会议云截图 2：im聊天文件
            conferenceId: routeQuery.conferenceId,
            conferenceNo: this.$configs.conferenceNo
        }

        // 开始插入
        // if(this.shareType == 2){ 
        //   this.isUploading = true;
        // } else {  
        //   let _data = Object.assign(file,{docType:0,status:"uploading",percent:0,createTime:new Date()})
        //   this.$refs.documentMember.fileList.unshift(_data);
        // }
        
        
        const formData = new FormData()
        formData.append('file', uploadData.file)

        const fileUploadDTO = {
            userId: uploadData.userId,
            type:uploadData.type,
            conferenceId:uploadData.conferenceId,
            roomId: this.$configs.roomId,
            metaData:''
        }

        loganLog(`uploadImgFn run --fileUploadDTO:${JSON.stringify(fileUploadDTO)}`)


        const json = JSON.stringify(fileUploadDTO);
        const blob = new Blob([json], {type: 'application/json'});
        formData.append('param', blob);

        try {
            const resData = await imImgUpload(formData, (uploadProgress)=>{
                onProgress({ percent: Math.round((uploadProgress.loaded / uploadProgress.total) * 100).toFixed(2) }, file)
            })
            this.sendMsgBody(2,resData.url)

            onSuccess({ ...res, name: file.name, status: 'done' }, file)
        } catch (error) {
            loganLog('uploadFileFn error',error)
            onError
        }
    },
    dialogClosedHandle() {
      const el = document.querySelector('.el-dialog-im')

      if (el) {
        el.style.top = 'initial'
        el.style.left = 'initial'
      }
    },
    clickLeft() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.$parent.showToast(this.$t('meeting.netWorkBad'))
        return
      }
      if (!this.isShowPanel) {
        if (!this.isLoadHistoryMessage) { // 还未加载过历史消息
          this.getHistoryMessage()
        } else {
          this.scrollToBottom()
        }
      }

      this.isShowPanel = !this.isShowPanel
      this.isShowTips = false
      this.$store.commit('resetUnReadMessageCount')
    }
  },
};
</script>

<style lang="scss" scoped>
.chatBtn {
  display: flex;
  justify-content: space-between;
  height: 48px;
  .btnLeft {
    display: flex;
    padding:0 12px;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    cursor: pointer;
    &:focus{
      background: rgba(0, 0, 0, 0.1) !important;
    }
    &:hover{
      background: rgba(0, 0, 0, 0.05) !important;
    }
  
    .chatInfo {
      position: relative;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin: 14px 0 14px 9px;
      float: left;

      .num {
        color: #ff0000;
      }
    
      .circle{
        position: absolute;
        width: 7px;
        height: 7px;
        padding: 0;
        border-radius: 50%;
        background-color: #f56c6c;
        top: -5px;
        right: -4px;
      }
    }

    .message-count-tip {
      height: 18px;
      min-width: 18px;
      border-radius: 12px;
      font-weight: 500;
      color: #FFFFFF;
      background: #FF4D4F;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      padding-left: 3.5px;
      padding-right: 3.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(.8);

      position: absolute;
      top: -10px;
      left: -25px;
      z-index: 1;
    }
  }
}
</style>

<style scoped lang="css">
/deep/ .IM-dialog > .el-dialog__body{
  padding: 0;
}
/deep/ .el-dialog-im > .el-dialog__header button{
  top: 14px;
  right: 14px;
  z-index: 1;
}
/deep/ .el-dialog-im > .el-dialog__header button i{
  font-size: 17px;
  color: #222;;
}
/deep/ .el-dialog-im > .el-dialog__header button:hover i{
  color: #222;
}
/deep/ .el-dialog-im > .el-dialog__body{
  padding: 0;
  height: calc(100% - 48px);
  overflow: hidden;
}
</style>

<style scoped lang="scss">

  @mixin public-message(){
    color: #FFFFFF;
    display: flex;
    .message-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      }
  }
    @mixin public-message-item(){
      font-size: 14px;
      padding: 0 4px;
      position: relative;
      line-height: 18px;
      word-wrap: break-word;
      white-space: normal;
      width: 90%;
      margin-left: 6px;
    }
    @mixin public-message-nick(){
      .private-message-nick {
          font-size: 14px;
          color: #656565;
          .nick-info {
            color: #00AF3D;
          }
          .p-txt {
            color: #FF8700;
          }
        }
    }
    @mixin public-message-container(){
      display: inline-block;
      position: relative;
      border-radius: 2px;
      padding: 5px 10px;
      text-align: left;
    }
    @mixin public-message-triangle(){
      width: 0;
      height: 0;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: 6px;
    }
    @mixin pic-container(){
      display: inline-block;
      position: relative;
      background: #fff;
    }
    @mixin public-tip(){
      width: 100%;
      text-align: center;
      position: relative;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
      word-wrap: break-word;
      white-space: normal;
      .tips-img {
        display: inline-block;
        width: 20px;
        vertical-align: center;
      }
    }
    @mixin message-tip(){
      .tip-text {
        padding: 4px 35px
      }
      .tip-leave {
        padding: 4px 40px
      }
      .message-text {
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        user-select: text;
        //white-space: normal;
        white-space: pre-wrap;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
.chat-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;

  .live-title {
    display: flex;
    position: relative;
    justify-content: center;
    height: 48px;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    .closeIcon{
      position: absolute;
      right: 14px;
      cursor: pointer;
    }
  }

  .sys-msg {
    z-index: 100;
    position: absolute;
    padding: 10px;
    width: calc(100% - 20px);
    //height: 44px;
    left: 0;
    top: 41px;
    display: flex;
    //align-items: center;
    //background: rgba(1,175,61, 1);
    background: #E5F7EB;
    //line-height: 44px;
    font-size: 14px;
    // color: #00AF3D;
    .iconnotice_icon {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 20px;
      color: #00AF3D;
    }

    > span {
      color: #00AF3D;
      //max-width: 250px;
      //overflow: hidden;
      text-align: left;
      word-break: break-word;
      display: block;
      white-space: pre-wrap;
      overflow: hidden;
    }
  }

  #message-send-box-wrapper {
    box-sizing: border-box;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .message-list {
      flex: 1;
      // position: absolute;
      z-index: 1;
      width: 100%;
      // top: 52px;
      // bottom: 88px;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; //ios卡顿
      padding: 8px 20px;
      margin-bottom: 5px;

      .message-wrapper {
        margin-bottom: 20px;

        .msg-time {
          margin: 0 auto 20px;
          font-size: 12px;
          color: #656565;
          text-align: center;
        }

        .message-box-other {
           @include public-message;
          .message-item {
             @include public-message-item;
            .message-nick-box {
              display: flex;
              margin-bottom: 6px;
              .message-nick {
                font-size: 14px;
                color: #333;
                margin-right: 8px;
              }
              .message-date {
                font-size: 12px;
                color: #999;
              }
              @include public-message-nick;
            }

            .message-container {
              background: #f5f5f5;
              float: left;
              @include public-message-container;
              .triangle {
                @include public-message-triangle;
                background: #fafafa;
                left: -6px;
                border-right: 6px solid #f5f5f5;
              }
              > img {
                vertical-align: middle;
              }
            }
            .pic-container {
              float: left;
              @include pic-container;
            
            }
          }

          .tip-text, .tip-leave {
            color: #00AF3D; //#258ff3//#fea602
            @include public-tip;
          }
          @include message-tip;
        }

        .message-box {
        @include public-message;
        flex-direction: row-reverse;
          .message-item {
           @include public-message-item;
            .message-nick-box {
              display: flex;
              flex-direction: row-reverse;
              margin-bottom: 6px;
              .message-nick {
                font-size: 12px;
                color: #999;
                margin-right: 8px;
              }
              .message-date {
                font-size: 14px;
                color: #333333;
              }
              @include public-message-nick;
            }

            .message-container {
              background: rgba(29, 175, 105, 0.3);
              float: right;
              @include public-message-container;
              .triangle {
                @include public-message-triangle;
                border-left: 6px solid rgba(29, 175, 105, 0.3);
                right: -6px;
              }

              > img {
                vertical-align: middle;
              }
            }

            .pic-container {
              float: right;
              @include pic-container;
            }
          }

          .tip-text, .tip-leave {
            @include public-tip;
          }
          @include message-tip;
        }
      }
    }

    .send-header-bar {
      width: 100%;
      // position: absolute;
      // bottom: 0;
      display: flex;
      flex-direction: column;
      height: 92px;
      border-top: 1px solid rgb(0, 0, 0, .1);
      box-sizing: border-box;
      padding: 10px 20px 20px 20px;
      .send-header-button {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }

      .at-tip {
        position: absolute;
        top: -32px;
        left: 10px;
        font-size: 14px;
        color: #FF8700;
        background: #fff;
        height: 24px;
        line-height: 24px;
        padding: 2px 4px;
        user-select: none;
        cursor: pointer;
        z-index: 1;
      }

      .chat-area-box {
        width: 100%;
        display: flex;
        padding-top:16px;
        ::v-deep .el-input__inner {
          width: 360px;
          height: 29px;
        }
        ::v-deep .el-input__inner::placeholder{
          color: #606266;
          font-size: 12px;
        }
        .send {
          margin-left: 8px;
          width: 72px;
          height: 32px;
          background: #1AB370;
          color:#fff;
          padding: 9px 22px;
          border-radius: 2px;
          border: none;
          &:hover{
            background: #3BBF82;
          }
          &:focus{
            background: #0E8C5A;
          }
        }
        .yb-button--small{
          padding: 9px 22px;
        }
      }
      .emojo-wrap{
        position: relative;
        display: flex;
        .emojis-btn {
          margin-left: 16px;
          user-select: none;
          cursor: pointer;
        }
        .emojis-box{
          border:1px solid red;
          width: 256px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2), 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
          position: absolute;
          box-sizing: border-box;
          z-index: 2001;
          background: #fff;
          min-width: 150px;
          left: -180px;
          top: -126px;
          border: 1px solid #ebeef5;
          padding: 12px;
          padding-bottom: 0;
          color: #606266;
          line-height: 1.4;
          text-align: justify;
          font-size: 14px;
          -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          word-break: break-all;
          .emojis {
            //padding: 2px 18px;
            //height: 160px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: scroll;

            &::-webkit-scrollbar { /*滚动条整体样式*/
              width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 4px;
            }

            &::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
              border-radius: 5px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: rgba(0, 0, 0, 0.1);
            }

            &::-webkit-scrollbar-track { /*滚动条里面轨道*/
              background: #fff;
            }

            .emoji {
              position: relative;
              height: 40px;
              width: 40px;
              margin-right: 6px;
              margin-bottom: 16px;
              box-sizing: border-box;
              img{
                position: absolute;
                width:32px;
                height:32px;
                top:50%;
                left:50%;
                margin-top: -16px;
                margin-left: -16px;
              }
              &:nth-child(5n){
                margin-right: 0;
              }
              &:hover{
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
              }
            }
          }
        }
      }
      .el-button--primary {
        background: rgba(138, 144, 153, 0.30);
        border-color: #34363b; //#F5A623
        padding: 10px 30px;

      }
    }
  }

  .tab-summary {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;

    .online-user-l {
      display: flex;
      align-items: center;
      margin: 20px 10px;
      font-size: 16px;
      color: #656565;

      > span {
        font-size: 24px;
        color: #FF8700;
      }
    }

    .online-user {
      padding: 10px;
      //height: 485px;
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;

      .user-info {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        > img {
          width: 36px;
          border-radius: 50%;
        }

        > span {
          font-family: MicrosoftYaHei;
          margin-left: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 16px;
        }
      }
    }
  }

  /*滚动条样式*/
  ::-webkit-scrollbar { /*滚动条整体样式*/
    width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
  }

  ::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-track { /*滚动条里面轨道*/
    background: #eee;
  }
}
</style>

<style scoped lang="css">
/deep/.maxHeight .el-image__inner{
  max-height: 300px !important; /* 设置你想要的最大高度 */
}
</style>




